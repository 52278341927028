import _ from 'lodash';

import { naturalSortBy, naturalSortByTwoProps } from '../utils/NaturalSort';

/**
 * A class that wraps concept management information returned from the
 * getConceptManagement endpoint. The object structure (see below) is the same
 * as returned from the endpoint, but the keys are camel cased and the lists of
 * concepts are sorted alphabetically.
 */
export class ConceptManagementInformation {
  /**
   * @typedef {Object} Concept
   * @property {string} concept - the concept text
   */

  /**
   * @typedef {Object} ConceptMerge
   * @property {string} concept - the concept text
   * @property {string} merge_with - the text of the concept that is being merged
   */

  /**
   * @typedef {Object} ConceptManagementObject - as described by the api
   * documentation.
   * @property {Concept []} ignore - list of Concepts to ignore
   * @property {Concept []} notice - list of Concepts to notice
   * @property {Concept []} collocate - list of multi-word Concepts to collocate
   * @property {ConceptMerge []} merge - list of pairs of concepts to merge
   */

  /**
   * @typedef {Object} ConceptManagement
   * @property {ConceptManagementObject} [current_build]
   * @property {ConceptManagementObject} next_build
   */

  /**
   * @param {ConceptManagement} conceptManagement - Describes both the current science
   * assertions and the assertions queued for the next rebuild. Expected to
   * be in the format the api returns.
   */
  constructor(conceptManagement) {
    this.currentBuild = conceptManagement.current_build;
    // If the project is currently building then there is no current build so
    // the API will return null for `current_build`. In this case it is
    // convenient to just treat the current build as equal to next build. After
    // rebuilding the next build will be the current build.
    if (this.currentBuild === null) {
      this.currentBuild = conceptManagement.next_build;
    }
    this.nextBuild = conceptManagement.next_build;
  }

  get hasIgnoreChanges() {
    const currentConcepts = [...this.currentBuild.ignore].sort(
      naturalSortBy('concept')
    );
    const nextConcepts = [...this.nextBuild.ignore].sort(
      naturalSortBy('concept')
    );
    return !_.isEqual(currentConcepts, nextConcepts);
  }

  get ignoreDiff() {
    const hasConcept = (list, c) =>
      list.some(element => element.concept === c.concept);

    const currentConcepts = this.currentBuild.ignore;
    const nextConcepts = this.nextBuild.ignore;

    const unchangedConcepts = currentConcepts
      .filter(c => hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const deletedConcepts = currentConcepts
      .filter(c => !hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const addedConcepts = nextConcepts
      .filter(c => !hasConcept(currentConcepts, c))
      .sort(naturalSortBy('concept'));
    return { addedConcepts, deletedConcepts, unchangedConcepts };
  }

  get ignoreSummaryString() {
    const {
      addedConcepts,
      deletedConcepts,
      unchangedConcepts
    } = this.ignoreDiff;

    const getSummaryString = (concepts, type) => {
      const shouldPluralize = concepts.length > 1 && type !== 'unchanged';
      return concepts.length > 0
        ? `${concepts.length} ${type}${shouldPluralize ? 's' : ''}`
        : '';
    };

    const summary = [
      getSummaryString(addedConcepts, 'addition'),
      getSummaryString(deletedConcepts, 'deletion'),
      getSummaryString(unchangedConcepts, 'unchanged')
    ]
      .filter(n => n)
      .join(', ');

    return summary;
  }

  get conceptsToIgnoreTexts() {
    return this.nextBuild.ignore.map(c => c.concept);
  }

  /**
   * Get new ConceptManagementInformation with the specified concept added to
   * the next build's ignored list.
   *
   * @param {string} concept - Concept to ignore
   * @returns {ConceptManagementInformation}
   */
  withAddedIgnoreConcept(concept) {
    const isConceptInNotice = this.nextBuild.notice?.some(
      item => item.concept === concept
    );
    const isConceptInMerge = this.nextBuild.merge?.some(
      item => item.concept === concept || item.merge_with === concept
    );

    if (isConceptInMerge || isConceptInNotice) {
      return this;
    }

    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        ignore: [{ concept }, ..._.reject(this.nextBuild.ignore, { concept })]
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  /**
   * Get new ConceptManagementInformation with the specified concept removed from
   * the next build's ignored list.
   *
   * @param {string} concept - Concept to ignore
   * @returns {ConceptManagementInformation}
   */
  withRemovedIgnoreConcept(concept) {
    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        ignore: _.reject(this.nextBuild.ignore, { concept })
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  /**
   * Get new ConceptManagementInformation with the ignore, notice, merge, and
   * collocate lists sorted alphabetically by the 'concept' property for both
   * the current and next builds
   *
   * @returns {ConceptManagementInformation}
   */
  get withSortedBuilds() {
    const alphabetize = concepts =>
      [...concepts].sort(naturalSortBy('concept'));
    const conceptManagement = {
      current_build: _.mapValues(this.currentBuild, alphabetize),
      next_build: _.mapValues(this.nextBuild, alphabetize)
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  /**
   * Get new ConceptManagementInformation with the next build reset to the value
   * of the current build.
   *
   * @returns {ConceptManagementInformation}
   */
  get withChangesReset() {
    return new ConceptManagementInformation({
      current_build: this.currentBuild,
      // Spread the existing next_build properties and only override the merge key
      next_build: {
        ...this.nextBuild,
        ignore: this.currentBuild.ignore
      }
    });
  }

  /**
   * Compare the nextBuild properties of two instances of
   * ConceptManagementInformation for equality.
   * Currently only compares on the basis of the ignore assertions, but as
   * features are added we might want to add logic to compare on the basis of
   * the other assertion arrays
   *
   * @param {ConceptManagementInformation} otherCM - concept management
   *                                                 information to compare
   * @returns {boolean}
   */
  nextBuildIsEqual(otherCM) {
    return _.isEqual(
      [...this.nextBuild.ignore].sort(naturalSortBy('concept')),
      [...otherCM.nextBuild.ignore].sort(naturalSortBy('concept'))
    );
  }

  withAddedMergeConcept(mergedConcept) {
    const { concept, merge_with } = mergedConcept;

    let isAlreadyMergedAsSource = false;
    let isMergeWithAlreadyASourceForOther = false;

    // Perform all checks in a single iteration over the merge array
    for (const item of this.nextBuild.merge) {
      if (item.concept === merge_with && item.merge_with === concept) {
        return this; // Circular merge, return without adding
      }
      if (item.concept === concept) {
        isAlreadyMergedAsSource = true; // 'concept' is already a source
      }
      if (item.concept === merge_with && item.merge_with !== concept) {
        isMergeWithAlreadyASourceForOther = true;
      }
    }

    if (isAlreadyMergedAsSource || isMergeWithAlreadyASourceForOther) {
      return this;
    }

    const updatedMergeList = [...this.nextBuild.merge, mergedConcept];

    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        merge: updatedMergeList
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  withRemovedMergeConcept(mergedConcept) {
    const { concept, merge_with } = mergedConcept;
    const updatedMerge = this.nextBuild.merge.filter(
      mc => !(mc.concept === concept && mc.merge_with === merge_with)
    );
    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        merge: updatedMerge
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  get withChangesResetMerge() {
    return new ConceptManagementInformation({
      current_build: this.currentBuild,
      // Spread the existing next_build properties and only override the merge key
      next_build: {
        ...this.nextBuild,
        merge: this.currentBuild.merge
      }
    });
  }

  get mergeSummaryString() {
    const {
      addedConcepts,
      deletedConcepts,
      unchangedConcepts
    } = this.mergeDiff;

    const getSummaryString = (concepts, type) => {
      const shouldPluralize = concepts.length > 1;
      return concepts.length > 0
        ? `${concepts.length} ${type}${shouldPluralize ? 's' : ''}`
        : '';
    };

    const summary = [
      getSummaryString(addedConcepts, 'merge addition'),
      getSummaryString(deletedConcepts, 'merge deletion'),
      getSummaryString(unchangedConcepts, 'unchanged merge')
    ]
      .filter(n => n)
      .join(', ');

    return summary;
  }

  get hasMergeChanges() {
    const currentConcepts = [...this.currentBuild.merge].sort((a, b) =>
      naturalSortByTwoProps(a, b)
    );
    const nextConcepts = [...this.nextBuild.merge].sort((a, b) =>
      naturalSortByTwoProps(a, b)
    );
    return !_.isEqual(currentConcepts, nextConcepts);
  }

  get mergeDiff() {
    const hasConcept = (list, c) =>
      list.some(
        element =>
          element.concept === c.concept && element.merge_with === c.merge_with
      );

    const currentConcepts = this.currentBuild.merge;
    const nextConcepts = this.nextBuild.merge;

    const unchangedConcepts = currentConcepts
      .filter(c => hasConcept(nextConcepts, c))
      .sort((a, b) => naturalSortByTwoProps(a, b));
    const deletedConcepts = currentConcepts
      .filter(c => !hasConcept(nextConcepts, c))
      .sort((a, b) => naturalSortByTwoProps(a, b));
    const addedConcepts = nextConcepts
      .filter(c => !hasConcept(currentConcepts, c))
      .sort((a, b) => naturalSortByTwoProps(a, b));
    return { addedConcepts, deletedConcepts, unchangedConcepts };
  }

  nextBuildIsEqualMerge(otherCM) {
    return _.isEqual(
      [...this.nextBuild.merge].sort(naturalSortBy('concept')),
      [...otherCM.nextBuild.merge].sort(naturalSortBy('concept'))
    );
  }

  withAddedNoticeConcept(concept) {
    const isConceptInIgnore = this.nextBuild.ignore.some(
      item => item.concept === concept
    );
    if (isConceptInIgnore) {
      return this;
    }

    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        notice: [{ concept }, ..._.reject(this.nextBuild.notice, { concept })]
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  withRemovedNoticeConcept(concept) {
    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        notice: _.reject(this.nextBuild.notice, { concept })
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  get withChangesResetNotice() {
    return new ConceptManagementInformation({
      current_build: this.currentBuild,
      // Spread the existing next_build properties and only override the merge key
      next_build: {
        ...this.nextBuild,
        notice: this.currentBuild.notice
      }
    });
  }

  nextBuildIsEqualNotice(otherCM) {
    return _.isEqual(
      [...this.nextBuild.notice].sort(naturalSortBy('concept')),
      [...otherCM.nextBuild.notice].sort(naturalSortBy('concept'))
    );
  }

  get hasNoticeChanges() {
    const currentConcepts = [...this.currentBuild.notice].sort(
      naturalSortBy('concept')
    );
    const nextConcepts = [...this.nextBuild.notice].sort(
      naturalSortBy('concept')
    );
    return !_.isEqual(currentConcepts, nextConcepts);
  }

  get noticeDiff() {
    const hasConcept = (list, c) =>
      list.some(element => element.concept === c.concept);

    const currentConcepts = this.currentBuild.notice;
    const nextConcepts = this.nextBuild.notice;

    const unchangedConcepts = currentConcepts
      .filter(c => hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const deletedConcepts = currentConcepts
      .filter(c => !hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const addedConcepts = nextConcepts
      .filter(c => !hasConcept(currentConcepts, c))
      .sort(naturalSortBy('concept'));
    return { addedConcepts, deletedConcepts, unchangedConcepts };
  }

  get noticeSummaryString() {
    const {
      addedConcepts,
      deletedConcepts,
      unchangedConcepts
    } = this.noticeDiff;

    const getSummaryString = (concepts, type) => {
      const shouldPluralize = concepts.length > 1;
      return concepts.length > 0
        ? `${concepts.length} ${type}${shouldPluralize ? 's' : ''}`
        : '';
    };

    const summary = [
      getSummaryString(addedConcepts, 'addition'),
      getSummaryString(deletedConcepts, 'deletion'),
      getSummaryString(unchangedConcepts, 'unchanged')
    ]
      .filter(n => n)
      .join(', ');

    return summary;
  }

  withAddedCollocateConcept(concept) {
    const isConceptInIgnore = this.nextBuild.collocate.some(
      item => item.concept === concept
    );
    if (isConceptInIgnore) {
      return this;
    }

    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        collocate: [
          { concept },
          ..._.reject(this.nextBuild.collocate, { concept })
        ]
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  withRemovedCollocateConcept(concept) {
    const conceptManagement = {
      current_build: this.currentBuild,
      next_build: {
        ...this.nextBuild,
        collocate: _.reject(this.nextBuild.collocate, { concept })
      }
    };
    return new ConceptManagementInformation(conceptManagement);
  }

  get withChangesResetCollocate() {
    return new ConceptManagementInformation({
      current_build: this.currentBuild,
      // Spread the existing next_build properties and only override the merge key
      next_build: {
        ...this.nextBuild,
        collocate: this.currentBuild.collocate
      }
    });
  }

  nextBuildIsEqualCollocate(otherCM) {
    return _.isEqual(
      [...this.nextBuild.collocate].sort(naturalSortBy('concept')),
      [...otherCM.nextBuild.collocate].sort(naturalSortBy('concept'))
    );
  }

  get hasCollocateChanges() {
    const currentConcepts = [...this.currentBuild.collocate].sort(
      naturalSortBy('concept')
    );
    const nextConcepts = [...this.nextBuild.collocate].sort(
      naturalSortBy('concept')
    );
    return !_.isEqual(currentConcepts, nextConcepts);
  }

  get collocateDiff() {
    const hasConcept = (list, c) =>
      list.some(element => element.concept === c.concept);

    const currentConcepts = this.currentBuild.collocate;
    const nextConcepts = this.nextBuild.collocate;

    const unchangedConcepts = currentConcepts
      .filter(c => hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const deletedConcepts = currentConcepts
      .filter(c => !hasConcept(nextConcepts, c))
      .sort(naturalSortBy('concept'));
    const addedConcepts = nextConcepts
      .filter(c => !hasConcept(currentConcepts, c))
      .sort(naturalSortBy('concept'));
    return { addedConcepts, deletedConcepts, unchangedConcepts };
  }

  get collocateSummaryString() {
    const {
      addedConcepts,
      deletedConcepts,
      unchangedConcepts
    } = this.collocateDiff;

    const getSummaryString = (concepts, type) => {
      const shouldPluralize = concepts.length > 1;
      return concepts.length > 0
        ? `${concepts.length} ${type}${shouldPluralize ? 's' : ''}`
        : '';
    };

    const summary = [
      getSummaryString(addedConcepts, 'addition'),
      getSummaryString(deletedConcepts, 'deletion'),
      getSummaryString(unchangedConcepts, 'unchanged')
    ]
      .filter(n => n)
      .join(', ');

    return summary;
  }
}
