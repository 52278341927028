import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Highlights = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper defaultSize="32" {...props}>
    <path
      d="M5.39917 5.33334C5.39917 4.96515 5.66817 4.66667 6.00001 4.66667H14.0658C14.3976 4.66667 14.6667 4.96515 14.6667 5.33334V16.0066C14.6667 16.3748 14.3976 16.6732 14.0658 16.6732H6C5.66817 16.6732 5.39917 16.3748 5.39917 16.0066V5.33334Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
    <path
      d="M5.39918 22.6033C5.39918 22.2351 5.66818 21.9366 6.00001 21.9366H14.0658C14.3977 21.9366 14.6667 22.2351 14.6667 22.6033V26.6667C14.6667 27.0349 14.3977 27.3333 14.0658 27.3333H6.00001C5.66818 27.3333 5.39918 27.0349 5.39918 26.6667V22.6033Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
    <path
      d="M18.2491 5.33334C18.2491 4.96515 18.5181 4.66667 18.85 4.66667H26.6667C26.9985 4.66667 27.2675 4.96515 27.2675 5.33334V9.35415C27.2675 9.72234 26.9985 10.0208 26.6667 10.0208H18.85C18.5181 10.0208 18.2491 9.72234 18.2491 9.35415V5.33334Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
    <path
      d="M18.2491 16.024C18.2491 15.6558 18.5181 15.3573 18.85 15.3573H26.6667C26.9985 15.3573 27.2675 15.6558 27.2675 16.024V26.6667C27.2675 27.0349 26.9985 27.3333 26.6667 27.3333H18.85C18.5181 27.3333 18.2491 27.0349 18.2491 26.6667V16.024Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
  </SVGWrapper>
);
