import React from 'react';

import { SVGWrapper } from '../SVGWrapper';
import { Colors } from '../../../styles';

/**
 * Face icons are only used for sentiment results and therefore have hard coded
 * colors.
 */
export function Face({ lineWidth, emotion, ...props }) {
  const Icon =
    emotion === 'sad' ? SadFace : emotion === 'happy' ? HappyFace : NeutralFace;
  return (
    <SVGWrapper {...props}>
      <Icon lineWidth={lineWidth} />
    </SVGWrapper>
  );
}

const SadFace = ({ lineWidth }) => {
  const color = Colors.red3;
  return (
    <>
      <path
        d="M14.5917 7.99991C14.5917 8.817 14.4431 9.59937 14.1713 10.3214C14.1679 10.3304 14.1645 10.3394 14.1611 10.3484C13.4717 12.1559 12.0098 13.582 10.1789 14.223C10.1726 14.2252 10.1663 14.2274 10.1599 14.2296C9.48326 14.4642 8.75653 14.5916 8.00003 14.5916C4.35953 14.5916 1.40833 11.6404 1.40833 7.99991C1.40833 4.35941 4.35953 1.4082 8.00003 1.4082C11.6405 1.4082 14.5917 4.35941 14.5917 7.99991Z"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.33331 10.5032C6.04117 9.80399 6.97563 9.37891 7.99998 9.37891C9.02433 9.37891 9.95879 9.80399 10.6666 10.5032"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d="M6.74971 6.13701C6.74971 6.48019 6.57837 6.75839 6.36701 6.75839C6.15565 6.75839 5.98431 6.48019 5.98431 6.13701C5.98431 5.79383 6.15565 5.51562 6.36701 5.51562C6.57837 5.51562 6.74971 5.79383 6.74971 6.13701Z"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d="M9.633 6.75839C9.84436 6.75839 10.0157 6.48019 10.0157 6.13701C10.0157 5.79383 9.84436 5.51562 9.633 5.51562C9.42165 5.51562 9.25031 5.79383 9.25031 6.13701C9.25031 6.48019 9.42165 6.75839 9.633 6.75839Z"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
    </>
  );
};

const HappyFace = ({ lineWidth }) => {
  const color = Colors.blue3;
  return (
    <path
      d="M6.53334 5.89731C6.23998 5.52497 5.785 5.2859 5.27418 5.2859C4.76336 5.2859 4.30838 5.52497 4.01501 5.89731M11.985 5.89731C11.6917 5.52497 11.2367 5.2859 10.7259 5.2859C10.2151 5.2859 9.76008 5.52497 9.46671 5.89731M14.5917 7.99991C14.5917 4.35941 11.6405 1.4082 8.00003 1.4082C4.35953 1.4082 1.40833 4.35941 1.40833 7.99991C1.40833 11.6404 4.35953 14.5916 8.00003 14.5916C8.75653 14.5916 9.48326 14.4642 10.1599 14.2296C10.1663 14.2274 10.1726 14.2252 10.1789 14.223C12.0098 13.582 13.4717 12.1559 14.1611 10.3484C14.1645 10.3394 14.1679 10.3304 14.1713 10.3214C14.4431 9.59937 14.5917 8.817 14.5917 7.99991ZM11.2967 7.72215C11.2967 9.59715 9.77676 11.1171 7.90176 11.1171C6.02676 11.1171 4.50678 9.59715 4.50678 7.72215C4.50678 7.69976 6.19008 8.92163 8.00003 8.92163C9.80998 8.92163 11.2967 7.69976 11.2967 7.72215Z"
      stroke={color}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  );
};

const NeutralFace = ({ lineWidth }) => {
  const color = Colors.yellow3;

  return (
    <>
      <path
        d="M14.5917 7.99991C14.5917 8.817 14.4431 9.59937 14.1713 10.3214C14.1679 10.3304 14.1645 10.3394 14.1611 10.3484C13.4717 12.1559 12.0098 13.582 10.1789 14.223C10.1726 14.2252 10.1663 14.2274 10.1599 14.2296C9.48326 14.4642 8.75653 14.5916 8.00003 14.5916C4.35953 14.5916 1.40833 11.6404 1.40833 7.99991C1.40833 4.35941 4.35953 1.4082 8.00003 1.4082C11.6405 1.4082 14.5917 4.35941 14.5917 7.99991Z"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.33331 10.2755C7.11109 10.2755 8.88887 10.2753 10.6666 10.2755"
        stroke={color}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d="M6.31358 6.50116C6.31358 6.96405 6.08247 7.33929 5.79738 7.33929C5.5123 7.33929 5.28119 6.96405 5.28119 6.50116C5.28119 6.03827 5.5123 5.66302 5.79738 5.66302C6.08247 5.66302 6.31358 6.03827 6.31358 6.50116Z"
        stroke={color}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M10.2027 7.33929C10.4877 7.33929 10.7189 6.96405 10.7189 6.50116C10.7189 6.03827 10.4877 5.66302 10.2027 5.66302C9.91757 5.66302 9.68646 6.03827 9.68646 6.50116C9.68646 6.96405 9.91757 7.33929 10.2027 7.33929Z"
        stroke={color}
        strokeWidth="1.1"
        strokeLinecap="round"
      />
    </>
  );
};
