import { useContext, useEffect } from 'react';

import { StoreContext } from '../../StoreContext';
import { usePrevious } from './usePrevious';

/**
 * Calls the provided function--if any--when the list of active concepts changes
 * in any way other than order. Useful for avoiding refetches when a user
 * reorders the list but doesn't change any actual concepts.
 *
 * @param {any} refetch A refetching function, or another value if no refetch
 *                      should happen
 */
export function useRefetchWhenActiveConceptsChange(refetch) {
  const { activeConcepts } = useContext(StoreContext);
  const prevActiveConcepts = usePrevious(activeConcepts);
  useEffect(() => {
    if (
      typeof refetch === 'function' &&
      !areEqualSetwise(activeConcepts, prevActiveConcepts)
    ) {
      refetch(true);
    }
  });
}

function areEqualSetwise(a, b) {
  const setA = new Set(a);
  const setB = new Set(b);
  if (setA.size !== setB.size) {
    return false;
  }
  for (const elA of setA) {
    if (!setB.has(elA)) {
      return false;
    }
  }
  return true;
}
