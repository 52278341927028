import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Clipboard = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M3.33331 4.35068C3.33331 3.79839 3.78103 3.35068 4.33331 3.35068H6.33207C6.52735 3.35068 6.69808 3.48238 6.74766 3.67127V3.67127C7.08423 4.95362 8.90462 4.95362 9.24119 3.67127V3.67127C9.29077 3.48238 9.4615 3.35068 9.65678 3.35068H11.6666C12.2189 3.35068 12.6666 3.79839 12.6666 4.35068V13.0173C12.6666 13.5696 12.2189 14.0173 11.6666 14.0173H4.33331C3.78103 14.0173 3.33331 13.5696 3.33331 13.0173V4.35068Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinejoin="round"
    />
    <path
      d="M6.66669 3.35068V3.31576C6.66669 2.57938 7.26364 1.98243 8.00002 1.98243V1.98243C8.7364 1.98243 9.33335 2.57938 9.33335 3.31576V3.35068"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinejoin="round"
    />
    <path
      d="M4.73865 3.35074H6.38469L8.0792 4.64431L9.47748 3.35068L11.2614 3.35074V5.0174C11.2614 5.56969 10.8137 6.0174 10.2614 6.0174H5.73865C5.18636 6.0174 4.73865 5.56969 4.73865 5.0174V3.35074Z"
      fill={iconColor}
    />
  </SVGWrapper>
);
