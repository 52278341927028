/**
 * If the search has any older parameters that have been renamed, modified etc.,
 * then this function does the necessary conversions to the parameters of the
 * search.
 *
 * - If no conversions are necessary, return the search params unmodified.
 *
 * @param params - The original search params object
 * @param feature - The current active feature
 *
 */

export function migrateOldFormatSearch(params, feature) {
  const migratedParams = { ...params };

  // handle count param rename
  if ('count' in params) {
    if (feature === 'volume' || feature === 'sentiment') {
      migratedParams.prevalent_count = migratedParams['count'];
      delete migratedParams.count;
    } else if (feature === 'drivers') {
      migratedParams.drivers_count = params['count'];
      delete migratedParams.count;
    }
  }

  return migratedParams;
}
