import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Approximate = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M13.3334 9.68106C11.9831 8.17481 10.2906 8.49335 8.00008 9.68106C5.49136 10.9816 4.18974 11.0104 2.66675 9.68106M13.3334 6.34773C11.9831 4.84147 10.2906 5.16002 8.00008 6.34773C5.49136 7.6483 4.18974 7.67709 2.66675 6.34773"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0.5 3"
    />
  </SVGWrapper>
);
