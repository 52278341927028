import ReactDOM from 'react-dom';
import ko from 'knockout';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import SectionBase from '../../classes/sectionbase';
import { getExternalToken } from '../../../../main/scripts/utils/ApiUtilsV5';
import Spinner from '../../../../main/scripts/components/core/Spinner';

const brandingInfo = require('../../../../brandings.json');

export default class ExternalLoginSection extends SectionBase {
  sectionRequiresAuth = false;

  constructor() {
    super();
    ko.applyBindings(this);
    ReactDOM.render(<RedirectAuth />, document.getElementById('ext_login'));
  }
}

const getTokenFromUrl = () => {
  return new URL(window.location.href).search.split('=')[1];
};

export const RedirectAuth = () => {
  const token = getTokenFromUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const getTokenFromParentResource = async () => {
    let isDomainPresent = false;
    let customErrorMessage = '';
    const parentDomain = document.referrer.split('/')[2].split(':')[0];

    for (const [, org_info] of Object.entries(brandingInfo)) {
      for (const domain of org_info['domains']) {
        if (parentDomain.endsWith(domain)) {
          isDomainPresent = true;
          customErrorMessage = org_info['error_text'];
          break;
        }
      }
    }
    setErrorMessage('');
    setIsLoading(true);
    try {
      const response = await getExternalToken(token);
      'error' in response
        ? isDomainPresent
          ? setErrorMessage(customErrorMessage)
          : setErrorMessage(response.error)
        : localStorage.setItem('auth_token', `"${response.token}"`);
      localStorage.setItem('username', response.username);

      location.replace('/');
    } catch (e) {
      isDomainPresent
        ? setErrorMessage(customErrorMessage)
        : setErrorMessage(e.error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getTokenFromParentResource();
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <Spinner size="large" />
      ) : errorMessage.length ? (
        <div>{errorMessage}</div>
      ) : null}
    </Wrapper>
  );
};
