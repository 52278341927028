{unpack} = require './pack64'

# Vector manipulation functions
module.exports = v = {
  add: (vec1, vec2, out) ->
    out ?= vec1
    len = Math.min vec1.length, vec2.length
    for i in [0...len]
      out[i] = vec1[i] + vec2[i]

    out

  subtract: (vec1, vec2, out) ->
    out ?= vec1
    len = Math.min vec1.length, vec2.length
    for i in [0...len]
      out[i] = vec1[i] - vec2[i]

    out

  normalize: (vec, out) ->
    out ?= vec
    sqmag = 1e-10
    for n in vec
      sqmag += n * n
    mag = Math.sqrt sqmag

    for n, i in vec
      out[i] = n / mag

    out

  scale: (vec, scale, out) ->
    out ?= vec
    for i in [0...vec.length]
      out[i] = vec[i] * scale

    out

  dot: (vec1, vec2) ->
    s = 0
    # Performance optimization: Assume either vectors are the same length, or the first is smaller.
    len = vec1.length
    for i in [0...len]
      s += vec1[i] * vec2[i]

    s


  ### Luminoso vector stuff ###

  parse: (items) ->
    # Take an array of items each with a pack64'd .vector,
    # unpack them, and also construct normalized versions
    # with the average normalized vector subtracted out.
    #
    # Result: Each object gets
    #   a .vec with an unpacked vector

    for item in items
      item.vecs = [unpack item.vectors[0]]

      # We no longer need the pack64 representation; delete it to save memory
      delete item.vectors

    return items

  average: (items) ->
    # Take an array of items and calculate the average
    # of their normalized vectors
    # Assumes len(items) > 0

    len = items[0].vec.length
    avgVec = new Float32Array(len)
    for item in items
      v.add avgVec, item.vecNormalized

    v.scale avgVec, 1 / items.length
    avgVec

  vectorRelatednessScore: (aVec, bVec) ->
    v.dot aVec, bVec
}
