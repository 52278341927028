_ = require 'lodash'
$ = require 'jquery'
ko = require 'knockout'
# Custom Knockout JS Bindings

### Bindings that do new things ###

keyHandler = (code) ->
    init: (el, valueAccessor, allBindingsAccessor, viewModel, bindingContext) ->
        $(el).keydown (e) ->
            fn = ko.utils.unwrapObservable valueAccessor()
            return true unless e.which == code

            fn.call bindingContext.$data, bindingContext.$data, e
            false

# enter, escape: fn
# Handlers for executing a function when enter or escape are pressed in an input
ko.bindingHandlers.enter = keyHandler 13
ko.bindingHandlers.escape = keyHandler 27

# This binding will select the text inside an input or textarea based on a boolean observable value.
ko.bindingHandlers.selected = {
    init: (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) ->
        valueAccessor().subscribe (value) ->
            # Defer the DOM update to the next tick in case the input is not yet visible
            _.defer(-> element.select()) if value
}

### Bindings that combine multiple old things or provide shortcuts ###

# disable: value, css: { disabled: value }
ko.bindingHandlers.disabled = {
    init: (el, valueAccessor) ->
        ko.bindingHandlers.disable.init? el, valueAccessor
        ko.bindingHandlers.css.init? el, valueAccessor

    update: (el, valueAccessor) ->
        value = ko.utils.unwrapObservable valueAccessor()
        ko.bindingHandlers.disable.update el, -> value
        ko.bindingHandlers.css.update el, -> { disabled: value }
}

# css: { visible: value }
ko.bindingHandlers.fadeVisible = {
    init: (el, valueAccessor) ->
        ko.bindingHandlers.css.init? el, valueAccessor

    update: (el, valueAccessor) ->
        value = ko.utils.unwrapObservable valueAccessor()
        ko.bindingHandlers.css.update el, -> { visible: value }
}
