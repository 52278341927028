import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Upload = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path;

  if (widthName === 'light') {
    path =
      'M8.01794 11.6238V1.21616M11.4172 4.21616L8.48934 1.28831C8.22899 1.02796 7.80688 1.02796 7.54653 1.28831L4.61868 4.21616M1.5498 10.8048V13.5652C1.5498 14.3015 2.2111 14.8985 3.02686 14.8985H13.0091C13.8248 14.8985 14.4861 14.3015 14.4861 13.5652V10.8047';
  } else if (widthName === 'bolder') {
    path =
      'M7.99996 11.6237V1.21613M11.3992 4.21613L8.47136 1.28828C8.21101 1.02793 7.7889 1.02793 7.52855 1.28828L4.6007 4.21613M1.88782 10.8047V13.5651C1.88782 14.3015 2.54912 14.8984 3.36487 14.8984H12.667C13.4827 14.8984 14.144 14.3015 14.144 13.5651V10.8047';
  } else {
    path =
      'M7.99995 11.6237V1.21613M11.3992 4.21613L8.47134 1.28828C8.21099 1.02793 7.78889 1.02793 7.52854 1.28828L4.60069 4.21613M1.91876 10.8047V13.5651C1.91876 14.3015 2.58006 14.8984 3.39581 14.8984H12.642C13.4577 14.8984 14.119 14.3015 14.119 13.5651V10.8047';
  }

  return (
    <SVGWrapper {...props}>
      <path
        d={path}
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
};
