import _ from 'lodash';

import {
  percentify,
  thousandify
} from '../../../../main/scripts/utils/NumFmtUtils';

export const getBuildInfoProperties = (project, hasOutdatedScience) => {
  const sentimentProgress = project.last_build_info.sentiment?.progress ?? 0;
  const sentimentProgressText = percentify(sentimentProgress, 1, 0);

  return {
    sentimentProgress,
    sentimentProgressText,
    status: getStatus(project, hasOutdatedScience)
  };
};

const getStatus = (project, hasOutdatedScience) => {
  const docCount = project.document_count;
  const buildInfo = project.last_build_info;
  const { sentiment } = buildInfo;
  const waitingOnSentiment =
    sentiment?.start_time != null && sentiment?.stop_time == null;
  const sentimentBuildFailed = sentiment?.success === false;

  // TODO: Clarify distinction b/w "Waiting for data" and "Some data present,
  // but not yet recalculating"
  const uploadText = 'Upload data';

  if (buildInfo.stop_time && !buildInfo.success) {
    // the last build failed
    return {
      text: 'Build failed',
      message: `Error: ${buildInfo.reason}`,
      uploadText,
      light: 'red'
    };
  } else if (_.isEmpty(buildInfo)) {
    // no build has been attempted
    return {
      text: 'Waiting for data',
      message: 'Upload your data in order to explore it.',
      uploadText
    };
  } else if (!buildInfo.stop_time) {
    // the last build hasn't stopped yet
    return {
      text: 'Processing data',
      message: "We're currently processing recently uploaded documents.",
      uploadText: '', // TODO: Have a button that cancels the upload.
      light: 'yellow'
    };
  } else if (hasOutdatedScience) {
    // the project is too old to open
    return {
      text: getDocCountText(docCount),
      message: '',
      uploadText,
      light: 'slate'
    };
  } else if (sentimentBuildFailed) {
    return {
      text: getDocCountText(docCount),
      message:
        'There was a problem calculating sentiment, but you can still explore your project',
      uploadText,
      light: 'red'
    };
  } else {
    return {
      text: getDocCountText(docCount),
      message: waitingOnSentiment
        ? "We're still calculating sentiment, but you can explore your project in the meantime."
        : 'This project is ready to be viewed and explored.',
      uploadText,
      light: waitingOnSentiment ? 'yellow' : 'green',
      waitingOnSentiment
    };
  }
};

const getDocCountText = docCount =>
  (docCount === 0 ? 'No' : thousandify(docCount)) +
  ' Document' +
  (docCount !== 1 ? 's' : '');
