import ko from 'knockout';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

import SectionBase from '../classes/sectionbase';
import Router from '../utils/router';
import { useDocumentTitle } from '../../../main/scripts/utils/hooks';
import { recoverPassword } from '../../../main/scripts/utils/ApiUtilsV5';
import { Colors } from '../../../main/scripts/styles';
import { Button } from '../../../main/scripts/components/core/Button';

export default class ForgotPasswordSection extends SectionBase {
  sectionRequiresAuth = false;

  constructor() {
    super();
    ko.applyBindings(this);
    ReactDOM.render(<ForgotPassword />, document.getElementById('main'));
  }
}

export const ForgotPassword = () => {
  useDocumentTitle('Forgot Password | Luminoso Daylight');
  const [email, setEmail] = useState('');

  const sendRecoveryEmail = event => {
    event.preventDefault();
    recoverPassword(email)
      .then(() => {
        alert('Email sent.');
      })
      .catch(data => {
        if (data.error === 'TOO_MANY_REQUESTS') {
          alert(
            'You have exceeded the number of password reset requests we allow in 24 hours. Either wait 24 hours to attempt another reset, or contact support@luminoso.com to reset your account.'
          );
        } else {
          alert(data.message);
        }
      })
      .finally(() => {
        Router.go('login.html');
      });
  };

  return (
    <Container>
      <Form onSubmit={sendRecoveryEmail}>
        <Header>Forgot password</Header>
        <p>
          Enter your email address to receive a password-recovery email.
          <br />
          (Make sure to enter the email address correctly. If the address
          entered isn't a registered Luminoso username, no email will be sent.)
        </p>
        <Input
          aria-label="email"
          type="text"
          autoFocus
          autoComplete="email"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <SubmitButton type="submit">Send email</SubmitButton>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 56px); /* account for nav bar height */
  color: ${Colors.gray9};

  /* Center contents */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 500px;
  height: 270px;
  transform: translateY(-104px);
`;

const Header = styled.h1`
  font-weight: 300;
  margin: 5px;
`;

const Input = styled.input`
  width: 100%;
  margin-bottom: 10px;
`;

const SubmitButton = styled(Button)`
  border: none;
  width: 135px;
  padding: 13px 20px;
`;
