export function createPasswordValidationErrors(newPass, confirmPass, username) {
  const errors = {};
  validateNotEmpty(errors, undefined, newPass, confirmPass);
  validateMinLength(errors, newPass);
  validateMaxLength(errors, newPass);
  validatePasswordNotUsername(errors, newPass, username);
  validatePasswordsMatch(errors, newPass, confirmPass);
  return errors;
}

export function changePasswordValidationErrors(
  oldPass,
  newPass,
  confirmPass,
  username
) {
  const errors = {};
  validateNotEmpty(errors, oldPass, newPass, confirmPass);
  validateMinLength(errors, newPass);
  validateMaxLength(errors, newPass);
  validatePasswordDiffersFromOldPassword(errors, newPass, oldPass);
  validatePasswordNotUsername(errors, newPass, username);
  validatePasswordsMatch(errors, newPass, confirmPass);
  return errors;
}

function validateNotEmpty(errors, oldPass, newPass, confirmPass) {
  if (oldPass === '' || newPass === '' || confirmPass === '') {
    const message = 'This field is required.';
    if (oldPass === '') {
      errors.oldPass = [message];
    }
    if (newPass === '') {
      errors.newPass = [message];
    }
    if (confirmPass === '') {
      errors.confirmPass = [message];
    }
  }
}

function validateMinLength(errors, newPass) {
  if (newPass !== '' && [...newPass].length < 8) {
    errors.newPass = [
      'Please enter a password that is at least 8 characters long.'
    ];
    if (!('confirmPass' in errors)) {
      errors.confirmPass = [];
    }
  }
}

function validateMaxLength(errors, newPass) {
  if ([...newPass].length > 250) {
    errors.newPass = [
      'Please enter a password that is no more than 250 characters.'
    ];
    if (!('confirmPass' in errors)) {
      errors.confirmPass = [];
    }
  }
}

function validatePasswordDiffersFromOldPassword(errors, newPass, oldPass) {
  if (newPass !== '' && newPass === oldPass) {
    const message = 'New password must be different from your old password.';
    if ('newPass' in errors) {
      errors.newPass.push(message);
    } else {
      errors.newPass = [message];
    }
    if (!('confirmPass' in errors)) {
      errors.confirmPass = [];
    }
  }
}

function validatePasswordNotUsername(errors, newPass, username) {
  if (newPass !== '' && newPass === username) {
    const message = 'New password cannot be the same as your username.';
    if ('newPass' in errors) {
      errors.newPass.push(message);
    } else {
      errors.newPass = [message];
    }
    if (!('confirmPass' in errors)) {
      errors.confirmPass = [];
    }
  }
}

function validatePasswordsMatch(errors, newPass, confirmPass) {
  if (newPass !== '' && confirmPass !== '' && newPass !== confirmPass) {
    errors.confirmPass = ["These passwords don't match.  Please try again."];
    if (!('newPass' in errors)) {
      errors.newPass = [];
    }
  }
}
