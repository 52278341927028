import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Colors } from '../styles';

export function Bold({ children }) {
  return (
    <strong
      css={css`
        color: ${Colors.gray8};
      `}
    >
      {children}
    </strong>
  );
}

Bold.propTypes = {
  children: PropTypes.node
};
