import moment from 'moment';

export const getLanguageName = (systemStatus, languageCode) => {
  const language = systemStatus.languages.find(
    language => language.code === languageCode
  );

  // Languages which aren't officially supported yet won't be
  // returned in the languages list
  return language?.name ?? `Unknown (${languageCode})`;
};

export const getProjectLanguageName = (systemStatus, project) =>
  getLanguageName(systemStatus, project.language);

export function formatTimestamp(timestamp) {
  return moment(timestamp * 1000)
    .utc()
    .format('ll');
}
