import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useUniqueId } from '../utils/hooks';
import { Checkbox } from '../components/core/Checkbox';

export default function SendEmailSelector({
  email,
  checked,
  onChange,
  additionalCss
}) {
  const checkboxId = useUniqueId();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        ${additionalCss}
      `}
    >
      <Checkbox
        id={checkboxId}
        checked={checked}
        onChange={evt => onChange(evt.target.checked)}
      />
      <label
        htmlFor={checkboxId}
        css={css`
          margin-left: 0.5rem;
          font-size: 1rem;
        `}
      >
        E-mail {email} when the project is ready
      </label>
    </div>
  );
}

SendEmailSelector.propTypes = {
  email: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalCss: PropTypes.object
};
