import _ from 'lodash';

import objToQueryString from './objToQueryString';

export default function (path, params = {}) {
  const paramsStringified =
    !_.isEmpty(params) && `?${objToQueryString(params)}`;

  return path + (paramsStringified || '');
}
