import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';

import Fade from './Fade';
import { ifAllExist } from '../../utils/common';
import { CloseButton } from '../CloseButton';
import { Colors, Mixins, ZIndexes } from '../../styles';

export default function SimpleModal({
  className,
  isOpen,
  onHide,
  hasCloseButton = !!onHide,
  children,
  trackingPrefix,
  onAfterOpen,
  header,
  containerCss,
  ...passedProps
}) {
  const overlayRef = useRef();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Modal
          {...passedProps}
          isOpen={isOpen}
          className={css`
            max-width: 60ch;
            min-width: 30rem;
            ${containerCss};
            z-index: ${ZIndexes.modal};
          `}
          overlayClassName={css`
            ${Mixins.fillViewport};
            // Make the background gray, with 80% opacity ("cc" as a hexadecimal value);
            background: ${Colors.gray11}cc;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: ${ZIndexes.modalBackground};
            position: fixed;
          `}
          onRequestClose={onHide}
          // react-modal doesn't allow passing data-attributes to the
          // clickable overlay, so we use a ref and onAfterOpen to
          // imperatively add the attribute to the overlay after creation.
          overlayRef={ref => {
            overlayRef.current = ref;
          }}
          onAfterOpen={() => {
            const overlay = overlayRef.current;
            if (overlay && trackingPrefix) {
              overlay.dataset.trackingItem = ifAllExist`${trackingPrefix}_overlay-click`;
            }
            onAfterOpen?.();
          }}
        >
          <Fade show={isOpen}>
            <div
              data-test-id="modal"
              className={cx(
                css`
                  ${Mixins.roundedCorners};
                  ${Mixins.shadowOutsetHeavy};
                  position: relative;
                  background-color: white;
                  background-clip: padding-box;
                  padding: 1rem;
                `,
                className
              )}
            >
              {(header || hasCloseButton) && (
                <Header>
                  {header}
                  {hasCloseButton && (
                    <CloseButton
                      data-tracking-item={ifAllExist`${trackingPrefix}_close-button`}
                      onClick={onHide}
                    />
                  )}
                </Header>
              )}
              {children}
            </div>
          </Fade>
        </Modal>
      )}
    </ClassNames>
  );
}

SimpleModal.propTypes = {
  className: PropTypes.string,
  // If present, data-tracking-item attributes will be added to the
  // modal overlay and the close button
  prefix: PropTypes.string,
  isOpen: PropTypes.bool,
  // Called when the modal's background is clicked
  onHide: PropTypes.func,
  children: PropTypes.node,
  trackingPrefix: PropTypes.string,
  onAfterOpen: PropTypes.func,
  header: PropTypes.node
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
