import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './core/Button';
import SimpleModal from './core/SimpleModal';
import DialogFooter from './DialogFooter';

export default function ConfirmationDialog({
  cancelText = 'Cancel',
  confirmText = 'Ok',
  autoFocusConfirm = true,
  showing,
  children,
  onConfirm,
  onCancel,
  trackingItemConfirm,
  trackingItemCancel
}) {
  return (
    <SimpleModal className="confirmation-dialog" isOpen={showing}>
      <div className="confirmation-dialog__question">{children}</div>
      <DialogFooter>
        <Button
          autoFocus={autoFocusConfirm}
          style={{ marginRight: '0.5rem' }}
          onClick={onConfirm}
          data-tracking-item={trackingItemConfirm}
        >
          {confirmText}
        </Button>
        {onCancel && (
          <Button
            palette="red"
            onClick={onCancel}
            data-tracking-item={trackingItemCancel}
          >
            {cancelText}
          </Button>
        )}
      </DialogFooter>
    </SimpleModal>
  );
}

ConfirmationDialog.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  autoFocusConfirm: PropTypes.bool,
  trackingItemCancel: PropTypes.string,
  trackingItemConfirm: PropTypes.string
};
