import moment from 'moment';

import { load } from '../../../lui/coffee/utils/localStorage';
import branding_data from '../../../brandings.json';

const startCopyrightYear = 2019;
const currentYear = moment.utc().year();

const copyrightRange =
  currentYear > startCopyrightYear
    ? `${startCopyrightYear} - ${currentYear}`
    : startCopyrightYear;

const branding = load('branding');
let trademark = `Luminoso Daylight®, powered by QuickLearn® | © ${copyrightRange} Luminoso Technologies. All rights reserved.`;

if (branding) {
  const footer_trademark = branding_data[`${branding}`].footer_trademark;
  trademark = footer_trademark;
}

export { trademark };
