import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function Funnel({ iconColor, widthName, ...props }) {
  let path;
  switch (widthName) {
    case 'light':
      path =
        'M8.67278 14.8564L9.02464 14.4336L8.67278 14.8564ZM7.0944 13.5426L6.74254 13.9653L7.0944 13.5426ZM13.4267 1.02265L13.4267 1.57265L13.4267 1.02265ZM9.79463 6.9596L10.1785 7.35345L9.79463 6.9596ZM10.1785 7.35345L14.5086 3.13257L13.7408 2.34489L9.41072 6.56576L10.1785 7.35345ZM13.4267 0.472654L2.63179 0.472646L2.63179 1.57265L13.4267 1.57265L13.4267 0.472654ZM1.56807 3.15003L6.04297 7.37227L6.79787 6.57219L2.32297 2.34995L1.56807 3.15003ZM7.28415 12.774V7.69957H6.18415V12.774H7.28415ZM9.02464 14.4336L7.44626 13.1199L6.74254 13.9653L8.32092 15.2791L9.02464 14.4336ZM8.94265 7.67568V14.4721H10.0427V7.67568H8.94265ZM8.32092 15.2791C9.00492 15.8484 10.0427 15.362 10.0427 14.4721H8.94265C8.94265 14.4678 8.94481 14.4558 8.95282 14.4438C8.95944 14.4339 8.96654 14.4291 8.97143 14.4268C8.97633 14.4245 8.98455 14.4221 8.99641 14.4234C9.01075 14.4249 9.02137 14.4309 9.02464 14.4336L8.32092 15.2791ZM6.18415 12.774C6.18415 13.2343 6.38875 13.6708 6.74254 13.9653L7.44626 13.1199C7.34355 13.0344 7.28415 12.9076 7.28415 12.774H6.18415ZM6.04297 7.37227C6.13308 7.45728 6.18415 7.57569 6.18415 7.69957H7.28415C7.28415 7.27286 7.10824 6.86503 6.79787 6.57219L6.04297 7.37227ZM2.63179 0.472646C1.22908 0.472645 0.547815 2.18738 1.56807 3.15003L2.32297 2.34995C2.02676 2.07047 2.22455 1.57265 2.63179 1.57265L2.63179 0.472646ZM14.5086 3.13257C15.5043 2.162 14.8171 0.472655 13.4267 0.472654L13.4267 1.57265C13.8303 1.57265 14.0298 2.06311 13.7408 2.34489L14.5086 3.13257ZM9.41072 6.56576C9.11144 6.8575 8.94265 7.25773 8.94265 7.67568H10.0427C10.0427 7.55434 10.0917 7.43814 10.1785 7.35345L9.41072 6.56576Z';
      break;
    case 'bolder':
      path =
        'M8.67278 14.8564L9.24855 14.1646L8.67278 14.8564ZM7.0944 13.5426L6.51863 14.2343L7.0944 13.5426ZM13.4267 1.02265L13.4267 1.92265L13.4267 1.02265ZM9.79463 6.9596L10.4229 7.60407L9.79463 6.9596ZM10.4229 7.60407L14.7529 3.3832L13.4965 2.09426L9.16641 6.31513L10.4229 7.60407ZM13.4267 0.122654L2.63179 0.122646L2.63179 1.92265L13.4267 1.92265L13.4267 0.122654ZM1.32787 3.4046L5.80277 7.62684L7.03807 6.31762L2.56317 2.09538L1.32787 3.4046ZM7.63415 12.774V7.69957H5.83415V12.774H7.63415ZM9.24855 14.1646L7.67017 12.8508L6.51863 14.2343L8.09701 15.5481L9.24855 14.1646ZM8.59265 7.67568V14.4721H10.3927V7.67568H8.59265ZM8.09701 15.5481C9.00901 16.3072 10.3927 15.6587 10.3927 14.4721H8.59265C8.59265 14.133 8.98798 13.9477 9.24855 14.1646L8.09701 15.5481ZM5.83415 12.774C5.83415 13.3382 6.08495 13.8733 6.51863 14.2343L7.67017 12.8508C7.64735 12.8318 7.63415 12.8037 7.63415 12.774H5.83415ZM5.80277 7.62684C5.8228 7.64573 5.83415 7.67204 5.83415 7.69957H7.63415C7.63415 7.17651 7.41851 6.67658 7.03807 6.31762L5.80277 7.62684ZM2.63179 0.122646C0.912333 0.122645 0.0772396 2.22458 1.32787 3.4046L2.56317 2.09538C2.53691 2.0706 2.53282 2.05436 2.53119 2.04599C2.52854 2.03241 2.52893 2.01071 2.53886 1.98572C2.54879 1.96074 2.56339 1.94469 2.57464 1.93662C2.58157 1.93166 2.59569 1.92265 2.63179 1.92265L2.63179 0.122646ZM14.7529 3.3832C15.9734 2.19347 15.1311 0.122656 13.4267 0.122654L13.4267 1.92265C13.4621 1.92265 13.4763 1.93147 13.4834 1.93655C13.4948 1.9446 13.5093 1.96044 13.5193 1.98498C13.5293 2.00951 13.5299 2.031 13.5274 2.04467C13.5258 2.0533 13.5218 2.06952 13.4965 2.09426L14.7529 3.3832ZM9.16641 6.31513C8.79955 6.67275 8.59265 7.16336 8.59265 7.67568H10.3927C10.3927 7.64872 10.4035 7.62289 10.4229 7.60407L9.16641 6.31513Z';
      break;
    default:
      path =
        'M8.67278 14.8564L9.15259 14.2799L8.67278 14.8564ZM7.0944 13.5426L6.61459 14.119L7.0944 13.5426ZM13.4267 1.02265L13.4267 1.77265L13.4267 1.02265ZM9.79463 6.9596L10.3181 7.49666L9.79463 6.9596ZM10.3181 7.49666L14.6482 3.27579L13.6012 2.20167L9.27112 6.42254L10.3181 7.49666ZM13.4267 0.272654L2.63179 0.272646L2.63179 1.77265L13.4267 1.77265L13.4267 0.272654ZM1.43081 3.2955L5.90572 7.51774L6.93513 6.42672L2.46022 2.20448L1.43081 3.2955ZM7.48415 12.774V7.69957H5.98415V12.774H7.48415ZM9.15259 14.2799L7.57421 12.9661L6.61459 14.119L8.19297 15.4328L9.15259 14.2799ZM8.74265 7.67568V14.4721H10.2427V7.67568H8.74265ZM8.19297 15.4328C9.00726 16.1106 10.2427 15.5315 10.2427 14.4721H8.74265C8.74265 14.2602 8.98973 14.1444 9.15259 14.2799L8.19297 15.4328ZM5.98415 12.774C5.98415 13.2937 6.21515 13.7865 6.61459 14.119L7.57421 12.9661C7.51715 12.9186 7.48415 12.8482 7.48415 12.774H5.98415ZM5.90572 7.51774C5.95578 7.56497 5.98415 7.63075 5.98415 7.69957H7.48415C7.48415 7.2178 7.28554 6.75735 6.93513 6.42672L5.90572 7.51774ZM2.63179 0.272646C1.04808 0.272645 0.278915 2.20864 1.43081 3.2955L2.46022 2.20448C2.29567 2.04921 2.40555 1.77265 2.63179 1.77265L2.63179 0.272646ZM14.6482 3.27579C15.7724 2.17998 14.9965 0.272656 13.4267 0.272654L13.4267 1.77265C13.6509 1.77265 13.7618 2.04513 13.6012 2.20167L14.6482 3.27579ZM9.27112 6.42254C8.93322 6.75192 8.74265 7.2038 8.74265 7.67568H10.2427C10.2427 7.60827 10.2699 7.54372 10.3181 7.49666L9.27112 6.42254Z';
  }
  return (
    <SVGWrapper {...props}>
      <path d={path} fill={iconColor} />
    </SVGWrapper>
  );
}
