import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import ScienceChangeLog from '../components/ScienceChangeLog';
import { getBuildChanges } from './utils';
import { InlineChangeArrow } from './components';

// Note that this component could make its own call for conceptManagement, but
// it's currently more convenient to pass it as a prop for storybook purposes.
export default function BuildChangesSummary({
  project,
  serverStatus,
  conceptManagement,
  detailedDiff = true,
  className,collocationNumbers
}) {
  const buildChangesIndicator = getBuildChanges(
    project,
    serverStatus,
    conceptManagement,collocationNumbers
  );
  const scienceVersion = project.last_build_info.science_version;
  const serverScienceVersion = serverStatus.science_version;

  const scrollboxNode = useRef(null);
  const noStickNode = useRef(null);

  const [isScrolling, setIsScrolling] = useState(false);
  function scrollboxScroll() {
    if (scrollboxNode.current.scrollTop > noStickNode.current.scrollHeight) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  }
  return (
    <Container
      className={className}
      ref={scrollboxNode}
      onScroll={scrollboxScroll}
    >
      <div ref={noStickNode}>
        {buildChangesIndicator.changingLanguage && (
          <li css={centerIcons}>
            Language: {buildChangesIndicator.currentLanguageName}
            <InlineChangeArrow />
            <strong>{buildChangesIndicator.nextLanguageName}</strong>
          </li>
        )}
        {buildChangesIndicator.upgradingScience && (
          <li css={centerIcons}>
            Science version: {scienceVersion}
            <InlineChangeArrow />
            <strong>{serverScienceVersion}</strong>
            {!detailedDiff && <ScienceChangeLog />}
          </li>
        )}
        {buildChangesIndicator.addingSentiment && <li>Upgrading sentiment</li>}
      </div>
      {buildChangesIndicator.hasUnbuiltAssertions && (
        <ConceptManagementChanges
          conceptManagement={conceptManagement}
          detailedDiff={detailedDiff}
          isScrolling={isScrolling}
        />
      )}
      {buildChangesIndicator.changingCollocation && (
        <li css={centerIcons}>
          Maximum number of Concept to collocate: {buildChangesIndicator.currentNumberCollocation}
          <InlineChangeArrow />
          <strong>{buildChangesIndicator.nextNumberCollocation}</strong>
        </li>
      )}
    </Container>
  );
}

BuildChangesSummary.propTypes = {
  project: PropTypes.object.isRequired,
  serverStatus: PropTypes.object.isRequired,
  conceptManagement: PropTypes.object
};

const Container = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  li:not(:last-child) {
    margin-bottom: 0.25rem;
  }
  box-sizing: border-box;
  padding: 0.75rem;
  overflow-y: auto;
  max-height: 50vh;
`;

function ConceptManagementChanges({
  conceptManagement,
  detailedDiff,
  isScrolling
}) {
  // Before we fetch the information from the API, conceptManagement will be
  // undefined
  if (conceptManagement === undefined) {
    return null;
  }

  return (
    <>
      {conceptManagement?.hasIgnoreChanges && (
        <IgnoreConceptsChangesDisplay
          conceptManagement={conceptManagement}
          detailedDiff={detailedDiff}
          isScrolling={isScrolling}
        />
      )}
      {conceptManagement?.hasMergeChanges && (
        <MergeConceptsChangesDisplay
          conceptManagement={conceptManagement}
          detailedDiff={detailedDiff}
          isScrolling={isScrolling}
        />
      )}
      {conceptManagement?.hasNoticeChanges && (
        <NoticeConceptsChangesDisplay
          conceptManagement={conceptManagement}
          detailedDiff={detailedDiff}
          isScrolling={isScrolling}
        />
      )}
      {conceptManagement?.hasCollocateChanges && (
        <CollocateConceptsChangesDisplay
          conceptManagement={conceptManagement}
          detailedDiff={detailedDiff}
          isScrolling={isScrolling}
        />
      )}
    </>
  );
}

function IgnoreConceptsChangesDisplay({
  conceptManagement,
  detailedDiff,
  isScrolling
}) {
  const summary = conceptManagement.ignoreSummaryString;
  const nextConcepts = conceptManagement.nextBuild.ignore;
  const stickyStyles = css`
    position: sticky;
  `;
  const scrollingStyles = css`
    top: -12px;
    padding: 4px 12px;
    margin: -4px -12px;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px #0000001a;
  `;

  let detailScrolling = detailedDiff && isScrolling;
  let multiAssertion = nextConcepts.length !== 1;

  return (
    <>
      <li
        css={css`
          ${detailedDiff && stickyStyles}
          ${detailScrolling && scrollingStyles}
          ${centerIcons}
        `}
      >
        Ignore: {summary}
        <InlineChangeArrow />
        <strong>
          {nextConcepts.length} Ignore assertion{multiAssertion && 's'}
        </strong>
      </li>

      {detailedDiff && (
        <div
          css={css`
            margin-left: 2rem;
          `}
        >
          {nextConcepts.map(({ concept }, i) => {
            return <li key={i}> {concept} </li>;
          })}
        </div>
      )}
    </>
  );
}

const centerIcons = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 0.5rem;
`;

function MergeConceptsChangesDisplay({
  conceptManagement,
  detailedDiff,
  isScrolling
}) {
  const summary = conceptManagement.mergeSummaryString;
  const nextConcepts = conceptManagement.nextBuild.merge;
  const stickyStyles = css`
    position: sticky;
  `;
  const scrollingStyles = css`
    top: -12px;
    padding: 4px 12px;
    margin: -4px -12px;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px #0000001a;
  `;

  let detailScrolling = detailedDiff && isScrolling;
  let multiAssertion = nextConcepts.length !== 1;

  return (
    <>
      <li
        css={css`
          ${detailedDiff && stickyStyles}
          ${detailScrolling && scrollingStyles}
          ${centerIcons}
        `}
      >
        Merge: {summary}
        <InlineChangeArrow />
        <strong>
          {nextConcepts.length} Merge assertion{multiAssertion && 's'}
        </strong>
      </li>

      {detailedDiff && (
        <div
          css={css`
            margin-left: 2rem;
          `}
        >
          {nextConcepts.map(({ concept, merge_with }, i) => {
            return (
              <li key={i}>
                {' '}
                {concept} merged with {merge_with}{' '}
              </li>
            );
          })}
        </div>
      )}
    </>
  );
}

function NoticeConceptsChangesDisplay({
  conceptManagement,
  detailedDiff,
  isScrolling
}) {
  const summary = conceptManagement.noticeSummaryString;
  const nextConcepts = conceptManagement.nextBuild.notice;
  const stickyStyles = css`
    position: sticky;
  `;
  const scrollingStyles = css`
    top: -12px;
    padding: 4px 12px;
    margin: -4px -12px;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px #0000001a;
  `;

  let detailScrolling = detailedDiff && isScrolling;
  let multiAssertion = nextConcepts.length !== 1;

  return (
    <>
      <li
        css={css`
          ${detailedDiff && stickyStyles}
          ${detailScrolling && scrollingStyles}
          ${centerIcons}
        `}
      >
        Notice: {summary}
        <InlineChangeArrow />
        <strong>
          {nextConcepts.length} Notice assertion{multiAssertion && 's'}
        </strong>
      </li>

      {detailedDiff && (
        <div
          css={css`
            margin-left: 2rem;
          `}
        >
          {nextConcepts.map(({ concept }, i) => {
            return <li key={i}> {concept} </li>;
          })}
        </div>
      )}
    </>
  );
}

function CollocateConceptsChangesDisplay({
  conceptManagement,
  detailedDiff,
  isScrolling
}) {
  const summary = conceptManagement.collocateSummaryString;
  const nextConcepts = conceptManagement.nextBuild.collocate;
  const stickyStyles = css`
    position: sticky;
  `;
  const scrollingStyles = css`
    top: -12px;
    padding: 4px 12px;
    margin: -4px -12px;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px #0000001a;
  `;

  let detailScrolling = detailedDiff && isScrolling;
  let multiAssertion = nextConcepts.length !== 1;

  return (
    <>
      <li
        css={css`
          ${detailedDiff && stickyStyles}
          ${detailScrolling && scrollingStyles}
          ${centerIcons}
        `}
      >
        Collocate: {summary}
        <InlineChangeArrow />
        <strong>
          {nextConcepts.length} Collocate assertion{multiAssertion && 's'}
        </strong>
      </li>

      {detailedDiff && (
        <div
          css={css`
            margin-left: 2rem;
          `}
        >
          {nextConcepts.map(({ concept }, i) => {
            return <li key={i}> {concept} </li>;
          })}
        </div>
      )}
    </>
  );
}
