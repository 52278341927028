import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Table = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path;

  if (widthName === 'light') {
    path =
      'M2.07147 5.48045H13.9285M5.42826 5.03581V13.4345M2.07147 9.4998H13.9285M3.05956 13.9286H12.9404C13.4861 13.9286 13.9285 13.4862 13.9285 12.9405V3.05965C13.9285 2.51394 13.4861 2.07156 12.9404 2.07156H3.05956C2.51385 2.07156 2.07147 2.51394 2.07147 3.05965V12.9405C2.07147 13.4862 2.51385 13.9286 3.05956 13.9286Z';
  } else {
    path =
      'M2.07147 5.48042H13.9285M5.42826 5.03578V13.4345M2.07147 9.49977H13.9285M3.05956 13.9285H12.9404C13.4861 13.9285 13.9285 13.4862 13.9285 12.9404V3.05962C13.9285 2.51391 13.4861 2.07153 12.9404 2.07153H3.05956C2.51385 2.07153 2.07147 2.51391 2.07147 3.05962V12.9404C2.07147 13.4862 2.51385 13.9285 3.05956 13.9285Z';
  }

  return (
    <SVGWrapper {...props}>
      <path d={path} stroke={iconColor} strokeWidth={lineWidth} />
    </SVGWrapper>
  );
};
