import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function Chart({ iconColor, thickness, widthName, ...props }) {
  return (
    <SVGWrapper {...props}>
      <Icon iconColor={iconColor} widthName={widthName} />
    </SVGWrapper>
  );
}

const Icon = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <>
          <path
            d="M3.53402 4.67091L5.42941 3.26347M8.84483 4.72716L7.08082 3.33853M10.6089 4.74595L12.523 3.07577M2.67608 14.6614C2.52039 14.6614 2.39418 14.5351 2.39418 14.3795V10.8959C2.39418 10.7402 2.52039 10.614 2.67608 10.614C2.83177 10.614 2.95799 10.7402 2.95799 10.8959V14.3795C2.95799 14.5351 2.83177 14.6614 2.67608 14.6614ZM9.72686 14.6614C9.57038 14.6614 9.44353 14.5345 9.44353 14.378V10.8973C9.44353 10.7408 9.57038 10.614 9.72686 10.614C9.88334 10.614 10.0102 10.7408 10.0102 10.8973V14.378C10.0102 14.5345 9.88334 14.6614 9.72686 14.6614ZM6.24139 14.6614C6.08491 14.6614 5.95806 14.5346 5.95806 14.3781V8.2781C5.95806 8.12162 6.08491 7.99477 6.24139 7.99477C6.39787 7.99477 6.52473 8.12162 6.52473 8.2781V14.3781C6.52473 14.5346 6.39787 14.6614 6.24139 14.6614ZM13.3239 14.6614C13.1674 14.6614 13.0405 14.5346 13.0405 14.3781V8.2781C13.0405 8.12162 13.1674 7.99477 13.3239 7.99477C13.4803 7.99477 13.6072 8.12162 13.6072 8.27811V14.3781C13.6072 14.5346 13.4803 14.6614 13.3239 14.6614ZM3.67609 5.28621C3.67609 5.83849 3.22837 6.28621 2.67609 6.28621C2.1238 6.28621 1.67609 5.83849 1.67609 5.28621C1.67609 4.73392 2.1238 4.28621 2.67609 4.28621C3.22837 4.28621 3.67609 4.73392 3.67609 5.28621ZM7.24139 2.68292C7.24139 3.23521 6.79368 3.68292 6.24139 3.68292C5.68911 3.68292 5.24139 3.23521 5.24139 2.68292C5.24139 2.13064 5.68911 1.68292 6.24139 1.68292C6.79368 1.68292 7.24139 2.13064 7.24139 2.68292ZM10.7269 5.31399C10.7269 5.86627 10.2791 6.31399 9.72685 6.31399C9.17457 6.31399 8.72685 5.86627 8.72685 5.31399C8.72685 4.7617 9.17457 4.31399 9.72685 4.31399C10.2791 4.31399 10.7269 4.7617 10.7269 5.31399ZM14.3239 2.33856C14.3239 2.89085 13.8762 3.33856 13.3239 3.33856C12.7716 3.33856 12.3239 2.89085 12.3239 2.33856C12.3239 1.78628 12.7716 1.33856 13.3239 1.33856C13.8762 1.33856 14.3239 1.78628 14.3239 2.33856Z"
            stroke={iconColor}
            strokeWidth="1.1"
            strokeLinecap="round"
          />
        </>
      );
    case 'bolder':
      return (
        <>
          <path
            d="M3.67609 5.28621C3.67609 5.83849 3.22837 6.28621 2.67609 6.28621C2.1238 6.28621 1.67609 5.83849 1.67609 5.28621C1.67609 4.73392 2.1238 4.28621 2.67609 4.28621C3.22837 4.28621 3.67609 4.73392 3.67609 5.28621Z"
            fill="#2C4249"
          />
          <path
            d="M7.24139 2.68292C7.24139 3.23521 6.79368 3.68292 6.24139 3.68292C5.90608 3.68292 5.60931 3.51788 5.42788 3.2646C5.31049 3.10072 5.24139 2.89989 5.24139 2.68292C5.24139 2.13064 5.68911 1.68292 6.24139 1.68292C6.79368 1.68292 7.24139 2.13064 7.24139 2.68292Z"
            fill="#2C4249"
          />
          <path
            d="M10.7269 5.31399C10.7269 5.86627 10.2791 6.31399 9.72685 6.31399C9.17457 6.31399 8.72685 5.86627 8.72685 5.31399C8.72685 4.7617 9.17457 4.31399 9.72685 4.31399C10.2791 4.31399 10.7269 4.7617 10.7269 5.31399Z"
            fill="#2C4249"
          />
          <path
            d="M14.3239 2.33856C14.3239 2.89085 13.8762 3.33856 13.3239 3.33856C12.7716 3.33856 12.3239 2.89085 12.3239 2.33856C12.3239 1.78628 12.7716 1.33856 13.3239 1.33856C13.8762 1.33856 14.3239 1.78628 14.3239 2.33856Z"
            fill="#2C4249"
          />
          <path
            d="M3.53402 4.67091L5.42788 3.2646M5.42941 3.26347L5.42788 3.2646M8.84483 4.72716L7.08082 3.33853M10.6089 4.74595L12.523 3.07577M5.42788 3.2646C5.60931 3.51788 5.90608 3.68292 6.24139 3.68292C6.79368 3.68292 7.24139 3.23521 7.24139 2.68292C7.24139 2.13064 6.79368 1.68292 6.24139 1.68292C5.68911 1.68292 5.24139 2.13064 5.24139 2.68292C5.24139 2.89989 5.31049 3.10072 5.42788 3.2646ZM2.67608 14.6614C2.52039 14.6614 2.39418 14.5351 2.39418 14.3795V10.8959C2.39418 10.7402 2.52039 10.614 2.67608 10.614C2.83177 10.614 2.95799 10.7402 2.95799 10.8959V14.3795C2.95799 14.5351 2.83177 14.6614 2.67608 14.6614ZM9.72686 14.6614C9.57038 14.6614 9.44353 14.5345 9.44353 14.378V10.8973C9.44353 10.7408 9.57038 10.614 9.72686 10.614C9.88334 10.614 10.0102 10.7408 10.0102 10.8973V14.378C10.0102 14.5345 9.88334 14.6614 9.72686 14.6614ZM6.24139 14.6614C6.08491 14.6614 5.95806 14.5346 5.95806 14.3781V8.2781C5.95806 8.12162 6.08491 7.99477 6.24139 7.99477C6.39787 7.99477 6.52473 8.12162 6.52473 8.2781V14.3781C6.52473 14.5346 6.39787 14.6614 6.24139 14.6614ZM13.3239 14.6614C13.1674 14.6614 13.0405 14.5346 13.0405 14.3781V8.2781C13.0405 8.12162 13.1674 7.99477 13.3239 7.99477C13.4803 7.99477 13.6072 8.12162 13.6072 8.27811V14.3781C13.6072 14.5346 13.4803 14.6614 13.3239 14.6614ZM3.67609 5.28621C3.67609 5.83849 3.22837 6.28621 2.67609 6.28621C2.1238 6.28621 1.67609 5.83849 1.67609 5.28621C1.67609 4.73392 2.1238 4.28621 2.67609 4.28621C3.22837 4.28621 3.67609 4.73392 3.67609 5.28621ZM10.7269 5.31399C10.7269 5.86627 10.2791 6.31399 9.72685 6.31399C9.17457 6.31399 8.72685 5.86627 8.72685 5.31399C8.72685 4.7617 9.17457 4.31399 9.72685 4.31399C10.2791 4.31399 10.7269 4.7617 10.7269 5.31399ZM14.3239 2.33856C14.3239 2.89085 13.8762 3.33856 13.3239 3.33856C12.7716 3.33856 12.3239 2.89085 12.3239 2.33856C12.3239 1.78628 12.7716 1.33856 13.3239 1.33856C13.8762 1.33856 14.3239 1.78628 14.3239 2.33856Z"
            stroke={iconColor}
            strokeWidth="1.8"
            strokeLinecap="round"
          />
        </>
      );
    default:
      return (
        <path
          d="M3.53402 4.67091L5.42941 3.26347M8.84483 4.72716L7.08082 3.33853M10.6089 4.74595L12.523 3.07577M2.67608 14.6614C2.52039 14.6614 2.39418 14.5351 2.39418 14.3795V10.8959C2.39418 10.7402 2.52039 10.614 2.67608 10.614C2.83177 10.614 2.95799 10.7402 2.95799 10.8959V14.3795C2.95799 14.5351 2.83177 14.6614 2.67608 14.6614ZM9.72686 14.6614C9.57038 14.6614 9.44353 14.5345 9.44353 14.378V10.8973C9.44353 10.7408 9.57038 10.614 9.72686 10.614C9.88334 10.614 10.0102 10.7408 10.0102 10.8973V14.378C10.0102 14.5345 9.88334 14.6614 9.72686 14.6614ZM6.24139 14.6614C6.08491 14.6614 5.95806 14.5346 5.95806 14.3781V8.2781C5.95806 8.12162 6.08491 7.99477 6.24139 7.99477C6.39787 7.99477 6.52473 8.12162 6.52473 8.2781V14.3781C6.52473 14.5346 6.39787 14.6614 6.24139 14.6614ZM13.3239 14.6614C13.1674 14.6614 13.0405 14.5346 13.0405 14.3781V8.2781C13.0405 8.12162 13.1674 7.99477 13.3239 7.99477C13.4803 7.99477 13.6072 8.12162 13.6072 8.27811V14.3781C13.6072 14.5346 13.4803 14.6614 13.3239 14.6614ZM3.67609 5.28621C3.67609 5.83849 3.22837 6.28621 2.67609 6.28621C2.1238 6.28621 1.67609 5.83849 1.67609 5.28621C1.67609 4.73392 2.1238 4.28621 2.67609 4.28621C3.22837 4.28621 3.67609 4.73392 3.67609 5.28621ZM7.24139 2.68292C7.24139 3.23521 6.79368 3.68292 6.24139 3.68292C5.68911 3.68292 5.24139 3.23521 5.24139 2.68292C5.24139 2.13064 5.68911 1.68292 6.24139 1.68292C6.79368 1.68292 7.24139 2.13064 7.24139 2.68292ZM10.7269 5.31399C10.7269 5.86627 10.2791 6.31399 9.72685 6.31399C9.17457 6.31399 8.72685 5.86627 8.72685 5.31399C8.72685 4.7617 9.17457 4.31399 9.72685 4.31399C10.2791 4.31399 10.7269 4.7617 10.7269 5.31399ZM14.3239 2.33856C14.3239 2.89085 13.8762 3.33856 13.3239 3.33856C12.7716 3.33856 12.3239 2.89085 12.3239 2.33856C12.3239 1.78628 12.7716 1.33856 13.3239 1.33856C13.8762 1.33856 14.3239 1.78628 14.3239 2.33856Z"
          stroke={iconColor}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      );
  }
};
