import React, {
  useEffect,
  useReducer,
  useRef,
  createContext,
  useContext
} from 'react';

import { RequestStatuses } from '../../constants';

const FetchErrorHandlerContext = createContext();

/**
 * The FetchErrorHandler can be used to describe how the useFetch hook
 * should react to certain errors, within a certain app scope.
 *
 * The handler component should be passed an errorHandler function which is of
 * type (error) => boolean. It receives whatever error is thrown by the useFetch
 * call. It should return true if the error was handled and the useFetch call
 * should just return, and false if the error was not handled.
 *
 * In the case of a false return, any outer containing FetchErrorHandlers are
 * called with the error. If none of the handlers handle the error (ie they all
 * return false) the rest of the useFetch hook continues to run and return an
 * error response.
 */
export const FetchErrorHandler = ({ children, errorHandler }) => {
  const parentHandler = useContext(FetchErrorHandlerContext);
  const handler = error => errorHandler(error) || parentHandler?.(error);
  return (
    <FetchErrorHandlerContext.Provider value={handler}>
      {children}
    </FetchErrorHandlerContext.Provider>
  );
};

/**
 * Make a request, and return the status of the request along with the
 * response.
 *
 * In order to prevent making the request until some information is
 * ready, pass a non-function in place of the fetcher parameter.
 *
 * @param {!function} fetcher A promise-returning function which will
 * make the request when called.
 * @param  {...any} args Arguments which fetcher will be called with.
 *
 * @return {{status: string, response: any}} status is any of the
 * RequestStatuses, and is initially PENDING.
 */
export function useFetch(fetcher, ...args) {
  const [{ status, response }, dispatch] = useReducer(fetchReducer, {
    response: undefined,
    status: RequestStatuses.PENDING
  });
  const request = useRef({});
  const errorHandler = useContext(FetchErrorHandlerContext);

  function refetch(showPendingState) {
    if (showPendingState) {
      dispatch({ type: RequestStatuses.PENDING });
    }
    abortCurrentRequest(); // in case there's an earlier fetch still pending
    const thisRequest = { canceled: false };
    request.current = thisRequest;
    if (typeof fetcher === 'function') {
      thisRequest.promise = fetcher
        .apply(null, args)
        .then(response => {
          if (thisRequest.canceled) {
            return;
          }
          dispatch({ type: RequestStatuses.FULFILLED, response });
        })
        .catch(error => {
          // - run any containing error handlers
          // - the call to errorHandler?.(error) runs the code necessary to
          //   handle any errors and returns whether the error was handled.
          const errorHandled = errorHandler?.(error);
          if (errorHandled) {
            return;
          }

          if (thisRequest.canceled) {
            return;
          }
          dispatch({ type: RequestStatuses.REJECTED, response: error });
        });
    } else {
      // TODO If there is no request to make what should be done? Should this be an error?
      dispatch({ type: RequestStatuses.PENDING });
    }
  }

  function abortCurrentRequest() {
    request.current.canceled = true;
    if (request.current.promise && request.current.promise.abort) {
      request.current.promise.abort();
    }
  }

  useEffect(() => {
    refetch(true);
    return () => {
      abortCurrentRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, ...prepareArgsForDiffing(args)]);

  return { status, response, refetch };
}

function fetchReducer(state, action) {
  switch (action.type) {
    case RequestStatuses.PENDING:
      return {
        response: undefined,
        status: action.type
      };
    case RequestStatuses.FULFILLED:
    case RequestStatuses.REJECTED:
      return {
        response: action.response,
        status: action.type
      };
  }
}

function prepareArgsForDiffing(args) {
  return args.map(arg => {
    if (arg === undefined) {
      // undefined should be passed through as is, so that if an argument
      // changes from undefined to null it causes a refetch
      return arg;
    }
    return JSON.stringify(arg);
  });
}
