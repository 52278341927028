# localStorage keys used throughout the app
KEYS =
  sortProjectsBy: 'sortProjectsBy',
  projectListWorkspaceId: 'projectListWorkspaceId'
  token: 'auth_token'
  user: 'username'

UNSTRINGIFIED_KEYS = [
  KEYS.sortProjectsBy
  KEYS.user
]

KEY_VALS = (val for key, val of KEYS)

assertKey = (key, cb) ->
  throw new Error "Invalid storage key: #{key}" unless key in KEY_VALS
  cb()

save = (key, value) ->
  # Save a value to local storage.
  assertKey key, ->
    localStorage[key] =
      if key in UNSTRINGIFIED_KEYS
        value
      else
        JSON.stringify(value)

    value

load = (key, defaultValue) ->
  # Load a value from local storage, optionally returning a default value.
  assertKey key, ->
    if localStorage.hasOwnProperty(key)
      if key in UNSTRINGIFIED_KEYS
        localStorage[key]
      else
        JSON.parse(localStorage[key])
    else
      defaultValue

clear = (key) -> assertKey key, -> delete localStorage[key]

module.exports = {KEYS, save, load, clear}
