import { useReducer } from 'react';

/**
 * Hook that provides a function you can call to force an update of the
 * component that used the hook.
 * @returns {Function}
 */
export function useForceUpdate() {
  const [, dispatch] = useReducer(runCount => runCount + 1, 1);
  return dispatch;
}
