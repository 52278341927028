import { getAPIStatus } from './utils/ApiUtilsV5';

export default function setUpGitRevsWindowAttribute() {
  // eslint-disable-next-line no-undef
  window.gitRevs = { analytics_ui: __BUILD_GIT_REV__ };

  getAPIStatus().then(response => {
    Object.assign(window.gitRevs, response.package_git_revs[0]);
  });
}
