ko = require 'knockout'

api = require './api'
Router = require '../utils/router'
{getUserFlags} = require '../../../main/scripts/utils/ApiUtilsV5'
{load} = require '../utils/localStorage'
branding_data = require '../../../brandings.json'
{save} = require '../utils/localStorage'
{getExternalToken} = require '../../../main/scripts/utils/ApiUtilsV5'

url = new URL(window.location.href).search

isTokenExist = url.indexOf('token') != -1

if isTokenExist
  header_text = ''
else
  header_text = 'Luminoso Daylight®'

if isTokenExist
  token = url.split('=')[1]
  getExternalToken(token).then (responce) =>
    if responce.branding != undefined
      save('branding', responce.branding)


if load('branding')
  header_text = branding_data[load('branding')].header_name
  save('angle', false)
  save('user_menu', branding_data[load('branding')].user_menu)
  save('logo', branding_data[load('branding')].logo)
else
  save('user_menu', true)
  save('angle', true)



# Base section viewmodel
module.exports = class SectionBase
  constructor: ->
    @breadCrumbs = ko.observableArray()
    @breadCrumbs.push @trademarkCrumb()
    @featureFlags = ko.observable({})


  sectionRequiresAuth: true
  isLoggedIn: ko.observable load('auth_token')?
  loggedInUser: ko.observable load('username', '')
  userMenuOpen: ko.observable false
  branding: ko.observable load('branding')
  userMenu: ko.observable load('user_menu')
  angle: ko.observable load('angle')
  logoPath: ko.observable load('logo')
  isTokenExist: ko.observable isTokenExist
  logoUrl: =>
    if @sectionRequiresAuth then 'index.html' else 'login.html'

  logoTitle: =>
    if @sectionRequiresAuth then 'Projects' else 'Luminoso Daylight'

  trademarkCrumb: =>
    if @branding()
    then return {
      text: header_text,
      url: @logoUrl(),
      root: true,
      title: @logoTitle(),
      trackingItem: 'global-nav-bar_logo',
      logoPath: @logoPath(),
      isTokenExist: @isTokenExist()
    }
    else return {
      text: header_text,
      url: @logoUrl(),
      root: true,
      title: @logoTitle(),
      trackingItem: 'global-nav-bar_logo',
      logoPath: false,
      isTokenExist: @isTokenExist()
    }

  toggleUserMenu: =>
    if @branding()
    then return
    isOpen = @userMenuOpen()
    @userMenuOpen(!isOpen)
    if !isOpen
      setTimeout -> document.querySelector('.user-menu')?.focus()

  handleLogout: ->
    # Make clicking on logout actually log the user out.
    api.logout()
    Router.go('login.html')
    false

  handleUserMenuBlur: (data, event) =>
    flyout = document.querySelector('.user-menu')
    toggle = document.querySelector('.menu-title')
    if (!flyout.contains(event.relatedTarget) and event.relatedTarget isnt toggle)
      @userMenuOpen(false)

  addCrumb: (url, text, root, trackingItem = '') ->
    @breadCrumbs.push {url, text, root, trackingItem}

  resetCrumbs: =>
    @breadCrumbs.removeAll()
    @breadCrumbs.push @trademarkCrumb()

  getFeatureFlags: =>
    getUserFlags().then (flags) =>
      @featureFlags(flags)
