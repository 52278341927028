import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const CircularCheck = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper alt="check" {...props}>
      <path
        d="M4.81955 7.80478L7.00871 10.3124L11.1804 5.68876M14.6265 8.00057C14.6265 11.6603 11.6597 14.6271 7.99996 14.6271C4.34022 14.6271 1.37341 11.6603 1.37341 8.00057C1.37341 4.34083 4.34022 1.37402 7.99996 1.37402C11.6597 1.37402 14.6265 4.34083 14.6265 8.00057Z"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
};
