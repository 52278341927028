import _ from 'lodash';
import $ from 'jquery';
import d3 from 'd3';
import ko from 'knockout';
import moment from 'moment';
import React from 'react';
import * as ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';

import {
  copyProject,
  deleteProject,
  editProject,
  getAPIStatus,
  getDashboardProjects,
  getProfile,
  getProject,
  getProjects,
  getWorkspaces
} from '../../../../main/scripts/utils/ApiUtilsV5';
import SectionBase from '../../classes/sectionbase';
import { trademark } from '../../../../main/scripts/utils/trademark';
import { getBuildInfoProperties } from './getBuildInfoProperties';
import naturalSort, {
  naturalSortByName
} from '../../../../main/scripts/utils/NaturalSort';
import {
  CancelButton,
  RebuildModal
} from '../../../../main/scripts/project_management/RebuildModal';
import { Version } from '../../../../main/scripts/utils/version';
import { objHasPerm } from '../../../../main/scripts/utils/common';
import { load, save } from '../../utils/localStorage';
import { useConceptManagement } from '../../../../main/scripts/data_hooks';
import { REACT_QUERY_CLIENT_CONFIG } from '../../../../main/scripts/constants';

const REACT_ELEMENT_ID = 'react-entry';

export default class OverviewSection extends SectionBase {
  constructor() {
    super();

    document.title = 'Projects | Luminoso Daylight';
    this.trademark = trademark;

    // Store profile and record analytics
    this.profile = ko.observable({});
    getProfile().then(profile => {
      this.profile(profile);
      this.recordAnalytics();
    });

    this.allProjects = ko.observableArray([]);
    this.allDashboardProjects = [];
    this.defaultWorkspace = ko.observable(null);
    this.loading = ko.observable(false);

    // Copy requests that have been made but not returned yet
    this.numCopiesInProgress = ko.observable(0);
    // Information for the copy-project overlay
    this.projectBeingCopied = ko.observable(null);
    this.nameToCopyTo = ko.observable(null);
    this.descriptionToCopyTo = ko.observable(null);
    this.workspaceToCopyTo = ko.observable(null);

    // Search
    this.searchString = ko.observable('');
    this.searchHasFocus = ko.observable(true);
    this.searchVisible = ko.computed(() => this.allProjects().length > 1);
    this.searchActive = ko.computed(
      () => this.searchHasFocus() && this.searchString()
    );

    // Sorting
    this.sortProjectsBy = ko.observable(load('sortProjectsBy', 'name'));
    this.sortCompareFn = ko.computed(() => {
      switch (this.sortProjectsBy()) {
        case 'name':
          return this.compareByName;
        case 'creation_date':
          return this.compareByDate;
      }
    });
    this.sortProjectsBy.subscribe(this.sortProjects);

    this.visibleProjects = ko.computed(() => {
      // Filter the list of all projects by the search string and selected workspace
      const filter = this.searchString().toLowerCase();
      return this.allProjects().filter(project => {
        const workspace = this.workspace();
        return (
          // Filter projects that match the search,
          (project.searchString().indexOf(filter) > -1 ||
            // are not being edited,
            project.titleEditMode() ||
            project.descEditMode()) &&
          // and which match the selected workspace:
          //   - If there is an workspace selected, filter projects by id.
          //   - If there is no workspace selected, include it.
          (!workspace?.workspace_id || workspace.workspace_id === project.owner)
        );
      });
    });

    this.tooManyProjects = ko.computed(() => {
      // Always try to display the projects if there's an workspace filter
      return (
        this.visibleProjects().length > 1000 && !this.workspace()?.workspace_id
      );
    });

    // All of the workspaces that own projects the user can see
    this.workspaces = ko.observableArray([]);
    this.workspace = ko.observable(null); // Full info for the currently selected workspace
    this.workspaceListStatus = ko.observable('loading'); // loading, loaded, error
    // All of the workspaces that the user has 'write' permission on
    this.workspacesWithWrite = ko.observable([]);
    // All of the workspaces that the user has 'read' permissions on
    this.workspacesWithRead = ko.observableArray([]);

    // Used for determining whether to show project-creation button
    this.canCreateProject = ko.observable(false);
    getWorkspaces().then(({ workspaces }) => {
      const createWorkspaces = workspaces.filter(ws =>
        ws.permissions.includes('create')
      );
      this.canCreateProject(createWorkspaces.length > 0);
    });

    // Used for computing an input width for the project title edit interface
    this.hiddenTitle = ko.observable('');

    this.addCrumb('', 'Projects', false);
    this.getFeatureFlags().then(() => {
      window.dataLayer?.push({
        featureFlags: _.keys(_.pickBy(this.featureFlags())).join(',')
      });
    });

    this.loadProjectsAndWorkspaces();
    this.refreshProjectCountInWorkspacesDropdown();
    this.buildWorkspaceDropdownOptions();

    // Store status
    this.status = ko.observable(null);
    getAPIStatus().then(status => {
      this.status(status);
    });

    ko.applyBindings(this);
  }

  recordAnalytics = () => {
    const { username, full_name, organizations, organization_id } =
      this.profile();
    const organization = _.find(organizations, { organization_id });
    const organization_name = organization?.name ?? '';

    if (window.heap) {
      window.heap.identify(username);
      window.heap.addUserProperties({
        name: full_name,
        email: username,
        organization_id,
        organization_name
      });
    }

    if (window.userflow) {
      window.userflow.identify(username, {
        name: full_name,
        email: username,
        organization_id,
        organization_name,
        // __BUILD_TIME__ is a webpack global
        // eslint-disable-next-line no-undef
        build_time: __BUILD_TIME__
      });
    }
  };

  compareByDate = (a, b) => d3.descending(a.msDate, b.msDate);

  compareByName = (a, b) => naturalSort(a.name(), b.name());

  compareWorkspaces = naturalSortByName;

  sortProjects = sortBy => {
    // NOTE: Performance issue on load: We first set @allProjects once and then
    // sort it, causing the elements to render twice.
    this.allProjects.sort(this.sortCompareFn());
    save('sortProjectsBy', sortBy);
  };

  fancyDate = timestamp => {
    if (timestamp == null || timestamp <= 0) {
      return '';
    }
    return moment(timestamp).format(' MMM D, YYYY h:mmA');
  };

  showCopyOverlay = project => {
    this.nameToCopyTo(`Copy of ${project.name()}`);
    this.descriptionToCopyTo(
      project.description() === project.defaultDescription
        ? ''
        : project.description()
    );

    const foundCurrent = _.find(this.workspacesWithWrite(), {
      workspace_id: project.owner
    });
    if (foundCurrent) {
      // If user has write permission on the workspace that the project is
      // already in, use that workspace
      this.workspaceToCopyTo(foundCurrent);
    } else {
      const foundDefault = _.find(this.workspacesWithWrite(), {
        workspace_id: this.defaultWorkspace()
      });
      if (foundDefault) {
        // If user has write permission on their default workspace, use that
        this.workspaceToCopyTo(foundDefault);
      } else {
        // Otherwise, use the first workspace in the list of workspaces they can
        // write in
        this.workspaceToCopyTo(this.workspacesWithWrite()[0]);
      }
    }

    this.projectBeingCopied(project);

    $('#new-project-workspace').chosen({
      inherit_select_classes: true,
      no_results_text: 'No workspaces match',
      width: '100%'
    });
  };

  closeCopyOverlay = () => {
    this.projectBeingCopied(null);
    this.nameToCopyTo(null);
    this.descriptionToCopyTo(null);
    this.workspaceToCopyTo(null);
  };

  buildWorkspaceDropdownOptions = workspaces => {
    // Group projects by their owning workspace
    const projectsByWorkspace = _.groupBy(
      this.allProjects(),
      project => project.owner
    );

    this.workspaces(
      _(workspaces)
        .map(workspace => ({
          workspace_id: workspace.workspace_id,
          name: workspace.name,
          project_count:
            projectsByWorkspace[workspace.workspace_id]?.length ?? 0
        }))
        .sort(this.compareWorkspaces)
        .unshift({
          // Include a menu item for 'All workspaces'
          name: 'All workspaces',
          id: null,
          project_count: this.allProjects().length
        })
        .value()
    );
  };

  getAllDashboardProjects = async () => {
    try {
      return await getDashboardProjects();
    } catch (e) {
      console.error(e);
    }
  };

  loadProjectsAndWorkspaces = () => {
    this.loading(true);
    Promise.all([
      getWorkspaces(),
      getProjects(),
      this.getAllDashboardProjects()
    ]).then((...args) => {
      const [
        { workspaces, default_workspace },
        projectsResponse,
        dashboardProjectsResponse
      ] = args[0];
      this.loading(false);
      this.workspacesWithRead(workspaces);
      this.workspacesWithWrite(
        workspaces
          .filter(workspace => objHasPerm(workspace, 'write'))
          .sort(this.compareWorkspaces)
      );
      this.defaultWorkspace(default_workspace);

      if (
        Array.isArray(dashboardProjectsResponse) &&
        dashboardProjectsResponse?.length > 0
      )
        this.allDashboardProjects = dashboardProjectsResponse;

      this.allProjects(
        _.values(projectsResponse)
          // For now, sort by name.
          .sort(naturalSortByName)
          .map(this.projectListItemFromMetadata)
      );

      this.sortProjects(this.sortProjectsBy());
      this.buildWorkspaceDropdownOptions(workspaces);

      // Instantiate the workspace dropdown menu
      $('.workspace-picker').chosen({
        inherit_select_classes: true,
        no_results_text: 'No workspaces match',
        width: '250px'
      });

      this.loadWorkspace();
      this.workspaceListStatus('loaded');

      $('.workspace-picker:first').trigger('chosen:updated');
      this.updateBuildingProjects();
    });
  };

  refreshProjectCountInWorkspacesDropdown = () => {
    return getWorkspaces().then(({ workspaces }) => {
      this.buildWorkspaceDropdownOptions(workspaces);
      $('.workspace-picker:first').trigger('chosen:updated');
    });
  };

  projectListItemFromMetadata = project => {
    const projectObservable = ko.observable(project);
    const name = ko.observable('');
    const defaultDescription = 'Click here to add a description.';
    const description = ko.observable(
      project.description.trim().length
        ? project.description
        : defaultDescription
    );
    const hasDescription = ko.observable(project.description.length > 0);
    const { project_id } = project;
    const docCount = project.document_count;
    // a project is explorable when the core build has started and not failed
    const explorable = ko.computed(() => {
      const project = projectObservable();
      const hasStartTime =
        typeof project.last_build_info.start_time === 'number';
      return hasStartTime && project.last_build_info.success !== false;
    });

    const saveNameAndDescription = function () {
      let newName = name().trim();
      let newDescription = description().trim();

      // Don't allow people to set the description to "Click here to add a description."
      if (newDescription === defaultDescription) {
        newDescription = '';
      }

      // If they've entered an empty description, show the default description prompt.
      hasDescription(newDescription.length > 0);
      if (!hasDescription()) {
        description(defaultDescription);
      }

      // TODO: We probably want to use computed observables for this trimming and validation at some point.

      // Only save changes if there are changes
      if (project.name !== newName || project.description !== newDescription) {
        // If they entered an empty name, use the current name
        if (!newName.length) {
          name(project.name);
          newName = project.name;
        }

        // Save changes
        editProject(project.project_id, {
          name: newName,
          description: newDescription
        }).then(() => {
          project.name = newName;
          project.description = newDescription;
        });
      }
    };

    // True if the title is currently being edited
    const titleEditMode = ko.observable(false);
    titleEditMode.subscribe(editing => {
      if (!editing) {
        saveNameAndDescription();
      }
    });

    // True if the description is currently being edited
    const descEditMode = ko.observable(false);
    descEditMode.subscribe(editing => {
      if (!editing) {
        saveNameAndDescription();
      }
    });

    // Adjust the title input size based on the width of the text it contains
    const inputWidth = ko.observable(0);
    name.subscribe(newValue => {
      this.hiddenTitle(newValue);
      inputWidth(Math.min(645, $('.hidden-title').width() + 20));
    });
    name(project.name);

    const hasOutdatedScience = ko.computed(() =>
      isScienceOutdated(projectObservable(), this.status())
    );

    const hasProjectCreatedByDataStream = ko.observable(false);

    const updateProjectCreationStatus = () => {
      const selectedProject = this.allDashboardProjects.find(
        item => item.project_id === project.project_id
      );
      const hasTasks = selectedProject?.task_has_run;
      hasProjectCreatedByDataStream(hasTasks); // Update the observable with the result
    };

    updateProjectCreationStatus();

    return {
      projectObservable,
      name,
      titleEditMode,
      description,
      descEditMode,
      defaultDescription,
      hasDescription,
      hasOutdatedScience,
      hasProjectCreatedByDataStream,
      creator:
        project.creator && project.creator !== 'Unknown'
          ? project.creator
          : null,

      writeable: project.permissions.includes('write'),
      uploadAllowed: project.permissions.includes('create'),
      deleteAllowed: project.permissions.includes('create'),

      project_id,
      owner: project.workspace_id,
      workspace_name: _.find(this.workspacesWithRead(), {
        workspace_id: project.workspace_id
      })?.name,
      msDate: project.creation_date,
      date: this.fancyDate(project.creation_date * 1000),
      docCount,

      updateDataStreamUrl: `app/upload/stream-edit/${project.workspace_id}/${project_id}`,
      uploadUrl: `app/upload/${project.workspace_id}/${project_id}`,
      exploreUrl: ko.computed(() => {
        if (explorable()) {
          return `/app/projects/${project.workspace_id}/${project_id}`;
        } else {
          return '';
        }
      }),
      onClickTitleLink: ko.computed(() => {
        // If the project is not explorable, clicking the title link shouldn't
        // do anything.
        if (!explorable()) {
          return _.noop;
        }

        // If the project has outdated science, clicking on its title should
        // open the force rebuild modal.
        if (hasOutdatedScience()) {
          return () => this.openRebuildModal(project);
        }

        // If the project is explorable and its science is up to date, return
        // null so that the link acts as a link (i.e. clicking on it takes you
        // to the projects page).
        return null;
      }),

      // TODO: Generalize to a 'status', e.g. created -> first upload -> explorable -> explorable + processing
      explorable,
      inputWidth,

      beginDescEdits: () => {
        // NOTE: This expects to be called from within a click
        // event; we check the selection to disambiguate clicks
        // from when a user is selecting part of the description
        // text.
        if (!window.getSelection().toString()) {
          descEditMode(true);
        }
      },

      beginTitleEdits: () => {
        titleEditMode(true);
      },

      endEdits: () => {
        titleEditMode(false);
        descEditMode(false);
      },

      cancelEdits: () => {
        name(project.name);
        description(project.description);
        titleEditMode(false);
        descEditMode(false);
      },

      searchString: ko.computed(() =>
        [name(), description()].join('\n').toLowerCase()
      ),

      deleteProject: () => {
        const answer = confirm(
          `You are about to delete the following project:\n${name()}\nAre you sure?`
        );
        if (answer) {
          deleteProject(project_id).then(() => {
            this.allProjects(_.reject(this.allProjects(), { project_id }));
            this.refreshProjectCountInWorkspacesDropdown();
          });
        }
      },

      copyProject: () => {
        this.numCopiesInProgress(this.numCopiesInProgress() + 1);
        const new_owner = this.workspaceToCopyTo().workspace_id;
        copyProject({
          projectId: project_id,
          name: this.nameToCopyTo(),
          description: this.descriptionToCopyTo(),
          workspaceId: new_owner
        })
          .then(result => {
            // @allProjects() is already sorted via @sortCompareFn()
            // find first index that compares equal to or
            // higher than projectListItem, and splice it in there
            const projectListItem = this.projectListItemFromMetadata(result);
            const index = _.findIndex(this.allProjects(), el => {
              return this.sortCompareFn()(el, projectListItem) >= 0;
            });
            if (index === -1) {
              this.allProjects.push(projectListItem);
            } else {
              this.allProjects.splice(index, 0, projectListItem);
            }
            return this.refreshProjectCountInWorkspacesDropdown();
          })
          .catch(({ message }) => {
            if (message) {
              alert(message);
            }
          })
          .finally(() => {
            this.numCopiesInProgress(this.numCopiesInProgress() - 1);
          });
      },

      ...getBuildInfoProperties(project, hasOutdatedScience())
    };
  };

  loadWorkspace = () => {
    const projectListWorkspaceId = load('projectListWorkspaceId');
    this.workspace(
      _.find(this.workspaces(), { workspace_id: projectListWorkspaceId }) ||
        this.workspaces[0]
    );
  };

  onChangeWorkspace = (self, event, selected) => {
    // For some reason this function can be triggered without user interaction
    // When that happens, the selected parameter is undefined.
    if (selected) {
      const projectListWorkspaceId = this.workspace().workspace_id;
      if (projectListWorkspaceId) {
        save('projectListWorkspaceId', projectListWorkspaceId);
      } else {
        localStorage.removeItem('projectListWorkspaceId');
      }
    } else {
      // Prevent option from being rewritten without user interaction
      this.loadWorkspace();
    }
  };

  /**
   * Updates the build-related properties for multiple projects.
   *
   * @param {Object} updates - Object mapping project IDs to objects containing
   *                           updates to be applied to that project.
   */
  updateProjectsBuild = updates => {
    const projects = this.allProjects().map(project => {
      const update = updates[project.project_id];

      if (!update) {
        return project;
      }

      // Update the project observable in order to update all computed values
      const updatedProject = { ...project.projectObservable(), ...update };
      project.projectObservable(updatedProject);

      // Spread existing project so as to not interrupt an in-progress edit.
      // Spread into a new object in order for KnockOut to pick up on the change
      return {
        ...project,
        ...getBuildInfoProperties(
          updatedProject,
          isScienceOutdated(updatedProject, this.status())
        )
      };
    });

    this.allProjects(projects);
  };

  updateBuildingProjects = () => {
    const projectsBuildingSentiment = this.allProjects().filter(
      project => project.status.waitingOnSentiment
    );
    if (projectsBuildingSentiment.length === 0) {
      return;
    }

    Promise.all(
      projectsBuildingSentiment.map(project =>
        getProject(project.project_id, [
          'project_id',
          'document_count',
          'last_build_info'
        ])
      )
    ).then(updatedProjects => {
      const updates = _.keyBy(updatedProjects, 'project_id');
      this.updateProjectsBuild(updates);
      setTimeout(this.updateBuildingProjects, 30000);
    });
  };

  openRebuildModal = project => {
    const serverStatus = this.status();
    const entry = document.getElementById(REACT_ELEMENT_ID);
    ReactModal.setAppElement(`#${REACT_ELEMENT_ID}`);
    const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <ForceRebuildInLUI
          project={project}
          username={this.profile().username}
          serverStatus={serverStatus}
        />
      </QueryClientProvider>,
      entry
    );
  };
}

const ForceRebuildInLUI = ({ project, username, serverStatus }) => {
  const { workspace_id, project_id } = project;
  const { conceptManagement } = useConceptManagement(project);
  return (
    <RebuildModal
      forcedRebuild={true}
      project={project}
      userEmail={username}
      serverStatus={serverStatus}
      cancelButton={<CancelButton onClick={closeModal} />}
      onAfterRebuild={() => {
        window.location.href = `/app/projects/${workspace_id}/${project_id}`;
      }}
      conceptManagement={conceptManagement}
      onHide={closeModal}
    />
  );
};

const closeModal = () => {
  // Close the modal by unmounting React altogether
  const entry = document.getElementById(REACT_ELEMENT_ID);
  ReactDOM.unmountComponentAtNode(entry);
};

const isScienceOutdated = (project, status) => {
  const minVersion = status?.minimum_science_version;
  const { science_version } = project.last_build_info;
  if (minVersion && science_version) {
    const projectVersion = new Version(science_version);
    return projectVersion.isLessThan(minVersion);
  }
  return false;
};
