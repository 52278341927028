import { useReducer } from 'react';

/**
 * Toggle between true and false
 *
 * @param {boolean} initialValue
 * @return {[boolean, function]} An array of the current value and a function to
 * toggle the value
 */
export function useToggle(initialValue = false) {
  const [value, toggle] = useReducer(value => !value, initialValue);
  return [value, toggle];
}
