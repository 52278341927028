import React from 'react';

import { miscErrMsg, ssoHasFailed } from './utils';

export const Alerts = ({ showLoginToContinuePrompt, loginErrorMessage }) => {
  const ssoError = ssoHasFailed();
  const showLoginError = loginErrorMessage !== '';
  return (
    <div className="alerts">
      {showLoginToContinuePrompt && (
        <div className="alert alert-info fade-in">
          Please log in to continue.
        </div>
      )}
      {(showLoginError || ssoError) && (
        <div className="alert alert-error fade-in">
          {loginErrorMessage || miscErrMsg}
        </div>
      )}
    </div>
  );
};
