import React, {
  createContext,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { StoreContext } from '../StoreContext';
import { useCurrentFeature, useStableMemo } from '../utils/hooks';
import * as SearchUtils from './SearchUtils';
import { commonSearchDefaults } from './searchDefaults/commonSearchDefaults';
import { galaxySearchDefaults } from './searchDefaults/galaxySearchDefaults';
import { volumeSearchDefaults } from './searchDefaults/volumeSearchDefaults';
import { driversSearchDefaults } from './searchDefaults/driversSearchDefaults';
import { sentimentSearchDefaults } from './searchDefaults/sentimentSearchDefaults';
import { decodeFilter, encodeFilter } from './filterParam';
import { migrateOldFormatSearch } from '../compatibility/searchMigrations';

/**
 * @typedef {Object} SearchParamState
 * @property {object} searchParams - Object mapping search param keys to parsed
 * values
 * @property {function(object): void} updateSearch - Function used to update the
 * search. It takes an object mapping a subset of search param keys to the new
 * values they should have. Passing in a value of null or undefined will clear
 * out that search param. The value of `filter` should be in the decoded form.
 */

/** @type {React.Context<SearchParamState|null>} */
const SearchContext = createContext(null);

/**
 * Hook to get current search and function to update the search.
 * @return {SearchParamState}
 */
export const useSearchParams = () => {
  const searchContext = useContext(SearchContext);
  if (!searchContext) {
    throw 'useSearchParams must be used inside of a <SearchProvider>';
  }
  return searchContext;
};

export const SearchProvider = ({ children }) => {
  const { replace } = useHistory();
  const { search } = useLocation();
  const parser = useParamParser();

  // "search" is a string containing the search as it appears in the URL (e.g.
  // "?foo=bar").
  //
  // "params" is an object representing that search (e.g. "{foo: 'bar'}").
  // Notably, all its values are strings).
  //
  // "parsedSearchParams" is a fixed version of the params. That is, we've
  // resolved any issues from incompatible or missing values and, in some cases,
  // have changed the values to a format that's easier to use. Notably, its
  // values are not necessarily strings. For example, the filter in the parsed
  // search params is an array of constraints.
  const params = useStableMemo(() => SearchUtils.parse(search), [search]);
  const filter = useStableMemo(() => decodeFilter(params.filter), [
    params.filter
  ]);
  const parsedSearchParams = useStableMemo(() => {
    return parser({ ...params, filter });
  }, [parser, params, filter]);

  const updateSearch = useCallback(
    updates => {
      const updatedParams = parser({ ...params, filter, ...updates });
      updatedParams.filter = encodeFilter(updatedParams.filter);

      if (!SearchUtils.areEqual(params, updatedParams)) {
        replace({ search: SearchUtils.stringify(updatedParams) });
      }
    },
    [replace, params, parser, filter]
  );

  useEffect(() => {
    updateSearch(parsedSearchParams);
  });

  return (
    <SearchContext.Provider
      value={{ searchParams: parsedSearchParams, updateSearch }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useParamParser = () => {
  const feature = useCurrentFeature();
  const {
    project,
    metadata,
    activeConcepts,
    projectHasLoaded,
    selection,
    sharedConceptLists
  } = useContext(StoreContext);

  return useCallback(
    params => {
      const store = {
        project,
        metadata,
        activeConcepts,
        projectHasLoaded,
        selection,
        sharedConceptLists
      };
      const migratedParams = migrateOldFormatSearch(params, feature);
      const commonParams = commonSearchDefaults(migratedParams, store);

      switch (feature) {
        case 'drivers':
          return driversSearchDefaults(commonParams, store);
        case 'galaxy':
          return galaxySearchDefaults(commonParams, store);
        case 'volume':
          return volumeSearchDefaults(commonParams, store);
        case 'sentiment':
          return sentimentSearchDefaults(commonParams, store);
        default:
          return params;
      }
    },
    [
      project,
      feature,
      metadata,
      activeConcepts,
      projectHasLoaded,
      selection,
      sharedConceptLists
    ]
  );
};
