import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function CaretStroke({ iconColor, lineWidth, direction, ...props }) {
  return (
    <SVGWrapper {...props}>
      <Caret
        direction={direction}
        iconColor={iconColor}
        lineWidth={lineWidth}
      />
    </SVGWrapper>
  );
}

const Caret = ({ direction, iconColor, lineWidth }) => {
  switch (direction) {
    case 'up':
      return (
        <path
          d="M4.54999 9.72461L7.29288 6.98172C7.68341 6.59119 8.31657 6.59119 8.70709 6.98172L11.45 9.72461"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'down':
      return (
        <path
          d="M11.4473 6.27734L8.70712 9.01749C8.3166 9.40801 7.68343 9.40801 7.29291 9.01749L4.55277 6.27734"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'right':
      return (
        <path
          d="M6.27502 4.55078L9.01792 7.29368C9.40844 7.6842 9.40844 8.31736 9.01792 8.70789L6.27502 11.4508"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'left':
      return (
        <path
          d="M9.72498 4.55078L6.98208 7.29368C6.59156 7.6842 6.59156 8.31736 6.98208 8.70789L9.72498 11.4508"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    default:
      throw 'CaretStroke icon requires a "direction" prop';
  }
};
