import React from 'react';

export const Dashboard = () => (
  <svg fill="#000000" height="32px" width="32px" viewBox="0 0 512 512">
    <g transform="translate(1 1)">
      <g>
        <g>
          <path
            d="M472.6,16.067H37.4c-21.333,0-38.4,17.067-38.4,38.4v260.267v38.4c0,21.333,17.067,38.4,38.4,38.4h163.84l-12.8,51.2
				h-1.707c-23.893,0-42.667,18.773-42.667,42.667c0,5.12,3.413,8.533,8.533,8.533h204.8c5.12,0,8.533-3.413,8.533-8.533
				c0-23.893-18.773-42.667-42.667-42.667h-1.707l-12.8-51.2H472.6c21.333,0,38.4-17.067,38.4-38.4v-38.4V54.467
				C511,33.133,493.933,16.067,472.6,16.067z M16.067,54.467c0-11.947,9.387-21.333,21.333-21.333h435.2
				c11.947,0,21.333,9.387,21.333,21.333V306.2H16.067V54.467z M348.013,476.867H162.84c3.413-10.24,12.8-17.067,23.893-17.067
				h8.533h119.467h9.387C335.213,459.8,344.6,466.627,348.013,476.867z M303.64,442.733h-97.28l12.8-51.2h71.68L303.64,442.733z
				 M493.933,353.133c0,11.947-9.387,21.333-21.333,21.333H297.667h-85.333H37.4c-11.947,0-21.333-9.387-21.333-21.333v-29.867
				h477.867V353.133z"
          />
          <path
            d="M468.333,109.933c0-5.12-3.414-8.533-8.533-8.533h-59.733c-5.12,0-8.533,3.413-8.533,8.533
				c0,5.12,3.413,8.533,8.533,8.533h39.253l-102.4,102.4l-70.827-70.827c-0.144-0.144-0.296-0.274-0.446-0.406
				c-0.133-0.151-0.263-0.303-0.408-0.448c-3.413-2.56-8.533-2.56-11.947,0.853l-98.987,107.52c-2.56,3.413-2.56,8.533,0.853,11.947
				c1.707,1.707,4.267,2.56,5.973,2.56c2.56,0,4.267-0.853,5.973-2.56l92.876-101.684l70.964,70.964
				c1.707,1.707,3.413,2.56,5.973,2.56c1.6,0,2.867-0.333,4.008-1c0.685-0.4,1.325-0.92,1.965-1.56l108.373-108.373v39.253
				c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533V109.933L468.333,109.933
				C468.333,109.933,468.333,109.933,468.333,109.933z"
          />
          <path
            d="M50.2,75.8c-5.12,0-8.533,3.413-8.533,8.533v187.733c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V84.333
				C58.733,79.213,55.32,75.8,50.2,75.8z"
          />
          <path
            d="M84.333,101.4H101.4c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H84.333c-5.12,0-8.533,3.413-8.533,8.533
				S79.213,101.4,84.333,101.4z"
          />
          <path
            d="M118.467,118.467H84.333c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h34.133
				c5.12,0,8.533-3.413,8.533-8.533S123.587,118.467,118.467,118.467z"
          />
          <path
            d="M84.333,169.667H101.4c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H84.333
				c-5.12,0-8.533,3.413-8.533,8.533C75.8,166.253,79.213,169.667,84.333,169.667z"
          />
          <path
            d="M118.467,186.733H84.333c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h34.133
				c5.12,0,8.533-3.413,8.533-8.533S123.587,186.733,118.467,186.733z"
          />
          <path
            d="M84.333,237.933H101.4c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H84.333
				c-5.12,0-8.533,3.413-8.533,8.533C75.8,234.52,79.213,237.933,84.333,237.933z"
          />
          <path
            d="M118.467,255H84.333c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h34.133c5.12,0,8.533-3.413,8.533-8.533
				S123.587,255,118.467,255z"
          />
        </g>
      </g>
    </g>
  </svg>
);
