import { getById } from '../../utils/conceptUtils';
import { getConceptsToVisualizeParams } from './getConceptsToVisualizeParams';

export const galaxySearchDefaults = (params, store) => {
  // Avoid clearing the axes params if we haven't loaded the active
  // concepts yet
  if (!store.projectHasLoaded) {
    return params;
  }

  const conceptsToVisualizeSubQuery = getConceptsToVisualizeParams(
    params,
    store,
    { concepts: 'prevalent', prevalent_count: '250' }
  );

  const xAxisConcept = getById(store.activeConcepts, params.x_axis);
  const yAxisConcept = getById(store.activeConcepts, params.y_axis);

  // Reset axis if a concept is turned into a boolean
  const x_axis =
    !xAxisConcept || xAxisConcept.boolean ? null : xAxisConcept.sharedConceptId;
  const y_axis =
    !yAxisConcept || yAxisConcept.boolean ? null : yAxisConcept.sharedConceptId;

  const show_axes =
    (x_axis || y_axis) && ['true', 'false'].includes(params.show_axes)
      ? params.show_axes
      : 'false';

  let color_by = ['clusters', 'active_concepts', 'concept_lists'].includes(
    params.color_by
  )
    ? params.color_by
    : store.activeConcepts.length !== 0
    ? 'active_concepts'
    : 'clusters';

  const color_by_list = store.sharedConceptLists
    ?.map(list => list.concept_list_id)
    .includes(params.color_by_list)
    ? params.color_by_list
    : undefined;

  // if there's some issue with the concept list whose id is the value of
  // color_by_list (like say it got deleted since you saved a bookmark), then we
  // want to switch to either coloring by active concepts or to clusters if
  // there are no active concepts.

  if (color_by === 'concept_lists' && !color_by_list) {
    color_by =
      store.activeConcepts.length !== 0 ? 'active_concepts' : 'clusters';
  }

  return {
    ...params,
    ...conceptsToVisualizeSubQuery,
    x_axis,
    y_axis,
    show_axes,
    color_by,
    color_by_list
  };
};
