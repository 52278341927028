$ = require 'jquery'
URI = require 'urijs'

{ load } = require './localStorage'

# Make an AJAX request, setting a token on it for authentication.
# Things you can put in `params`:
#   type - HTTP method (in all caps)
#   url - URL to make the request to (not including query params)
#   data - key-value object containing parameters and/or file contents to upload
#   contentType - specify this for file uploads
#   responseType = 'json' (default) or other xhr responseType
module.exports = (params) ->
    body = null
    query = null
    if params.type in ['PUT', 'POST']
        if params.contentType is 'application/json'
            body = JSON.stringify params.data.body
            delete params.data.body
            query = params.data
        else
            # Parameters go in the request body
            if params.data
                body = $.param params.data
            params.contentType = 'application/x-www-form-urlencoded'
    else
        # Parameters go in the URL
        query = params.data

    xhr = new XMLHttpRequest()
    fullURL = params.url + (if query? then '?' + $.param(query) else '')
    xhr.open(params.type or 'GET', fullURL)
    xhr.responseType = params.responseType or 'json'
    xhr.setRequestHeader('Authorization', 'Token ' + load('auth_token'))
    xhr.withCredentials = true
    if params.contentType?
        xhr.setRequestHeader('Content-Type', params.contentType)

    promise = $.Deferred()
    xhr.onload = ->
        # This is a callback function that gets called when the response is ... done?
        # Inside this function, `this` is `xhr`.
        try
            # First, try to parse the response as JSON.
            response = JSON.parse(new TextDecoder('utf-8').decode(new DataView(this.response)))
        catch
            # If it's not JSON, then leave it as-is (e.g., binary for CSV or XLSX).
            response = this.response

        if this.status < 400
            promise.resolve response, this.statusText, this

        else
            shouldLogOut = (
                # You should be logged out if The error was a 401, and
                this.status == 401 &&

                # the error code implies that you're not logged in, and
                # FIXME: Do not use NO_TOKEN here; instead: make sure that requests aren't sent without logging in
                (response.error in ['INVALID_TOKEN', 'NO_TOKEN']) &&

                # you need to be logged in to look at the page you're currently on.
                window.ActiveSection.sectionRequiresAuth
            )

            if shouldLogOut
                # Do not fulfill the promise; simply redirect to the login page.
                console.log 'We are logging you out. xhr.status == 401 and xhr.response ==', this.response
                url = URI(window.location.href)
                query = url.query()
                loc = escape(url.filename() + (if query.length then '?' + query else ''))
                window.location.href = 'login.html?dest=' + loc
            else
                if this.readyState == 4
                    # readyState 4 means the request is complete
                    promise.reject response
                else
                    console.log "Response incomplete; probably page navigation."
                    promise.reject {
                        result: null
                        error: {
                            code: -1
                            message: "Incomplete response"
                        }
                    }

    xhr.send(body)

    # Expose the request's abort method to allow users of the API client to cancel requests they make.
    promise.abort = xhr.abort.bind(xhr)
    promise
