import { recursiveCamelCaseKeys } from '../utils/common';
// Import Concept so that the type annotation gets picked up by VS Code.
// eslint-disable-next-line no-unused-vars
import { Concept } from './Concepts';

export class Doc {
  constructor(config) {
    for (const key of Object.keys(config)) {
      this[key] = config[key];
    }
  }

  /**
   * Find the indexes in the text of this doc which either exactly or
   * conceptually match the provided concept. Indexes are given in UTF-16 code
   * units, so they can be used for indexing into JavaScript strings directly.
   *
   * @param {Concept} concept The concept to find the matching indexes of.
   * @return An object with an array for the exact and conceptual indices for
   * the given concept.
   */
  getMatchIndices(concept) {
    if (!concept) {
      return { exact: [], conceptual: [] };
    }

    return {
      exact: findMatchIndices(this, concept.exactTermIds),
      conceptual: findMatchIndices(this, concept.relatedTermIds)
    };
  }

  static fromJSON(config) {
    const camelCased = recursiveCamelCaseKeys(config);
    const indexMap = makeIndexMap(camelCased.text);

    const convertIndices = term => {
      return {
        ...term,
        start: indexMap.get(term.start),
        end: indexMap.get(term.end),
        sentiment: term.sentiment,
      };
    };
    return new Doc({
      ...camelCased,
      terms: camelCased.terms.map(convertIndices),
      fragments: camelCased.fragments.map(convertIndices)
    });
  }
}

function findMatchIndices(doc, matchingTerms) {
  if (matchingTerms == null) {
    return [];
  }

  return [...doc.terms, ...doc.fragments]
    .filter(({ termId }) => matchingTerms.includes(termId))
    .map(({ start, end, sentiment}) => [start, end, sentiment]);
}

/**
 * The backend counts indexes in UTF-8, Javascript in UTF-16.
 *
 * @param {string} string
 * @returns {Map<number, number>} UTF-8 index => UTF-16 index
 */
export function makeIndexMap(string) {
  const indexMap = new Map();
  let utf8Index = 0;
  let utf16Index = 0;
  // Keep looping until we've exhausted every possible UTF-16 index in the
  // string.
  // Note: the looping condition uses <= rather than < because a word's end
  // index is given as the index after the final character. For a terminal word,
  // this would be the character after the end of the string.
  while (utf16Index <= string.length) {
    indexMap.set(utf8Index, utf16Index);
    utf8Index += 1;
    utf16Index += /[\uD800-\uDFFF]/.test(string[utf16Index]) ? 2 : 1;
  }
  return indexMap;
}
