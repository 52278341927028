import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Flask = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path1 = '',
    path2 = '';

  if (widthName === 'light') {
    path1 =
      'M6.57983 1.28197V6.23398C6.57983 6.68311 6.42865 7.11917 6.15065 7.47193L1.77089 13.0294C1.25398 13.6853 1.72119 14.6484 2.5563 14.6484L13.442 14.6484C14.2774 14.6484 14.7445 13.6847 14.227 13.0289L9.85069 7.48332C9.57218 7.13039 9.4207 6.69392 9.4207 6.24434V1.28197M4.7718 0.98387H11.1785C11.2883 0.98387 11.3772 1.07285 11.3772 1.1826V1.1826C11.3772 1.23748 11.3328 1.28197 11.2779 1.28197H4.7718C4.68948 1.28197 4.62275 1.21524 4.62275 1.13292V1.13292C4.62275 1.0506 4.68948 0.98387 4.7718 0.98387Z';
    path2 =
      'M1.30576 14.6484L14.8347 14.6483L13.5907 11.7803C13.5599 11.7092 13.5208 11.6419 13.4745 11.5798L12.0203 9.63293C11.8316 9.38032 11.5348 9.23149 11.2195 9.23136L5.09712 9.22887C4.79406 9.22874 4.5073 9.36606 4.3174 9.60223L2.52751 11.8282C2.46868 11.9014 2.42045 11.9825 2.38423 12.0691L1.30576 14.6484Z';
  } else {
    path1 =
      'M6.57983 1.46583V6.41783C6.57983 6.86697 6.42865 7.30303 6.15065 7.65578L1.77089 13.2132C1.25398 13.8691 1.72119 14.8322 2.5563 14.8322L13.442 14.8322C14.2774 14.8322 14.7445 13.8685 14.227 13.2127L9.85069 7.66717C9.57218 7.31425 9.4207 6.87778 9.4207 6.42819V1.46583M4.7718 1.16772H11.1785C11.2883 1.16772 11.3772 1.2567 11.3772 1.36646V1.36646C11.3772 1.42134 11.3328 1.46583 11.2779 1.46583H4.7718C4.68948 1.46583 4.62275 1.39909 4.62275 1.31677V1.31677C4.62275 1.23446 4.68948 1.16772 4.7718 1.16772Z';
    path2 =
      'M1.30576 14.8322L14.8347 14.8322L13.5907 11.9642C13.5599 11.893 13.5208 11.8258 13.4745 11.7637L12.0203 9.81679C11.8316 9.56417 11.5348 9.41534 11.2195 9.41521L5.09712 9.41272C4.79406 9.4126 4.5073 9.54991 4.3174 9.78609L2.52751 12.0121C2.46868 12.0852 2.42045 12.1663 2.38423 12.2529L1.30576 14.8322Z';
  }

  return (
    <SVGWrapper {...props}>
      <path
        d={path1}
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d={path2} fill={iconColor} />
    </SVGWrapper>
  );
};
