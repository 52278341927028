import {
  getDefaultDriversField,
  isValidDriversField
} from '../../drivers/utils';
import {
  getSentimentStatus,
  SENTIMENT_STATUS
} from '../../utils/sentimentStatus';

/**
 * @typedef {Object} Store
 * @property {Concept[]} activeConcepts
 * @property {MetadataField[]} metadata
 * @property {Object} project
 */

/**
 * @typedef {Object} DefaultParams
 * @property {string} concepts
 * @property {string} [drivers_count]
 * @property {string} [prevalent_count]
 * @property {string} [drivers_of]
 */

/**
 * @typedef {Object} ConceptsToVisualizeParams
 * @property {string|null} concepts
 * @property {string|null} drivers_count
 * @property {string|null} prevalent_count
 * @property {string|null} drivers_of
 */

/**
 * @param {Object} params - Object mapping search params to their values
 * @param {Store} store - Subset of the store needed to determine which concepts
 *                        to visualize
 * @param {DefaultParams} defaults - Default query param values to use if a
 *                                   valid value isn't already specified in the
 *                                   query
 * @returns {ConceptsToVisualizeParams}
 */
export const getConceptsToVisualizeParams = (params, store, defaults) => {
  return {
    concepts: getConceptType(params, store, defaults),
    drivers_count: getDriversCount(params, defaults),
    prevalent_count: getPrevalentCount(params, defaults),
    drivers_of: getDriversOf(params, store.metadata, defaults)
  };
};

const getConceptType = (
  params,
  { activeConcepts, metadata, project },
  { concepts }
) => {
  const hasValidDriverFields = getDefaultDriversField(metadata) !== undefined;
  const hasSentiment = getSentimentStatus(project) === SENTIMENT_STATUS.READY;

  const acceptedConceptParamValues = [
    ...(hasSentiment ? ['sentiment'] : []),
    'prevalent',
    'clusters',
    ...(hasValidDriverFields ? ['drivers'] : []),
    ...(activeConcepts.length > 0 ? ['active'] : []),
    ...(params.filter.length > 0 ? ['unique_to_filter'] : [])
  ];

  if (acceptedConceptParamValues.includes(params.concepts)) {
    return params.concepts;
  } else if (activeConcepts.length > 0 && params.concepts === 'saved') {
    return 'active';
  }

  // If "drivers" is the default concept type and there no valid fields to
  // select, then we instead return undefined. This effectively acts like no
  // concept type has been selected (and avoids an invalid "drivers" value from
  // being selected).
  if (concepts === 'drivers' && !hasValidDriverFields) {
    return undefined;
  }

  // If "sentiment" is the default concept type but the project doesn't have
  // access to sentiment (e.g. the build is still running, has failed, or does
  // not have access to a GPU), then return undefined.
  if (concepts === 'sentiment' && !hasSentiment) {
    return undefined;
  }

  return concepts;
};

const getDriversCount = (params, { drivers_count = '50' }) =>
  ['10', '20', '50'].includes(params.drivers_count)
    ? params.drivers_count
    : drivers_count;

const MATCH_COUNT_LIMITS = ['10', '50', '100', '250', '500'];

const getPrevalentCount = (params, { prevalent_count = '50' }) => {
  return MATCH_COUNT_LIMITS.includes(params.prevalent_count)
    ? params.prevalent_count
    : prevalent_count;
};

const getDriversOf = (
  params,
  metadata,
  { drivers_of = getDefaultDriversField(metadata) }
) => {
  return isValidDriversField(metadata, params.drivers_of)
    ? params.drivers_of
    : drivers_of;
};
