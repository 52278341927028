import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { selectConcept, selectFilter } from '../actions';
import { usePrevious } from '../utils/hooks';
import { StoreContext } from '../StoreContext';
import { Concept } from '../classes/Concepts';
import { useSearchParams } from './useSearchParams';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';

/**
 * Certain values in the project store depend on the current search and filter.
 * This hook is responsible for updating those values whenever the search or
 * filter search params change.
 *
 * It is also responsible for updating the "search" param if the selection
 * in the store is updated.
 */
export function useSyncFilterAndSearch() {
  const { projectId } = useParams();
  const { selection,project } = useContext(StoreContext);
  const prevSelection = usePrevious(selection);
  const { searchParams, updateSearch } = useSearchParams();
  const { filter, search } = searchParams;
  const prevSearch = usePrevious(search);
  const prevFilter = usePrevious(filter);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY

  useEffect(() => {
    if (prevSelection !== selection) {
      updateSearch({ search: Concept.toString(selection) });
    }
  });

  const filterChanged = filter !== prevFilter;
  const searchChanged =
    search !== prevSearch && Concept.toString(selection) !== search;

  if (filterChanged && searchChanged) {
    // selectFilter calls selectConcept under the hood. If we call both, the
    // standalone selectConcept call causes selectFilter's call to selectConcept
    // to be aborted, which in turns prevents selectFilter from finishing, which
    // causes the value of `filterIsLoading` (in the store) to not get reset.
    selectFilter(projectId, filter, Concept.fromString(search),isSentimentReady);
  } else if (filterChanged) {
    selectFilter(projectId, filter, selection,isSentimentReady);
  } else if (searchChanged) {
    selectConcept(projectId, Concept.fromString(search), filter,isSentimentReady);
  }
}
