import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function CaretFill({ iconColor, direction, widthName, ...props }) {
  return (
    <SVGWrapper {...props}>
      <Caret
        iconColor={iconColor}
        direction={direction}
        widthName={widthName}
      />
    </SVGWrapper>
  );
}

const Caret = ({ direction, iconColor, widthName }) => {
  switch (direction) {
    case 'up':
      return <CaretUp iconColor={iconColor} widthName={widthName} />;
    case 'down':
      return <CaretDown iconColor={iconColor} widthName={widthName} />;

    case 'right':
      return <CaretRight iconColor={iconColor} widthName={widthName} />;

    case 'left':
      return <CaretLeft iconColor={iconColor} widthName={widthName} />;

    default:
      throw 'CaretFill icon requires a "direction" prop';
  }
};

const CaretUp = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M8.35355 6.35355L11.1464 9.14645C11.4614 9.46143 11.2383 10 10.7929 10H5.20711C4.76165 10 4.53857 9.46143 4.85355 9.14645L7.64645 6.35355C7.84171 6.15829 8.15829 6.15829 8.35355 6.35355Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7929 10.2929C11.6838 10.2929 12.13 9.21577 11.5 8.5858L8.70713 5.79291C8.31661 5.40238 7.68344 5.40238 7.29292 5.79291L4.50002 8.5858C3.87006 9.21577 4.31623 10.2929 5.20713 10.2929H10.7929Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7929 10.5C11.6838 10.5 12.1299 9.42284 11.5 8.79288L8.70708 5.99999C8.31656 5.60946 7.68339 5.60946 7.29287 5.99999L4.49998 8.79288C3.87001 9.42285 4.31618 10.5 5.20708 10.5H10.7929Z"
          fill={iconColor}
        />
      );
  }
};

const CaretDown = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M7.64645 9.64645L4.85355 6.85355C4.53857 6.53857 4.76165 6 5.20711 6H10.7929C11.2383 6 11.4614 6.53857 11.1464 6.85355L8.35355 9.64645C8.15829 9.84171 7.84171 9.84171 7.64645 9.64645Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20708 5.5C4.31618 5.5 3.87001 6.57714 4.49998 7.20711L7.29287 10C7.68339 10.3905 8.31656 10.3905 8.70708 10L11.5 7.20711C12.1299 6.57714 11.6838 5.5 10.7929 5.5H5.20708Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20713 5.75C4.53895 5.75 4.20433 6.55786 4.6768 7.03033L7.46969 9.82322C7.76259 10.1161 8.23746 10.1161 8.53035 9.82322L11.3232 7.03033C11.7957 6.55785 11.4611 5.75 10.7929 5.75H5.20713Z"
          fill={iconColor}
        />
      );
  }
};

const CaretRight = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M9.64645 8.35355L6.85355 11.1464C6.53857 11.4614 6 11.2383 6 10.7929L6 5.20711C6 4.76166 6.53857 4.53857 6.85355 4.85355L9.64645 7.64645C9.84171 7.84171 9.84171 8.15829 9.64645 8.35355Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 10.7929C5.5 11.6838 6.57714 12.13 7.20711 11.5L10 8.70714C10.3905 8.31662 10.3905 7.68345 10 7.29293L7.20711 4.50004C6.57714 3.87007 5.5 4.31624 5.5 5.20714V10.7929Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.75 10.7929C5.75 11.4611 6.55786 11.7957 7.03033 11.3232L9.82322 8.53032C10.1161 8.23743 10.1161 7.76256 9.82322 7.46966L7.03033 4.67677C6.55785 4.2043 5.75 4.53892 5.75 5.2071V10.7929Z"
          fill={iconColor}
        />
      );
  }
};

const CaretLeft = ({ iconColor, widthName }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M6.35355 7.64645L9.14645 4.85355C9.46143 4.53857 10 4.76165 10 5.20711L10 10.7929C10 11.2383 9.46143 11.4614 9.14645 11.1464L6.35355 8.35355C6.15829 8.15829 6.15829 7.84171 6.35355 7.64645Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 5.20708C10.5 4.31618 9.42284 3.87001 8.79288 4.49998L5.99999 7.29287C5.60946 7.68339 5.60946 8.31656 5.99999 8.70708L8.79288 11.5C9.42285 12.1299 10.5 11.6838 10.5 10.7929V5.20708Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.25 5.2071C10.25 4.53892 9.44213 4.2043 8.96966 4.67677L6.17676 7.46966C5.88387 7.76256 5.88387 8.23743 6.17676 8.53032L8.96966 11.3232C9.44213 11.7957 10.25 11.4611 10.25 10.7929V5.2071Z"
          fill={iconColor}
        />
      );
  }
};
