_ = require 'lodash'
$ = require 'jquery'
ko = require 'knockout'

api = require '../classes/api'
SectionBase = require '../classes/sectionbase'
Router = require '../utils/router'
{createPasswordValidationErrors} = require '../../../main/scripts/utils/password'

module.exports = class JoinSection extends SectionBase
  sectionRequiresAuth: false

  constructor: ->
    super()

    # Validate the link
    @token = Router.param 'token'

    unless @token
      alert 'Invalid workspace invitation link.'
      Router.go 'index.html'
      return

    # Set up observables and apply bindings
    @email = ko.observable ''
    @submitting = ko.observable false
    @newUser = ko.observable false
    @passwordEnabled = ko.observable false

    @fullname = ko.observable ''
    @password1 = ko.observable ''
    @password2 = ko.observable ''
    @errors = ko.observable null

    @newPassErrors = ko.computed =>
      errors = @errors()?.newPass
      if errors?.length > 0 then errors.join('\n') else null

    @confirmPassErrors = ko.computed =>
      errors = @errors()?.confirmPass
      if errors?.length > 0 then errors.join('\n') else null

    ko.applyBindings(this)

    # Attempt to join an existing user to the workspace
    api.join_existing_user(@token, (response) =>
      if response.error is 'CREATE_USER'
        # No user exists; display the form for creating one
        api.get_status ({password_authentication}) =>
          @newUser true
          @email response.username
          $('#name-field').focus()

          # If password auth is disabled, hide the password form
          # fields and send null as the value to the server.
          @passwordEnabled(password_authentication)
          if not password_authentication
            @password1(null)
            @password2(null)

      else
        @handleResponse response
    )

  submit: =>
      # There can't be password errors if we disable passwords
    if @passwordEnabled()
      @errors createPasswordValidationErrors(@password1(), @password2(), @email())
    else
      @errors(null)

    if _.isEmpty(@errors())
      @submitting true
      api.join_new_user(@token, @fullname(), @password1(), (response) =>
        @submitting false
        @handleResponse response
      )

  handleResponse: (response) =>
    if response.error?
      # The most probable error is an invalid password,
      # but in any case there's nowhere useful to send them
      alert 'There was an error joining the workspace: ' + response.message
    else
      if (not @isLoggedIn()) or (@loggedInUser() is response)
        alert 'Workspace joined!'
      else
        alert 'Workspace joined!\n\nYou are currently logged in as ' +
          @loggedInUser() + ', but if you\'d like, you can log out and log in as ' + response + '.'
      Router.go 'index.html'
