import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { StoreContext } from '../StoreContext';
import { getConcepts } from '../utils/ApiUtilsV5';
import { useFilter } from '../search_params';
import { Concept } from '../classes/Concepts';

/**
 * Hook that returns an object mapping each shared concept list's ID to an array
 * of its concepts. Note that the returned concepts are not the same concepts
 * already stored on the shared list, but instead the full concept, including
 * information such as the vectors and term IDs.
 *
 * @returns {{[concept_list_id: string]: Concept[]}} - Object mapping a concept
 * list's ID to an array of its concepts.
 */
export const useConceptsBySharedList = () => {
  const { sharedConceptLists } = useContext(StoreContext);
  const concepts = useSharedAndTopConcepts();
  const conceptsByText = getConceptsByText(concepts);

  if (!sharedConceptLists) {
    return {};
  }

  return Object.fromEntries(
    sharedConceptLists.map(cl => [
      cl.concept_list_id,
      cl.concepts.map(c => conceptsByText[stringifyConcept(c)]?.update(c))
    ])
  );
};

const useSharedAndTopConcepts = () => {
  const { projectId } = useParams();
  const filter = useFilter();
  const { topConcepts, sharedConceptLists } = useContext(StoreContext);
  const uniqueSharedTexts = getUniqueTexts(
    getConceptsInSharedLists(sharedConceptLists)
  );
  // Avoid fetching concepts in the top 500 because we already have that info.
  const topConceptsByText = getConceptsByText(topConcepts);
  const uniqueSharedTextsOutsideTop500 = uniqueSharedTexts.filter(
    t => !topConceptsByText[t]
  );

  const key = [
    projectId,
    'shared-concept-lists-concepts',
    uniqueSharedTextsOutsideTop500,
    filter
  ];

  const { data } = useQuery(
    key,
    () => {
      const concepts = uniqueSharedTextsOutsideTop500.map(stringToAPIConcept);
      return getConcepts(projectId, { type: 'specified', concepts }, filter);
    },
    {
      keepPreviousData: true,
      enabled: uniqueSharedTextsOutsideTop500.length > 0
    }
  );

  return [...topConcepts, ...(data?.result ?? [])];
};

const stringToAPIConcept = s => ({ texts: Concept.fromString(s).texts });

const getConceptsByText = concepts =>
  Object.fromEntries(concepts.map(c => [stringifyConcept(c), c]));

const getConceptsInSharedLists = conceptLists =>
  conceptLists?.map(({ concepts }) => concepts).flat() ?? [];

const getUniqueTexts = concepts => [...new Set(concepts.map(stringifyConcept))];

const stringifyConcept = concept => {
  const c = concept instanceof Concept ? concept : Concept.fromJSON(concept);
  return c.toString();
};
