import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Person = ({ iconColor, lineWidth, widthName, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M10.8316 4.53476C10.8316 6.09863 9.56387 7.3664 8 7.3664C6.43613 7.3664 5.16837 6.09863 5.16837 4.53476C5.16837 2.97089 6.43613 1.70312 8 1.70312C9.56387 1.70312 10.8316 2.97089 10.8316 4.53476Z"
      stroke={iconColor}
      strokeLinejoin="round"
      strokeWidth={widthName !== 'light' ? lineWidth : null}
    />
    <path
      d="M2 13.8112C2 11.661 3.74307 9.91793 5.89327 9.91793H10.1067C12.2569 9.91793 14 11.661 14 13.8112C14 14.08 13.7821 14.2979 13.5133 14.2979H2.48666C2.21788 14.2979 2 14.08 2 13.8112Z"
      stroke={iconColor}
      strokeLinejoin="round"
      strokeWidth={widthName !== 'light' ? lineWidth : null}
    />
  </SVGWrapper>
);
