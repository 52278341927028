import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function SpinnerDotted({ iconColor, widthName, ...props }) {
  return (
    <SVGWrapper {...props}>
      <SpinnerDottedIcon iconColor={iconColor} widthName={widthName} />
    </SVGWrapper>
  );
}
const SpinnerDottedIcon = ({ iconColor, widthName }) => {
  if (widthName === 'light') {
    return (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.14554 1.16611L8.18965 1.16602L8.23376 1.16611C9.03883 1.1697 9.68856 1.82525 9.68497 2.63032C9.68138 3.43539 9.02583 4.08512 8.22076 4.08154L8.18965 4.08147L8.15854 4.08154C7.35347 4.08512 6.69792 3.43539 6.69433 2.63032C6.69074 1.82525 7.34047 1.1697 8.14554 1.16611ZM5.31808 3.43826C5.84688 3.97184 5.843 4.83306 5.30943 5.36186L5.26872 5.40257C4.73992 5.93614 3.8787 5.94002 3.34512 5.41122C2.81154 4.88243 2.80767 4.0212 3.33646 3.48763C3.35572 3.4682 3.37506 3.44886 3.39449 3.4296C3.92806 2.90081 4.78929 2.90468 5.31808 3.43826ZM13.4654 2.94481C12.7406 2.22644 11.5706 2.2317 10.8522 2.95657C10.1339 3.68143 10.1391 4.8514 10.864 5.56977L10.9193 5.62508C11.6377 6.34994 12.8077 6.35521 13.5325 5.63684C14.2574 4.91847 14.2626 3.7485 13.5443 3.02363C13.5181 2.99724 13.4918 2.97096 13.4654 2.94481ZM14.0108 8.15517C14.2766 8.15398 14.4931 8.36854 14.4943 8.63439L14.4943 8.65516L14.4943 8.67594C14.4931 8.94179 14.2766 9.15634 14.0108 9.15516C13.7449 9.15397 13.5304 8.9375 13.5315 8.67165L13.5316 8.65516L13.5315 8.63868C13.5304 8.37283 13.7449 8.15635 14.0108 8.15517ZM5.1507 11.9224C4.78406 11.5525 4.18693 11.5498 3.81698 11.9164C3.44702 12.2831 3.44433 12.8802 3.81097 13.2502C3.83 13.2694 3.84912 13.2885 3.86832 13.3075C4.23828 13.6742 4.83541 13.6715 5.20205 13.3015C5.56869 12.9316 5.566 12.3344 5.19605 11.9678L5.1507 11.9224ZM7.62911 13.6139C7.89995 13.3454 8.3371 13.3474 8.60552 13.6182L8.63871 13.6514C8.90955 13.9199 8.91152 14.357 8.64311 14.6278C8.37469 14.8987 7.93754 14.9007 7.6667 14.6322L7.62472 14.5903C7.3563 14.3194 7.35827 13.8823 7.62911 13.6139ZM3.60662 8.38656C3.60921 7.80642 3.14102 7.33404 2.56088 7.33145C1.98075 7.32886 1.50836 7.79706 1.50578 8.37719L1.50568 8.42253L1.50578 8.46786C1.50836 9.04799 1.98075 9.51619 2.56088 9.5136C3.14102 9.51102 3.60921 9.03863 3.60662 8.4585L3.60654 8.42253L3.60662 8.38656Z"
          fill={iconColor}
        />
        <path
          d="M12.524 11.6807C12.7766 11.9311 12.7785 12.3389 12.5281 12.5915L12.4889 12.6307C12.2363 12.8811 11.8285 12.8792 11.5781 12.6266C11.3278 12.3739 11.3296 11.9662 11.5822 11.7158L11.6132 11.6848C11.8636 11.4322 12.2713 11.4304 12.524 11.6807Z"
          fill={iconColor}
        />
      </>
    );
  } else {
    const width = widthName === 'medium' ? '0.5' : '0.8';
    return (
      <>
        <path
          d="M8.14554 1.16611L8.18965 1.16602L8.23376 1.16611C9.03883 1.1697 9.68856 1.82525 9.68497 2.63032C9.68138 3.43539 9.02583 4.08512 8.22076 4.08154L8.18965 4.08147L8.15854 4.08154C7.35347 4.08512 6.69792 3.43539 6.69433 2.63032C6.69074 1.82525 7.34047 1.1697 8.14554 1.16611Z"
          fill={iconColor}
        />
        <path
          d="M5.31808 3.43826C5.84688 3.97184 5.843 4.83306 5.30943 5.36186L5.26872 5.40257C4.73992 5.93614 3.8787 5.94002 3.34512 5.41122C2.81154 4.88243 2.80767 4.0212 3.33646 3.48763C3.35572 3.4682 3.37506 3.44886 3.39449 3.4296C3.92806 2.90081 4.78929 2.90468 5.31808 3.43826Z"
          fill={iconColor}
        />
        <path
          d="M13.4654 2.94481C12.7406 2.22644 11.5706 2.2317 10.8522 2.95657C10.1339 3.68143 10.1391 4.8514 10.864 5.56977L10.9193 5.62508C11.6377 6.34994 12.8077 6.35521 13.5325 5.63684C14.2574 4.91847 14.2626 3.7485 13.5443 3.02363C13.5181 2.99724 13.4918 2.97096 13.4654 2.94481Z"
          fill={iconColor}
        />
        <path
          d="M14.0108 8.15517C14.2766 8.15398 14.4931 8.36854 14.4943 8.63439L14.4943 8.65516L14.4943 8.67594C14.4931 8.94179 14.2766 9.15634 14.0108 9.15516C13.7449 9.15397 13.5304 8.9375 13.5315 8.67165L13.5316 8.65516L13.5315 8.63868C13.5304 8.37283 13.7449 8.15635 14.0108 8.15517Z"
          fill={iconColor}
        />
        <path
          d="M5.1507 11.9224C4.78406 11.5525 4.18693 11.5498 3.81698 11.9164C3.44702 12.2831 3.44433 12.8802 3.81097 13.2502C3.83 13.2694 3.84912 13.2885 3.86832 13.3075C4.23828 13.6742 4.83541 13.6715 5.20205 13.3015C5.56869 12.9316 5.566 12.3344 5.19605 11.9678L5.1507 11.9224Z"
          fill={iconColor}
        />
        <path
          d="M7.62911 13.6139C7.89995 13.3454 8.3371 13.3474 8.60552 13.6182L8.63871 13.6514C8.90955 13.9199 8.91152 14.357 8.64311 14.6278C8.37469 14.8987 7.93754 14.9007 7.6667 14.6322L7.62472 14.5903C7.3563 14.3194 7.35827 13.8823 7.62911 13.6139Z"
          fill={iconColor}
        />
        <path
          d="M3.60662 8.38656C3.60921 7.80642 3.14102 7.33404 2.56088 7.33145C1.98075 7.32886 1.50836 7.79706 1.50578 8.37719L1.50568 8.42253L1.50578 8.46786C1.50836 9.04799 1.98075 9.51619 2.56088 9.5136C3.14102 9.51102 3.60921 9.03863 3.60662 8.4585L3.60654 8.42253L3.60662 8.38656Z"
          fill={iconColor}
        />
        <path
          d="M12.524 11.6807C12.7766 11.9311 12.7785 12.3389 12.5281 12.5915L12.4889 12.6307C12.2363 12.8811 11.8285 12.8792 11.5781 12.6266C11.3277 12.3739 11.3296 11.9662 11.5822 11.7158L11.6132 11.6848C11.8636 11.4322 12.2713 11.4304 12.524 11.6807Z"
          fill={iconColor}
        />
        <path
          d="M8.14554 1.16611L8.18965 1.16602L8.23376 1.16611C9.03883 1.1697 9.68856 1.82525 9.68497 2.63032C9.68138 3.43539 9.02583 4.08512 8.22076 4.08154L8.18965 4.08147L8.15854 4.08154C7.35347 4.08512 6.69792 3.43539 6.69433 2.63032C6.69074 1.82525 7.34047 1.1697 8.14554 1.16611Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.31808 3.43826C5.84688 3.97184 5.843 4.83306 5.30943 5.36186L5.26872 5.40257C4.73992 5.93614 3.8787 5.94002 3.34512 5.41122C2.81154 4.88243 2.80767 4.0212 3.33646 3.48763C3.35572 3.4682 3.37506 3.44886 3.39449 3.4296C3.92806 2.90081 4.78929 2.90468 5.31808 3.43826Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4654 2.94481C12.7406 2.22644 11.5706 2.2317 10.8522 2.95657C10.1339 3.68143 10.1391 4.8514 10.864 5.56977L10.9193 5.62508C11.6377 6.34994 12.8077 6.35521 13.5325 5.63684C14.2574 4.91847 14.2626 3.7485 13.5443 3.02363C13.5181 2.99724 13.4918 2.97096 13.4654 2.94481Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0108 8.15517C14.2766 8.15398 14.4931 8.36854 14.4943 8.63439L14.4943 8.65516L14.4943 8.67594C14.4931 8.94179 14.2766 9.15634 14.0108 9.15516C13.7449 9.15397 13.5304 8.9375 13.5315 8.67165L13.5316 8.65516L13.5315 8.63868C13.5304 8.37283 13.7449 8.15635 14.0108 8.15517Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.1507 11.9224C4.78406 11.5525 4.18693 11.5498 3.81698 11.9164C3.44702 12.2831 3.44433 12.8802 3.81097 13.2502C3.83 13.2694 3.84912 13.2885 3.86832 13.3075C4.23828 13.6742 4.83541 13.6715 5.20205 13.3015C5.56869 12.9316 5.566 12.3344 5.19605 11.9678L5.1507 11.9224Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.62911 13.6139C7.89995 13.3454 8.3371 13.3474 8.60552 13.6182L8.63871 13.6514C8.90955 13.9199 8.91152 14.357 8.64311 14.6278C8.37469 14.8987 7.93754 14.9007 7.6667 14.6322L7.62472 14.5903C7.3563 14.3194 7.35827 13.8823 7.62911 13.6139Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.60662 8.38656C3.60921 7.80642 3.14102 7.33404 2.56088 7.33145C1.98075 7.32886 1.50836 7.79706 1.50578 8.37719L1.50568 8.42253L1.50578 8.46786C1.50836 9.04799 1.98075 9.51619 2.56088 9.5136C3.14102 9.51102 3.60921 9.03863 3.60662 8.4585L3.60654 8.42253L3.60662 8.38656Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.524 11.6807C12.7766 11.9311 12.7785 12.3389 12.5281 12.5915L12.4889 12.6307C12.2363 12.8811 11.8285 12.8792 11.5781 12.6266C11.3277 12.3739 11.3296 11.9662 11.5822 11.7158L11.6132 11.6848C11.8636 11.4322 12.2713 11.4304 12.524 11.6807Z"
          stroke={iconColor}
          strokeWidth={width}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    );
  }
};
