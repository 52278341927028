import keymirror from 'keymirror';

export const SENTIMENT_STATUS = keymirror({
  READY: null,
  BUILDING: null,
  BUILD_FAILED: null,
  BUILT_WITHOUT_SENTIMENT: null
});

export function getSentimentStatus(project) {
  const sentiment = project?.last_build_info.sentiment;
  if (sentiment?.success === true) {
    return SENTIMENT_STATUS.READY;
  }
  if (sentiment?.success === false) {
    return SENTIMENT_STATUS.BUILD_FAILED;
  }
  if (sentiment?.skip_sentiment) {
    return SENTIMENT_STATUS.BUILT_WITHOUT_SENTIMENT;
  }
  return SENTIMENT_STATUS.BUILDING;
}
