import React, { useRef, useState } from 'react';
import ko from 'knockout';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import SectionBase from '../../classes/sectionbase';
import Router from '../../utils/router';
import { load, save } from '../../utils/localStorage';
import { REACT_QUERY_CLIENT_CONFIG } from '../../../../main/scripts/constants';
import { getSSOLoginURL, miscErrMsg, registerUserTypeInGTM } from './utils';
import { useRedirectToSSOLoginURLIfOnlySSOIsEnabled, useStatus } from './hooks';
import { DataUseAgreement } from './DataUseAgreement';
import { Alerts } from './Alerts';
import { LoginForm } from './LoginForm';
import { getProfile, login } from '../../../../main/scripts/utils/ApiUtilsV5';

export default class LoginSection extends SectionBase {
  sectionRequiresAuth = false;

  constructor() {
    super();
    const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <LoginPage />
      </QueryClientProvider>,
      document.getElementById('sections')
    );
    ko.applyBindings(this);
  }
}

export const LoginPage = () => {
  const status = useStatus();
  useRedirectToSSOLoginURLIfOnlySSOIsEnabled();
  const [recentlyRedirected, setRecentlyRedirected] = useState(
    Router.param('dest')?.length > 0
  );
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(load('username', ''));
  const [password, setPassword] = useState('');
  const [dataUseMessage, setDataUseMessage] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const formRef = useRef();

  const declineAgreement = () => {
    setPassword('');
    setDataUseMessage('');
  };

  const doLogin = (acknowledged = false) => {
    setRecentlyRedirected(false);
    setLoading(true);
    setLoginErrorMessage('');

    login(username, password, acknowledged)
      .then(getDest)
      .then(dest => {
        save('username', username);
        registerUserTypeInGTM(username);
        Router.go(dest);
      })
      .catch(({ code, message }) => {
        setLoading(false);

        if (code === 'ACKNOWLEDGEMENT_NEEDED') {
          setDataUseMessage(message);
        } else {
          // The most likely issue was an invalid password, so clear out the
          // password and focus on the input to make it easier for the user to
          // retry.
          setPassword('');
          formRef.current.focus();
          setLoginErrorMessage(
            code === 'AUTH_CREDS_INVALID' ? 'Invalid credentials.' : miscErrMsg
          );
        }
      });
  };

  return (
    <div id="login" className="section">
      <div className="content fade-in">
        <Alerts
          showLoginToContinuePrompt={recentlyRedirected}
          loginErrorMessage={loginErrorMessage}
        />
        {!status && <LoadingMessage />}
        {!dataUseMessage ? (
          <>
            {status?.password_authentication && (
              <LoginForm
                ref={formRef}
                onLogin={doLogin}
                loading={loading}
                username={username}
                onChangeUsername={setUsername}
                password={password}
                onChangePassword={setPassword}
              />
            )}
            {status?.saml_authentication && <SSOLink />}
            {status?.password_authentication && <ForgotPasswordLink />}
          </>
        ) : (
          <DataUseAgreement
            message={dataUseMessage}
            onAcknowledge={() => doLogin(true)}
            onDecline={declineAgreement}
          />
        )}
      </div>
    </div>
  );
};

const getDest = () => {
  // If there's a destination in the query string, go there.
  // Otherwise, go to the Projects page.
  const dest = Router.param('dest');
  if (dest) {
    return dest;
  } else {
    // The profile was already being fetched before we refactored this code (and
    // wasn't commented). We decided to err on the side of caution and leave it
    // in for the time being. Our best guess is that it's checking that the auth
    // token actually works.
    return getProfile().then(() => 'index.html');
  }
};

const SSOLink = () => {
  return (
    <div className="btns-container fade-in">
      <a id="sso-login" className="button" href={getSSOLoginURL()}>
        Log in with SSO
      </a>
    </div>
  );
};

const ForgotPasswordLink = () => {
  return (
    <div className="forgot fade-in">
      <a href="forgot.html">Forgot password?</a>
    </div>
  );
};

const LoadingMessage = () => {
  return (
    <span>
      Loading your login experience...
      <i className="fa fa-spinner fa-pulse"></i>
    </span>
  );
};
