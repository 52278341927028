# TODO: Heavily refactor to work similarly to the other API clients we have, rather than
# exposing a separate method for each piece of functionality, and as a side effect to have a uniform interface
# for error-handling. There are currently some calls that have an error callback separate from
# their main callback, while others check for data.error within the same callback as they
# do their regular result processing.
_ = require 'lodash'

authAjax = require '../utils/auth'

class LuminosoRequest
  constructor: (urlBase) ->
    @urlBase = urlBase

  url: (path...) =>
    @urlBase + path.join('/') + '/'

  urlFromBase: (path...) =>
    @urlBase + path.join('/') + '/'

  prepare: (params) ->
    # Return an object whose values are
    # ready to be sent to the API server,
    # data structures being suitably escaped.
    _.assign { }, params, (a, b) ->
      if _.isArray(b) or _.isObject(b)
        JSON.stringify b
      else
        b

  call: (type, path..., params) =>
    # Handle params-less requests.
    unless _.isObject params
      path.push params
      params = null

    authAjax {
      type: type.toUpperCase()
      url: @url path...
      data: @prepare params
    }

  get: (args...) -> @call 'get', args...
  post: (args...) -> @call 'post', args...
  put: (args...) -> @call 'put', args...
  delete: (args...) -> @call 'delete', args...
  patch: (args...) -> @call 'patch', args...

class LuminosoAPIv5 extends LuminosoRequest
  constructor: ->
    super('/api/v5/')

  logout: =>
    delete localStorage['auth_token']

  get_status: (cb) =>
    ajax = authAjax({
      type: 'GET'
      url: @urlFromBase('status')
      data: {include_secrets: 'true'}
    })

    # Note: Use either the promise interface or the explicitly-passed callback interface, but not both.
    ajax.then(cb, cb)
    ajax

  join_new_user: (token, full_name, password, cb) =>
    ajax = authAjax({
      type: 'POST'
      contentType: 'application/json'
      url: @url 'workspaces', 'join'
      data: {
        body: {
          token,
          full_name,
          password,
        }
      }
    })
    # Note: Use either the promise interface or the explicitly-passed callback interface, but not both.
    ajax.then(cb, cb)
    ajax

  join_existing_user: (token, cb) =>
    ajax = authAjax({
      type: 'POST'
      contentType: 'application/json'
      url: @url 'workspaces', 'join'
      data: {
        body: {
          token
        }
      }
    })
    # Note: Use either the promise interface or the explicitly-passed callback interface, but not both.
    ajax.then(cb, cb)
    ajax


module.exports = new LuminosoAPIv5()
