import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Walmart = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper defaultSize="233" {...props}>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#0071CE"
          d="M135.4743805,29.8036613c0-5.5308571-2.3675842-10.4175968-10.3747253-10.4175968   c-4.1080627,0-7.3714752,1.1708717-9.1407852,2.1957397l1.2968369,4.4372807   c1.6290054-1.022007,4.2282715-1.8636551,6.6816483-1.8636551c4.0537186-0.0057278,4.7178574,2.2873478,4.7178574,3.7616692   v0.3549843c-8.8488083-0.0143185-14.4425888,3.0431156-14.4425888,9.2696228c0,3.804615,2.8398743,7.3716202,7.8039322,7.3716202   c3.0287552,0,5.5880127-1.2224007,7.1167145-3.1519012h0.14888c0,0,1.0078278,4.2168465,6.5815277,2.605114   c-0.2834167-1.7663269-0.3892975-3.627121-0.3892975-5.8887024V29.8036613z M128.8729248,35.6436958   c0,0.4465904-0.0372162,0.9103622-0.1689606,1.3082848c-0.5096741,1.7033424-2.2672806,3.1290016-4.4545441,3.1290016   c-1.8463593,0-3.2950439-1.0449066-3.2950439-3.2349243c0-3.3494339,3.6986694-4.2712402,7.9185486-4.2483406"
        />
        <path
          fill="#0071CE"
          d="M0,11.8607178c0,0,5.7798676,23.6464272,6.6988559,27.4768028   c1.082098,4.4773636,3.0201945,6.1320381,8.6140633,5.0298729l3.6127882-14.6630726   c0.9132233-3.632843,1.5343857-6.237957,2.1241837-9.9395084h0.1001205   c0.4093895,3.7387676,0.9991894,6.3066654,1.7463303,9.950964c0,0,1.4742775,6.6616497,2.238718,10.1513577   c0.7442589,3.4897079,2.8397865,5.6911736,8.3277702,4.5002594l8.5997353-32.5066757h-6.9422531l-2.9514389,14.0618954   c-0.7815647,4.0737133-1.4915771,7.2828732-2.0440712,11.015913h-0.1002064   c-0.4980659-3.6929626-1.1307602-6.7704353-1.9437752-10.7611256L25.023407,11.8607178h-7.2427864l-3.2635927,13.9502487   c-0.9304333,4.2597904-1.8006725,7.6750679-2.3474874,11.290741H12.075099   c-0.5639381-3.3923798-1.3282909-7.712286-2.1385107-11.8117657c0,0-1.9380965-9.9881725-2.6222486-13.429224"
        />
        <path
          fill="#0071CE"
          d="M61.4092827,29.8036613c0-5.5308571-2.3733482-10.4175968-10.3804893-10.4175968   c-4.0965271,0-7.3657951,1.1708717-9.1349297,2.1957397l1.2967529,4.4372807   c1.6232338-1.022007,4.219799-1.8636551,6.6817322-1.8636551c4.0536308-0.0057278,4.7177773,2.2873478,4.7177773,3.7616692   v0.3549843c-8.8487244-0.0143185-14.4482727,3.0431156-14.4482727,9.2696228c0,3.804615,2.8513184,7.3716202,7.7924004,7.3716202   c3.0573235,0,5.5938683-1.2224007,7.1225739-3.1519012h0.1544609c0,0,1.0134277,4.2168465,6.5815239,2.605114   c-0.2862053-1.7663269-0.3835297-3.627121-0.3835297-5.8887024V29.8036613z M54.8020706,35.6436958   c0,0.4465904-0.0372162,0.9103622-0.1631966,1.3082848c-0.5154495,1.7033424-2.2730522,3.1290016-4.4659042,3.1290016   c-1.8350945,0-3.2893639-1.0449066-3.2893639-3.2349243c0-3.3494339,3.6985817-4.2712402,7.9184647-4.2483406"
        />
        <polygon
          fill="#0071CE"
          points="65.011673,11.8607178 65.011673,44.3559456 71.6188812,44.3559456 71.6188812,37.4652748    71.6188812,11.8607178"
        />
        <path
          fill="#0071CE"
          d="M155.7529297,11.8607178v23.9756432c0,3.3093567,0.6211548,5.6167412,1.9580078,7.0309525   c1.1738281,1.2395706,3.094635,2.0468712,5.3820038,2.0468712c1.9639587,0,3.8847656-0.3778877,4.7923126-0.7071037   l-0.0802002-5.1615601c-0.6784821,0.1574516-1.4542847,0.2919998-2.5192566,0.2919998   c-2.2557526,0-3.0000916-1.4285164-3.0000916-4.4000626v-9.1780205h5.7541809v-6.2093353h-5.7541809v-7.6893845"
        />
        <path
          fill="#0071CE"
          d="M138.675415,19.9500256V44.366539h6.8305206V31.8619881   c0-0.6727524,0.0428009-1.253891,0.1488647-1.7920895c0.5095062-2.63661,2.5105133-4.3056011,5.3933716-4.3056011   c0.8044434,0,1.351181,0.088747,1.9581909,0.1832199v-6.4011383c-0.5096893-0.0916023-0.8560028-0.140276-1.4829407-0.140276   c-2.5564575,0-5.4536438,1.6231861-6.6760406,5.1587009h-0.1687927v-4.6147785"
        />
        <path
          fill="#0071CE"
          d="M75.4557648,19.9500256V44.366539h6.6557846V30.0355434   c0-0.6727524,0.0744324-1.3598137,0.3120651-1.9810314c0.5411377-1.4514217,1.8837585-3.1318626,4.0393906-3.1318626   c2.6794739,0,3.9448624,2.2615795,3.9448624,5.525135V44.366539h6.6388321V29.8637791   c0-0.6326733,0.088768-1.4084816,0.2746811-1.9695816c0.5325699-1.6460915,1.9868393-2.9715481,3.9965744-2.9715481   c2.7081299,0,4.0193024,2.2129116,4.0193024,6.0604668V44.366539h6.6328964V29.969698   c0-7.5634174-3.8475418-10.5635948-8.2161179-10.5635948c-1.9207993,0-3.4495087,0.4723587-4.8237534,1.3197365   c-1.1623001,0.7128239-2.1813202,1.7176533-3.0946274,3.0316658h-0.1001205   c-1.0506439-2.6280193-3.5183487-4.3514023-6.7419281-4.3514023c-4.1337433,0-6.011734,2.086956-7.1167221,3.8504162h-0.1002884   v-3.3064938"
        />
        <path
          fill="#FFC220"
          d="M203.7866821,18.8541565c1.3054047,0,2.4046326-0.6813354,2.5477295-1.5573406l1.2940369-14.6573458   C207.6284485,1.1880494,205.9194336,0,203.7866821,0c-2.1442719,0-3.8419495,1.1880494-3.8419495,2.6394703l1.3054047,14.6573458   C201.3876495,18.172821,202.4811096,18.8541565,203.7866821,18.8541565"
        />
        <path
          fill="#FFC220"
          d="M195.8473358,23.4405899c0.6468506-1.1451035,0.6096344-2.416172-0.0887604-2.9829979   l-12.0809479-8.4480162c-1.2566528-0.7328577-3.137619,0.1402855-4.2139435,1.9896307   c-1.0735474,1.8464727-0.8560028,3.9191208,0.3749695,4.6376743l13.3748169,6.2007408   c0.8560028,0.3206272,1.9753113-0.274828,2.6338654-1.4084816"
        />
        <path
          fill="#FFC220"
          d="M211.7242889,23.428566c0.6840515,1.1336613,1.8033752,1.7291107,2.6364899,1.4256611l13.3748169-6.217926   c1.2424927-0.7328568,1.4285889-2.7854671,0.3779449-4.6319513c-1.0735474-1.8493443-2.9658661-2.7167549-4.2197113-1.9982033   l-12.0837555,8.4394217c-0.6699066,0.5754166-0.7184906,1.852211-0.07164,2.9944553"
        />
        <path
          fill="#FFC220"
          d="M203.7866821,37.1629601c1.3054047,0,2.4046326,0.6813393,2.5477295,1.5573463l1.2940369,14.6573486   c0,1.4514236-1.7090149,2.6280174-3.8417664,2.6280174c-2.1442719,0-3.8419495-1.1765938-3.8419495-2.6280174l1.3054047-14.6573486   C201.3876495,37.8442993,202.4811096,37.1629601,203.7866821,37.1629601"
        />
        <path
          fill="#FFC220"
          d="M211.7242889,32.5894127c0.6840515-1.1365166,1.8033752-1.7434216,2.6364899-1.4142036   l13.3748169,6.2064743c1.2424927,0.7185516,1.4285889,2.7883301,0.3779449,4.6376724   c-1.0735474,1.8350334-2.9658661,2.7081718-4.2197113,1.9896202l-12.0837555-8.4508743   c-0.6699066-0.5696907-0.7184906-1.8407593-0.07164-2.968689"
        />
        <path
          fill="#FFC220"
          d="M195.8473358,32.5768166c0.6468506,1.1422424,0.6096344,2.41045-0.0887604,2.9830017l-12.0809479,8.4365616   c-1.2566528,0.7328682-3.137619-0.1431351-4.2139435-1.9781685c-1.0735474-1.8464813-0.8560028-3.9191246,0.3749695-4.6376762   l13.3748169-6.2064724C194.0694733,30.8477116,195.1887817,31.4488888,195.8473358,32.5768166"
        />
        <path
          fill="#FFC220"
          d="M231.6495361,42.7227364h0.166153c0.2261047,0,0.3176727,0.0715675,0.3435211,0.2519264   c0.048584,0.2003899,0.0744324,0.334938,0.1230164,0.3921967h0.4294891   c-0.0372314-0.042942-0.085968-0.1517258-0.1116638-0.4036522c-0.0487518-0.2175674-0.1604004-0.3406677-0.3178253-0.3836098   v-0.0400772c0.2175293-0.0486679,0.3435211-0.1946716,0.3435211-0.3750229c0-0.1631775-0.0744476-0.2805519-0.14888-0.3549843   c-0.1430969-0.0687065-0.2804413-0.1230965-0.5839386-0.1230965c-0.2690887,0-0.4667053,0.0228996-0.6241302,0.0601158v1.620327   h0.3807373V42.7227364z M231.6610565,41.9841423l0.1946564-0.0257645c0.2290649,0,0.3520813,0.1059227,0.3520813,0.2547874   c0,0.1660423-0.1660004,0.2433319-0.3777771,0.2433319h-0.1689606V41.9841423z M231.9044647,40.8848419   c-0.9389954,0-1.6833496,0.7328682-1.6833496,1.6317787c0,0.9218063,0.7443542,1.6546745,1.6833496,1.6546745   c0.9503479,0,1.6717987-0.7328682,1.6717987-1.6546745C233.5762634,41.6177101,232.8548126,40.8848419,231.9044647,40.8848419    M231.9044647,41.2140579c0.721283,0,1.2566528,0.5840034,1.2566528,1.3025627   c0,0.7242737-0.5353699,1.3254585-1.2566528,1.3140068c-0.7214661,0-1.2683716-0.5897331-1.2683716-1.3140068   c0-0.7185593,0.5469055-1.3025627,1.2568359-1.3025627"
        />
      </g>
    </SVGWrapper>
  );
};
