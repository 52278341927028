import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Volume = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper defaultSize="32" {...props}>
    <path
      d="M4 26.6667V6.66666C4 5.93028 4.59695 5.33333 5.33333 5.33333H8C8.73638 5.33333 9.33333 5.93028 9.33333 6.66666V26.6667M4 26.6667C4 27.403 4.59695 28 5.33333 28H8C8.73638 28 9.33333 27.403 9.33333 26.6667M4 26.6667V14.6667C4 13.9303 4.59695 13.3333 5.33333 13.3333H8C8.73638 13.3333 9.33333 13.9303 9.33333 14.6667V26.6667M22.6667 26.6667V12C22.6667 11.2636 23.2636 10.6667 24 10.6667H26.6667C27.403 10.6667 28 11.2636 28 12V26.6667M22.6667 26.6667C22.6667 27.403 23.2636 28 24 28H26.6667C27.403 28 28 27.403 28 26.6667M22.6667 26.6667V17.3333C22.6667 16.5969 23.2636 16 24 16H26.6667C27.403 16 28 16.5969 28 17.3333V26.6667M14.6667 28H17.3333C18.0697 28 18.6667 27.403 18.6667 26.6667V18.6667C18.6667 17.9303 18.0697 17.3333 17.3333 17.3333H14.6667C13.9303 17.3333 13.3333 17.9303 13.3333 18.6667V26.6667C13.3333 27.403 13.9303 28 14.6667 28ZM14.6667 27.8905H17.3333C18.0697 27.8905 18.6667 27.2935 18.6667 26.5571V22.6667C18.6667 21.9303 18.0697 21.3333 17.3333 21.3333H14.6667C13.9303 21.3333 13.3333 21.9303 13.3333 22.6667V26.5571C13.3333 27.2935 13.9303 27.8905 14.6667 27.8905Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
  </SVGWrapper>
);
