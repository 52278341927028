import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Galaxy = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper defaultSize="32" {...props}>
    <path
      d="M24.978 17.2038C25.654 19.3182 25.528 21.6074 24.6241 23.6348C23.7201 25.6622 22.1015 27.2859 20.0769 28.1961C18.0523 29.1064 15.7635 29.2394 13.6471 28.57C13.5018 28.524 13.3581 28.4745 13.216 28.4216M22.9106 13.7201C21.3787 12.1136 19.309 11.1274 17.0963 10.9494C14.8837 10.7715 12.683 11.4143 10.914 12.7552C9.14499 14.0961 7.93153 16.0414 7.5051 18.2198C7.07867 20.3982 7.4691 22.6574 8.60192 24.5664C8.76039 24.8335 8.93183 25.091 9.11531 25.3384"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
    <path
      d="M8.08523 28.4308C5.67761 29.4627 3.91334 29.7061 3.29305 28.9277C2.57314 28.0243 3.53891 25.9261 5.65991 23.3146M14.2049 24.9927C15.6048 24.0565 17.062 22.9928 18.5294 21.8234C25.5884 16.1983 30.2117 10.259 28.8559 8.55756C28.0553 7.55296 25.3494 8.25032 21.8142 10.1479"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="10.6172"
      cy="27.4424"
      rx="2.53483"
      ry="2.53483"
      transform="rotate(-8.35918 10.6172 27.4424)"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
    <path
      d="M7.14482 3.1987C7.20775 3.0211 7.45892 3.0211 7.52185 3.1987L8.52243 6.02253C8.54258 6.07938 8.5873 6.1241 8.64415 6.14425L11.468 7.14482C11.6456 7.20775 11.6456 7.45892 11.468 7.52185L8.64415 8.52243C8.5873 8.54258 8.54257 8.5873 8.52243 8.64415L7.52185 11.468C7.45892 11.6456 7.20775 11.6456 7.14482 11.468L6.14425 8.64415C6.1241 8.5873 6.07938 8.54257 6.02253 8.52243L3.1987 7.52185C3.0211 7.45892 3.0211 7.20775 3.1987 7.14482L6.02253 6.14425C6.07938 6.1241 6.1241 6.07938 6.14425 6.02253L7.14482 3.1987Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinejoin="round"
    />
    <path
      d="M20.2046 3.78444C20.3749 3.37247 20.9584 3.37246 21.1287 3.78444L21.7972 5.4015C21.8544 5.53991 21.9707 5.64539 22.114 5.68891L23.7581 6.18825C24.231 6.33189 24.231 7.00146 23.7581 7.14509L22.114 7.64443C21.9707 7.68796 21.8544 7.79343 21.7972 7.93184L21.1287 9.5489C20.9584 9.96088 20.3749 9.96088 20.2046 9.5489L19.5361 7.93184C19.4789 7.79343 19.3627 7.68796 19.2193 7.64443L17.5752 7.14509C17.1023 7.00146 17.1023 6.33189 17.5752 6.18825L19.2193 5.68891C19.3627 5.64539 19.4789 5.53991 19.5361 5.4015L20.2046 3.78444Z"
      fill={iconColor}
    />
  </SVGWrapper>
);
