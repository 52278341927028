import { curry, uniqWith } from 'lodash';
import { polygonCentroid } from 'd3-polygon';

import { Concept } from '../classes/Concepts';
import sortObjects from '../utils/sortObjects';
import { naturalSortByName } from '../utils/NaturalSort';
import { NumericField, ScoreField } from '../classes/MetadataFields';

export const instanceOf = curry((Class, obj) => obj instanceof Class);

export const hasEqualId = curry((id, dataPoint) => dataPoint.id === id);

export const fieldHasNoRange = field => field.minimum === field.maximum;

export function noValidRanges(metadata) {
  return metadata
    .filter(
      field => instanceOf(ScoreField, field) || instanceOf(NumericField, field)
    )
    .every(fieldHasNoRange);
}

export const areCollinear = dataPoints => {
  const slope = (a, b) => (b.y - a.y) / (b.x - a.x);
  const comparePoints = (p1, p2) => p1.x === p2.x && p1.y === p2.y;
  const uniquePoints = uniqWith(dataPoints, comparePoints);
  if (uniquePoints.length <= 2) return true;
  const [pointA, pointB, ...points] = uniquePoints;
  return points.every(point => slope(pointA, point) === slope(pointA, pointB));
};

const LABEL_OFFSET = 8;

export const ORIENT = {
  TOP: { textAnchor: 'middle', dy: -LABEL_OFFSET },
  RIGHT: { textAnchor: 'start', dx: LABEL_OFFSET, dy: 4 },
  BOTTOM: { textAnchor: 'middle', dy: LABEL_OFFSET + 12 },
  LEFT: { textAnchor: 'end', dx: -LABEL_OFFSET, dy: 4 }
};

export const labelOrientation = (cell, x, y, { width, height }, bounds) => {
  if (cell) {
    // Calculate angle between point and the center of its voronoi cell
    const [centerX, centerY] = polygonCentroid(cell);
    const angle = Math.round(
      (Math.atan2(centerY - y, centerX - x) / Math.PI) * 2
    );

    const textWidth = Math.abs(angle) === 1 ? width / 2 : width - LABEL_OFFSET;
    const textHeight =
      Math.abs(angle) === 1 ? height - LABEL_OFFSET : height / 2;

    const availableSpace = {
      left: x - bounds.left,
      right: bounds.right - x,
      top: y - bounds.top,
      bottom: bounds.bottom - y
    };

    if (availableSpace.left <= textWidth) return ORIENT.RIGHT;
    if (availableSpace.right <= textWidth) return ORIENT.LEFT;
    if (availableSpace.top <= textHeight) return ORIENT.BOTTOM;
    if (availableSpace.bottom <= textHeight) return ORIENT.TOP;
    if (angle === -1) return ORIENT.TOP;
    if (angle === 1) return ORIENT.BOTTOM;
    if (angle === 0) return ORIENT.RIGHT;
    return ORIENT.LEFT; // angle == ±2
  }

  return ORIENT.TOP;
};

export function sortConcepts(
  concepts = [],
  activeConcepts = [],
  property,
  direction
) {
  const directionModifier = direction === 'asc' ? 1 : -1;

  return sortObjects(
    concepts,
    (conceptA, conceptB) => {
      switch (property) {
        case 'name': {
          const activeConceptA = activeConcepts.find(activeConcept =>
            Concept.areTextsEqual(activeConcept, conceptA)
          );
          const activeConceptB = activeConcepts.find(activeConcept =>
            Concept.areTextsEqual(activeConcept, conceptB)
          );
          return (
            directionModifier *
            naturalSortByName(
              activeConceptA || conceptA,
              activeConceptB || conceptB
            )
          );
        }
        case 'matchCount':
          return (
            directionModifier * (conceptA.matchCount - conceptB.matchCount)
          );
        case 'averageScore':
          return (
            directionModifier * (conceptA.averageScore - conceptB.averageScore)
          );
        case 'absoluteDifference':
          return (
            directionModifier *
            (Math.abs(conceptA.impact) - Math.abs(conceptB.impact))
          );
        default:
          return 0;
      }
    },
    naturalSortByName
  );
}

const isScoreField = instanceOf(ScoreField);
const isNumberField = instanceOf(NumericField);
const hasValidRange = field => !fieldHasNoRange(field);

export function getDefaultDriversField(metadata) {
  const fields = metadata.filter(hasValidRange).sort(naturalSortByName);
  const scoreFields = fields.filter(isScoreField);
  const numericFields = fields.filter(isNumberField);
  return scoreFields[0]?.name ?? numericFields[0]?.name;
}

export function isValidDriversField(metadata, fieldName) {
  const validDriversFields = metadata
    .filter(field => isScoreField(field) || isNumberField(field))
    .filter(field => !fieldHasNoRange(field))
    .map(field => field.name);

  return validDriversFields.includes(fieldName);
}
