import { useContext } from 'react';
import _ from 'lodash';

import * as SearchUtils from '../../search_params/SearchUtils';
import { RoutePatterns } from '../../constants';
import buildRoutePath from '../../utils/buildRoutePath';
import { StoreContext } from '../../StoreContext';
import { encodeFilter, useSearchParams } from '../../search_params';
import { useCurrentFeature } from '../../utils/hooks';
import { filtersAreEqual } from '../../utils/filterUtils';

export const POSSIBLE_SEARCH_PARAMS = [
  'filter',
  'search',
  'doc_match_type',
  'concepts',
  'prevalent_count',
  'drivers_count',
  'field',
  'drivers_of',
  'tab',
  'time',
  'comparison_field',
  'display_same',
  'match_type',
  'sortby',
  'breakdown',
  'normalized',
  'interval',
  'sync_fields'
  // TODO: uncomment when galaxy settings become a part of the shared view again
  // 'x_axis',
  // 'y_axis',
  // 'show_axes'
];

export function useCurrentView() {
  const currentFeature = useCurrentFeature();
  const { searchParams } = useSearchParams();
  const { sharedConceptLists, activeConceptListName } = useContext(
    StoreContext
  );
  const conceptListId = _.find(sharedConceptLists, {
    name: activeConceptListName
  })?.concept_list_id;
  return View.fromSearch(searchParams, currentFeature, conceptListId);
}

/**
 * This function checks if two saved views result in the same data
 * visualization by disregarding differences in properties that don't impact
 * the view.
 *
 * @param {object} viewA  - The first view to compare. Should have keys in
 *                          "POSSIBLE_SEARCH_PARAMS" and an encoded filter
 * @param {object} viewB  - The second view to compare. See requirements above.
 * @return {boolean}
 */

export function viewsAreEqual(viewA, viewB) {
  const areStrictlyEqual = _.isEqual(viewA, viewB);
  if (areStrictlyEqual) {
    return true;
  }

  const paramsForCustomComparison = [
    'prevalent_count',
    'drivers_count',
    'drivers_of',
    'filter',
    'sync_fields'
  ];
  if (
    !_.isEqual(
      _.omit(viewA, paramsForCustomComparison),
      _.omit(viewB, paramsForCustomComparison)
    )
  ) {
    return false;
  }

  let hasDifference = false;

  // prevalent_count, drivers_count, and drivers_of, are only relevant for view
  // comparison if we are visualizing the related concepts.
  if (viewA.concepts === 'prevalent') {
    hasDifference = viewA.prevalent_count !== viewB.prevalent_count;
  } else if (viewA.concepts === 'drivers') {
    hasDifference =
      viewA.drivers_count !== viewB.drivers_count ||
      viewA.drivers_of !== viewB.drivers_of;
  }

  if (viewA.feature === 'drivers' && viewA.concepts === 'drivers') {
    hasDifference = hasDifference || viewA.sync_fields !== viewB.sync_fields;
  }

  // Check for filter equality regardless of constraint order
  hasDifference = hasDifference || !filtersAreEqual(viewA.filter, viewB.filter);

  return !hasDifference;
}

export class View {
  static fromSearch(params, feature, concept_list_id = undefined) {
    const view = { feature, ..._.pick(params, POSSIBLE_SEARCH_PARAMS) };
    const canHaveConceptList =
      feature === 'galaxy' || params.concepts === 'active';
    if (concept_list_id && canHaveConceptList) {
      view.concept_list_id = concept_list_id;
    }
    view.filter = encodeFilter(view.filter);
    return new View(_.pickBy(view, value => value != null));
  }

  constructor(view) {
    this.view = view;
  }

  get dependsOnConceptList() {
    return this.feature === 'galaxy' || this.view.concepts === 'active';
  }

  get conceptListId() {
    return this.view.concept_list_id;
  }

  get feature() {
    return this.view.feature;
  }

  get conceptType() {
    if (this.view.concepts !== undefined) {
      return this.view.concepts;
    }

    switch (this.view.feature) {
      case 'volume':
        return 'prevalent';
      case 'sentiment':
        return 'sentiment';
      case 'drivers':
        // If there isn't a `drivers_of` field, then we can't actually visualize
        // drivers, so return undefined.
        if (!this.view.drivers_of) {
          return undefined;
        }
        return 'drivers';
      default:
        return undefined;
    }
  }

  get conceptLimit() {
    if (this.conceptType === 'prevalent') {
      return parseInt(this.view.prevalent_count);
    } else if (this.conceptType === 'drivers') {
      return parseInt(this.view.drivers_count);
    }

    return undefined;
  }

  get conceptSelector() {
    if (this.conceptType === undefined) {
      return undefined;
    }

    if (this.conceptType === 'active') {
      return {
        type: 'concept_list',
        concept_list_id: 'active'
      };
    } else if (this.conceptType === 'clusters') {
      return { type: 'suggested' };
    } else if (this.conceptType === 'prevalent') {
      return {
        type: 'top',
        limit: this.conceptLimit
      };
    } else if (this.conceptType === 'sentiment') {
      // We don't support concept limits for Sentiment suggestions in the UI
      // so we never pass one to the API
      return { type: 'sentiment_suggested' };
    } else if (this.conceptType === 'drivers') {
      return {
        type: 'drivers_suggested',
        score_field: this.view.drivers_of,
        limit: this.conceptLimit
      };
    }

    return {
      type: this.conceptType,
      limit: this.conceptLimit
    };
  }

  get sortDirection() {
    return this.view.sortby?.split('-')[1];
  }

  get conceptSelectorDescription() {
    switch (this.conceptType) {
      case 'prevalent':
        return `Top concepts by prevalence (${this.conceptLimit})`;
      case 'sentiment':
        return 'Concepts linked to strong feeling';
      case 'active':
        return 'Active concepts';
      case 'unique_to_filter':
        return 'Unique to this filter';
      case 'drivers':
        if (this.view.drivers_of === undefined) {
          return 'Concepts linked to scores (No score selected)';
        }
        return `Concepts linked to scores (${this.view.drivers_of}, ${this.conceptLimit})`;
      case 'clusters':
        return 'Conversation clusters';
      default:
        return null;
    }
  }

  get featureName() {
    return _.capitalize(this.view.feature);
  }

  get matchType() {
    const match_type = this.view.match_type === 'total' ? 'Include' : 'Exclude';
    return `${match_type} conceptual matches`;
  }

  get search() {
    // TODO Confirm formatting with Marleigh
    return this.view.search;
  }

  get docMatchType() {
    switch (this.view.doc_match_type) {
      case 'both':
        return 'Display documents with exact and conceptual matches';
      case 'exact':
        return 'Display documents with exact matches';
      case 'conceptual':
        return 'Display documents with conceptual matches';
      case 'neither':
        return 'No documents';
      default:
        return undefined;
    }
  }

  get currentScoreField() {
    return this.view.field;
  }

  get breakdown() {
    if (this.view.feature !== 'volume') {
      return undefined;
    }

    if (!this.view.breakdown) {
      return 'Visualize concept volume by overall concept volume';
    }

    let message = `Visualize concept volume by "${this.view.breakdown}"`;
    if (this.view.interval) {
      message += ` (${this.view.interval})`;
    }
    return message;
  }

  get normalized() {
    return this.view.normalized === 'true'
      ? 'Visualize matches as % of each subset'
      : 'Visualize matches as # of matches in each subset';
  }

  get sortBy() {
    const [column, direction] = this.view.sortby.split('-');
    const prettyDirection = direction === 'asc' ? 'ascending' : 'descending';

    let prettyColumn;
    switch (column) {
      case 'name':
        prettyColumn = 'concept name';
        break;
      case 'matchCount':
      case 'matches':
        if (
          this.view.feature === 'sentiment' ||
          this.view.match_type === 'exact'
        ) {
          prettyColumn = 'exact matches';
        } else {
          prettyColumn = 'total matches';
        }
        break;
      case 'averageScore':
        prettyColumn = 'average score';
        break;
      case 'absoluteDifference':
        prettyColumn = 'absolute difference';
        break;
      case 'negative':
        prettyColumn = 'negative match percentage';
        break;
      case 'positive':
        prettyColumn = 'positive match percentage';
        break;
    }

    return `Sort by ${prettyColumn} (${prettyDirection})`;
  }

  get show_axes() {
    const showAxes = this.view.show_axes === 'true';
    return showAxes ? 'Show axis labels' : 'Hide axis labels';
  }

  get tab() {
    let tab;
    switch (this.view.tab) {
      case 'compared-concepts':
        tab = 'Concepts (below)';
        break;
      case 'concepts':
        tab = this.view.comparison_field ? 'Concepts (above)' : 'Concepts';
        break;
      default:
        tab = 'Visualization';
    }
    return `Show ${tab} tab`;
  }

  get field() {
    return `Axis: ${this.view.field}`;
  }

  get syncFields() {
    const syncStatus = this.view.sync_fields === 'yes' ? 'Sync' : "Don't sync";
    return `${syncStatus} score fields`;
  }

  get display_same() {
    return this.view.display_same === 'true'
      ? 'Show same concepts in both scatterplots'
      : 'Show different concepts in each scatterplot';
  }

  get time() {
    return this.view.time === 'year_over_year'
      ? 'year-over-year'
      : 'previous period';
  }

  get comparison_field() {
    return `Compare scores over "${this.view.comparison_field}" (${this.time})`;
  }

  getNameForField(field) {
    if (field !== 'breakdown' && !this.view[field]) {
      return;
    }
    switch (field) {
      case 'feature':
        return this.featureName;
      case 'concepts':
        return this.conceptSelectorDescription;
      case 'match_type':
        return this.matchType;
      case 'search':
        return this.search;
      case 'doc_match_type':
        return this.docMatchType;
      case 'breakdown':
        return this.breakdown;
      case 'normalized':
        return this.normalized;
      case 'sortby':
        return this.sortBy;
      case 'x_axis':
        return this.xAxis;
      case 'y_axis':
        return this.yAxis;
      case 'show_axes':
        return this.show_axes;
      case 'interval':
        // The `interval` field is displayed with the `breakdown` field, so it
        // doesn't get its own line.
        return;
      case 'tab':
        return this.tab;
      case 'field':
        return this.field;
      case 'sync_fields':
        return this.syncFields;
      case 'display_same':
        return this.display_same;
      case 'comparison_field':
        return this.comparison_field;
      case 'time':
        // The `time` field is displayed with the `comparison_field` field, so
        // it doesn't get its own line.
        return;
      default:
        return this.view[field];
    }
  }

  update(viewChanges) {
    return new View({ ...this.view, ...viewChanges });
  }

  toURL(projectId, workspaceId, currentSearchParams) {
    const searchParams = { ...currentSearchParams };
    for (const field of POSSIBLE_SEARCH_PARAMS) {
      // If the view doesn't have a value for `field`, clear it even if
      // it's present on the current search (by setting it to undefined).
      searchParams[field] = this.view[field];
    }

    const search = SearchUtils.stringify(searchParams);
    const pattern = RoutePatterns[this.view.feature.toUpperCase()];
    let url = buildRoutePath(pattern, { projectId, workspaceId });
    if (search) {
      url += `?${search}`;
    }
    return url;
  }
}
