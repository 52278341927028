import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SimpleModal from '../components/core/SimpleModal';
import { Colors } from '../styles';
import { Button, LinkButton } from '../components/core/Button';
import { Icon, IconTypes } from '../components/icons';
import BuildChangesSummary from './BuildChangesSummary';
import SendEmailSelector from '../upload_page/SendEmailSelector';
import { buildProject } from '../utils/ApiUtilsV5';
import { StoreContext } from '../StoreContext';
import { AuthContext } from '../settings/model';
import { Bold } from '../components/Bold';
import { loadProject } from '../actions';
import { useSearchParams } from '../search_params';
import { Concept } from '../classes/Concepts';
import { useConceptManagement } from '../data_hooks';
import SentimentSelector from '../upload_page/SentimentSelector';

export function RebuildModalWithContext({ isOpen, onCancel, onRebuildStart }) {
  const {
    profile: { username },
    serverStatus
  } = useContext(AuthContext);

  const { project, projectHasLoaded, collocationNumbers } = useContext(StoreContext);
  const { searchParams } = useSearchParams();
  const { search, filter } = searchParams;
  const { conceptManagement } = useConceptManagement(project);

  const onAfterRebuild = () => {
    loadProject(project.project_id, Concept.fromString(search), filter);
  };

  if (!projectHasLoaded) {
    return null;
  }

  return (
    <RebuildModal
      project={project}
      userEmail={username}
      serverStatus={serverStatus}
      cancelButton={<CancelButton onClick={onCancel} />}
      onRebuildStart={onRebuildStart}
      onAfterRebuild={onAfterRebuild}
      conceptManagement={conceptManagement}
      isOpen={isOpen}
      onHide={onCancel}
      collocationNumbers={collocationNumbers}
    />
  );
}

// This component is only separate from the above RebuildModalWithContext for
// use in Lui due to the lack of access to the project store and the difference
// in cancel button behavior
export function RebuildModal({
  project,
  userEmail,
  serverStatus,
  onRebuildStart,
  // This is overridable for storybook purposes (to avoid making API requests),
  // but otherwise should be left as the default value.
  onRebuild = (sendEmail, sentiment, dashboard_build, max_ngram_length) =>
    buildProject(project.project_id, sendEmail, sentiment, dashboard_build, max_ngram_length),
  cancelButton,
  onAfterRebuild,
  forcedRebuild = false,
  conceptManagement,
  isOpen = true,
  onHide,
  collocationNumbers
}) {
  const name = project.name;
  const [sendEmail, setSendEmail] = useState(userEmail ? true : false);
  const [rebuildStarted, setRebuildStarted] = useState(false);
  const [sentiment, setSentiment] = useState(
    project.last_build_info.sentiment?.skip_sentiment
  );
  const dashboard_build = false;

  const rebuild = () => {
    if (!rebuildStarted) {
      setRebuildStarted(true);
      onRebuildStart?.();
      onRebuild(sendEmail, sentiment, dashboard_build, project.next_build_collocation).then(onAfterRebuild);
    }
  };

  return (
    <SimpleModal isOpen={isOpen} onHide={onHide} hasCloseButton={false}>
      <Column>
        {forcedRebuild && <ErrorBanner projectName={name} />}
        <BuildChangesSummary
          project={project}
          serverStatus={serverStatus}
          collocationNumbers={collocationNumbers}
          conceptManagement={conceptManagement}
          css={css`
            border: 1px solid ${Colors.gray0};
          `}
        />
        <ConclusionBox
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sentiment={sentiment}
          setSentiment={setSentiment}
          email={userEmail}
          lastBuildInfo={project.last_build_info}
        />
        <ButtonWrapper>
          {cancelButton}
          <Button palette="green" onClick={rebuild}>
            <Icon type={IconTypes.CLOCK} size="18" />
            Rebuild now
          </Button>
        </ButtonWrapper>
      </Column>
    </SimpleModal>
  );
}

RebuildModal.propTypes = {
  project: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  serverStatus: PropTypes.object.isRequired,
  cancelButton: PropTypes.node,
  onRebuild: PropTypes.func,
  onAfterRebuild: PropTypes.func,
  forcedRebuild: PropTypes.bool,
  conceptManagement: PropTypes.object,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }
  > :last-child {
    margin-top: 1.25rem;
  }
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
  > *:not(:last-child) {
    margin-right: 0.625rem;
  }

  button {
    border: none;
  }
`;

const ErrorBanner = ({ projectName }) => {
  return (
    <div
      css={css`
        display: flex;
        background: ${Colors.red0};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding: 0.75rem 0.5rem;
          color: ${Colors.red5};
        `}
      >
        <Icon type={IconTypes.CIRCULAR_EXCLAMATION} />
      </div>
      <div
        css={css`
          padding: 0.5rem;
          color: ${Colors.red5};
        `}
      >
        <strong>{projectName}</strong> is using science that is no longer
        supported. You will need to rebuild to continue.
      </div>
    </div>
  );
};

export const getEstimate = ({ start_time, stop_time }) => {
  const lastBuildTime = (stop_time - start_time) / 60; // in min

  if (!lastBuildTime) {
    return '';
  } else if (lastBuildTime <= 8) {
    return 'Up to ten minutes';
  } else if (lastBuildTime <= 50) {
    return 'Up to one hour';
  } else if (lastBuildTime <= 150) {
    return 'Up to three hours';
  } else {
    return 'More than three hours';
  }
};

export const BuildTimeEstimateWithContext = props => {
  const { project } = useContext(StoreContext);

  return (
    <BuildTimeEstimate
      lastBuildInfo={project.last_build_info}
      boldEstimate
      {...props}
    />
  );
};

const BuildTimeEstimate = ({ lastBuildInfo, boldEstimate }) => {
  const buildTimeEstimate = lastBuildInfo.stop_time
    ? getEstimate(lastBuildInfo)
    : '';

  return (
    buildTimeEstimate && (
      <div>
        Estimated time:{' '}
        {boldEstimate ? <Bold> {buildTimeEstimate} </Bold> : buildTimeEstimate}
      </div>
    )
  );
};

const ConclusionBox = ({
  sendEmail,
  setSendEmail,
  email,
  lastBuildInfo,
  sentiment,
  setSentiment
}) => {
  return (
    <div
      css={css`
        box-sizing: border-box;
        width: 100%;
        padding: 0.75rem;
        border: 1px solid ${Colors.gray0};
      `}
    >
      {email && (
        <SendEmailSelector
          checked={sendEmail}
          onChange={() => setSendEmail(!sendEmail)}
          email={email}
          additionalCss={css`
            margin-bottom: 0.5rem;
          `}
        />
      )}
      <SentimentSelector
        rebuild={true}
        checked={sentiment}
        onChange={() => setSentiment(!sentiment)}
        additionalCss={css`
          margin-bottom: 0.5rem;
        `}
      />
      <strong>
        This project will be unavailable until it has finished building
      </strong>
      <BuildTimeEstimate lastBuildInfo={lastBuildInfo} />
    </div>
  );
};

export const CancelLink = ({ linkTo }) => {
  return (
    <LinkButton palette="gray" linkTo={linkTo}>
      Cancel
    </LinkButton>
  );
};

export const CancelButton = ({ onClick }) => {
  return (
    <Button
      palette="gray"
      onClick={onClick}
      css={css`
        /* Needed to override default button styles in Lui */
        background: unset;
      `}
    >
      Cancel
    </Button>
  );
};
