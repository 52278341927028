import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function ExactCircle({ iconColor, lineWidth, checkedState, ...props }) {
  const checked = checkedState === 'checked';

  return (
    <SVGWrapper {...props}>
      {checked && (
        <circle
          cx="7.99998"
          cy="8.00065"
          r="6.66667"
          stroke={iconColor}
          strokeWidth={lineWidth}
        />
      )}
      <circle
        cx="8"
        cy="8"
        r="4"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
}
