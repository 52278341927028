export class Breakdown {
  static getTotalBucketCount(breakdowns) {
    return breakdowns.reduce((count, breakdown) => {
      return count + breakdown.getBucketCount();
    }, 0);
  }

  constructor(field) {
    this.field = field;
  }

  getBucketCount() {
    return 0;
  }
}

export class CategoricalBreakdown extends Breakdown {
  getBucketCount() {
    if (this.field.values === undefined) {
      return Infinity;
    }
    return this.field.values.length;
  }

  toAPIObject() {
    return {
      name: this.field.name
    };
  }
}

export class DateBreakdown extends Breakdown {
  constructor(field, interval) {
    super(field);
    this.interval = interval;
  }

  getBucketCount() {
    return (
      this.field.maximum.diff(
        this.field.minimum.clone().startOf(this.interval),
        this.interval
      ) + 1
    );
  }

  toAPIObject() {
    return {
      name: this.field.name,
      interval: this.interval
    };
  }
}

DateBreakdown.INTERVALS = ['hour', 'day', 'week', 'month', 'quarter', 'year'];

export class NumericBreakdown extends Breakdown {
  static getIntervals(field) {
    const { minimum, maximum, values } = field;

    const integersOnly =
      values.length !== 0 &&
      values.every(({ value }) => Number.isInteger(value));
    let preferredNum = maximum - minimum;
    const intervals = [...Array(5)]
      .map(() => {
        preferredNum = getPrevPreferredNumber(preferredNum);
        return preferredNum;
      })
      .filter(n => !integersOnly || Number.isInteger(n))
      .sort((a, b) => a - b);

    return {
      default:
        intervals
          .slice()
          .reverse()
          .find(
            interval =>
              new NumericBreakdown(field, interval).getBucketCount() >= 4
          ) || intervals[intervals.length - 1],
      intervals
    };
  }

  constructor(field, interval) {
    super(field);
    this.interval = interval;
  }

  getBucketCount() {
    const { field, interval } = this;
    const { minimum, maximum } = field;
    // This is based on how the API calculates buckets
    const getBucketNum = n => Math.floor((n + Math.abs(n) * 1e-12) / interval);
    const [bucketNumMin, bucketNumMax] = [minimum, maximum].map(getBucketNum);
    return bucketNumMax - bucketNumMin + 1;
  }

  toAPIObject() {
    return { name: this.field.name, interval: this.interval };
  }
}

const getPrevPreferredNumber = n => {
  const comparator = isPreferredNumber(n) ? (a, b) => a > b : (a, b) => a >= b;
  const [digit, exponent] = numToDigitAndExponent(n);
  const [nextDigit, nextExponent] = comparator(digit, 5)
    ? [5, exponent]
    : comparator(digit, 2)
    ? [2, exponent]
    : comparator(digit, 1)
    ? [1, exponent]
    : [5, exponent - 1];

  return digitAndExponentToNum(nextDigit, nextExponent);
};

// a preferred number is a number that has 1 significant digit: 1, 2 or 5
const isPreferredNumber = n => {
  const [digit, exponent] = numToDigitAndExponent(n);
  return (
    [1, 2, 5].includes(digit) && digitAndExponentToNum(digit, exponent) === n
  );
};

const numToDigitAndExponent = n => {
  const exponent = Math.floor(Math.log10(n));
  return [Math.floor(n / 10 ** exponent), exponent];
};

const digitAndExponentToNum = (digit, exponent) => digit * 10 ** exponent;

export class ScoreBreakdown extends Breakdown {
  static getIntervals(field) {
    const { minimum, maximum, values } = field;

    const integersOnly =
      values.length !== 0 &&
      values.every(({ value }) => Number.isInteger(value));
    let preferredNum = maximum - minimum;
    const intervals = [...Array(5)]
      .map(() => {
        preferredNum = getPrevPreferredNumber(preferredNum);
        return preferredNum;
      })
      .filter(n => !integersOnly || Number.isInteger(n))
      .sort((a, b) => a - b);

    return {
      default:
        intervals
          .slice()
          .reverse()
          .find(
            interval =>
              new ScoreBreakdown(field, interval).getBucketCount() >= 4
          ) || intervals[intervals.length - 1],
      intervals
    };
  }

  constructor(field, interval) {
    super(field);
    this.interval = interval;
  }

  getBucketCount() {
    const { field, interval } = this;
    const { minimum, maximum } = field;
    // This is based on how the API calculates buckets
    const getBucketNum = n => Math.floor((n + Math.abs(n) * 1e-12) / interval);
    const [bucketNumMin, bucketNumMax] = [minimum, maximum].map(getBucketNum);
    return bucketNumMax - bucketNumMin + 1;
  }

  toAPIObject() {
    return { name: this.field.name, interval: this.interval };
  }
}
