import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const List = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path1, path2;
  if (widthName === 'light') {
    path1 =
      'M5.53064 2.11176H14.9754M5.53064 6.09756H14.9754M5.53064 9.94763H14.9754M5.53064 13.8882H14.9754';
    path2 =
      'M1.56256 2.11111H1.61259M1.56256 6.04687H1.61259M1.56256 9.97177H1.61259M1.56256 13.8882H1.61259';
  } else {
    path1 =
      'M5.26166 2.11212H14.7064M5.26166 6.09793H14.7064M5.26166 9.94799H14.7064M5.26166 13.8886H14.7064';
    path2 =
      'M1.29358 2.11145H1.34361M1.29358 6.0472H1.34361M1.29358 9.97211H1.34361M1.29358 13.8886H1.34361';
  }
  return (
    <SVGWrapper {...props}>
      <path
        d={path1}
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <path
        d={path2}
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SVGWrapper>
  );
};
