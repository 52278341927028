import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const CircularInfo = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper {...props} alt="info">
      <circle
        cx="8.00002"
        cy="7.99959"
        r="6.62654"
        stroke={iconColor}
        strokeWidth={lineWidth}
      />
      <path
        d="M7.09671 7.20264H8.17001L8.17001 11.1278M8.17001 11.1278L6.93213 11.1278M8.17001 11.1278H9.32961"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.67831 4.84605C8.67831 5.40052 8.22883 5.85 7.67436 5.85C7.11989 5.85 6.67041 5.40052 6.67041 4.84605C6.67041 4.29159 7.11989 3.8421 7.67436 3.8421C8.22883 3.8421 8.67831 4.29159 8.67831 4.84605Z"
        fill={iconColor}
      />
    </SVGWrapper>
  );
};
