import { useEffect, useRef } from 'react';

/**
 * @template T
 * @type {T}
 *
 * @param {T} value
 * @return {T} The last value it was called with
 */
export function usePrevious(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * A variant of usePrevious, which stores the last non-null, non-undefined value
 * it was called with.
 *
 * This can be useful, for instance, to always maintain a reasonable value of
 * some variable in between fetches from an api (when a variable may be
 * undefined in the interim period while the fetch is taking place).
 *
 * @template T
 * @type {T}
 *
 * @param {T} value
 * @return {T} The last non-null, non-undefined value it was called with
 */
export function usePreviousDefined(value) {
  const ref = useRef(value);
  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  });
  return ref.current;
}
