import { useEffect, useRef } from 'react';

/**
 * Compute a value which is guaranteed to be stable across renders.
 * Unlike the official React.useMemo, this offers the same semantic
 * guarantee as useCallback.
 *
 * This hook is not intended to be used as a performance optimization.
 * It's main use is to produce values which an API hook depends on in
 * order to prevent that hook from making unnecessary requests.
 *
 * @param {function} callback A function to produce the value.
 * @param {any[]} dependencies An array of values which the value
 * depends on. Any changes to elements of the dependency array will
 * cause the memoized value to be recomputed.
 * @see {React.useMemo}
 */
export function useStableMemo(callback, dependencies) {
  const cache = useRef({ result: undefined, dependencies: undefined });
  const needsUpdate =
    !Array.isArray(cache.current.dependencies) ||
    !Array.isArray(dependencies) ||
    shallowCompareArrays(cache.current.dependencies, dependencies);

  const next = needsUpdate
    ? { result: callback(), dependencies }
    : cache.current;

  useEffect(() => {
    cache.current = next;
  }, [next]);

  return next.result;
}

/**
 * Shallow comparison of two arrays.
 *
 * @param {any[]} [a]
 * @param {any[]} [b]
 * @return {boolean} true if arrays are shallowly equal, false otherwise
 */
function shallowCompareArrays(a, b) {
  if (a === b) {
    return false;
  }

  if (a.length !== b.length) {
    return true;
  }

  for (const index in b) {
    // The official React Hooks use Object.is to compare elements in
    // dependency arrays.
    if (!Object.is(a[index], b[index])) {
      return true;
    }
  }

  return false;
}
