import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function CoordinateIcon({ iconColor, plane, lineWidth, ...props }) {
  const alt = plane === 'center' ? undefined : `${plane}-axis`;
  return (
    <SVGWrapper {...props} alt={alt}>
      <Coordinate plane={plane} iconColor={iconColor} lineWidth={lineWidth} />
    </SVGWrapper>
  );
}

const Coordinate = ({ plane, iconColor, lineWidth }) => {
  switch (plane) {
    case 'x':
      return (
        <>
          <path
            d="M2.03082 9.33301L14.0292 9.33301"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <ellipse cx="7.99994" cy="9.33301" rx="2" ry="2" fill={iconColor} />
          <path
            d="M5.04341 1.60883L2.21204 5.27293M2.21204 1.60883L5.04341 5.27293"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
        </>
      );
    case 'y':
      return (
        <>
          <path
            d="M10.3334 1.99887L10.3334 14.0018"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <ellipse
            cx="10.3334"
            cy="7.96957"
            rx="2"
            ry="2"
            transform="rotate(90 10.3334 7.96957)"
            fill={iconColor}
          />
          <path
            d="M6.33335 1.99789L5.33335 4.66456M5.33335 4.66456C5.11113 5.22012 4.46669 6.26456 3.66669 5.99789M5.33335 4.66456L4.00002 1.99789"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
        </>
      );
    default:
      return (
        <>
          <path
            d="M8 1.5L8 4"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <path
            d="M8 12L8 14.5"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <path
            d="M1.5 8L4 8"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <path
            d="M12 8L14.5 8"
            stroke={iconColor}
            strokeWidth={lineWidth}
            strokeLinecap="round"
          />
          <circle
            cx="8"
            cy="8"
            r="2"
            transform="rotate(90 8 8)"
            fill={iconColor}
          />
        </>
      );
  }
};
