import SectionBase from '../../classes/sectionbase';
import ko from 'knockout';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';


export default class TokenRedirectLoginSection extends SectionBase {
  sectionRequiresAuth = false;

  constructor() {
    super();
    ko.applyBindings(this);
    ReactDOM.render(<RedirectLogin />, document.getElementById('redirect_login'));
  }
}

const getTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
};

const getRedirectUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = urlParams.get('url');

  if (!baseUrl) return null; // Return null if base URL is not present

  // Remove the base URL and the token from the search parameters
  urlParams.delete('url');
  urlParams.delete('token');  // Specifically remove the token parameter

  // Create the full URL with additional parameters (excluding the token)
  const fullUrl = new URL(baseUrl, window.location.origin);
  urlParams.forEach((value, key) => {
    fullUrl.searchParams.set(key, value);
  });

  return fullUrl.toString();
};

const RedirectLogin = () => {
  useEffect(() => {
    const token = getTokenFromUrl();
    const url = getRedirectUrl();
    if (token) {
      localStorage.setItem('auth_token', `"${token}"`);
      window.location.replace(url);
    } else {
      console.error('No token found in URL');
      window.location.replace('/error-page');
    }
  }, []);

  return null;
};
