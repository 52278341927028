import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Launcher = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M6.41928 3.05315H3.23381C2.64671 3.05315 2.17078 3.52909 2.17078 4.11619V12.6356C2.17078 13.2227 2.64671 13.6986 3.23381 13.6986H11.7532C12.3403 13.6986 12.8162 13.2227 12.8162 12.6356V9.44323M9.89224 2.30145H12.4118C13.4794 2.30145 13.8292 2.68845 13.8292 3.71883V6.30163M5.01083 11.3851L13.5365 2.8594"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
