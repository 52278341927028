import moment from 'moment';

import { MetadataTypes } from '../constants';

export class MetadataField {
  constructor(name, type) {
    this.name = name;
    this.type = type;
  }

  static fromJSON(field) {
    const { name, type, values, minimum, maximum } = field;
    switch (type) {
      case MetadataTypes.CATEGORY:
        return new CategoricalField(name, values);
      case MetadataTypes.DATE:
        return new DateField(name, minimum, maximum);
      case MetadataTypes.NUMBER:
        return new NumericField(name, minimum, maximum, values);
      case MetadataTypes.SCORE:
        return new ScoreField(name, minimum, maximum, values);
    }
  }
}

export class Category {
  constructor(value, count = 1) {
    this.value = value;
    this.count = count;
  }
}

export class CategoricalField extends MetadataField {
  constructor(name, values) {
    super(name, MetadataTypes.CATEGORY);

    if (values !== undefined) {
      this.values = values.map(category => {
        return category instanceof Category
          ? category
          : new Category(category.value, category.count);
      });
    }
  }
}

export class DateField extends MetadataField {
  constructor(name, minimum, maximum) {
    super(name, MetadataTypes.DATE);

    if (minimum !== undefined) {
      this.minimum = minimum instanceof moment ? minimum : moment.utc(minimum);
    }

    if (maximum !== undefined) {
      this.maximum = maximum instanceof moment ? maximum : moment.utc(maximum);
    }
  }
}

export class NumericField extends MetadataField {
  constructor(name, minimum, maximum, values = []) {
    super(name, MetadataTypes.NUMBER);
    this.minimum = minimum;
    this.maximum = maximum;
    this.values = values.map(category => {
      return category instanceof Category
        ? category
        : new Category(category.value, category.count);
    });
  }
}

export class ScoreField extends MetadataField {
  constructor(name, minimum, maximum, values = []) {
    super(name, MetadataTypes.SCORE);
    this.minimum = minimum;
    this.maximum = maximum;
    this.values = values.map(category => {
      return category instanceof Category
        ? category
        : new Category(category.value, category.count);
    });
  }
}
