import moment from 'moment';

import {
  DateField,
  NumericField,
  ScoreField,
  CategoricalField
} from '../classes/MetadataFields';
import { NumericRangeConstraint } from '../classes/Constraints';

export const findFilterMatchingBreakdown = (breakdown, filters = []) =>
  breakdown && filters.find(({ name }) => name === breakdown.name);

const filterIndices = (values, filterCondition, array) => {
  const indices = new Set();
  values.forEach((value, i) => {
    if (filterCondition(value)) {
      indices.add(i);
    }
  });
  return array.filter((_, i) => indices.has(i));
};

const numericValues = (field, numBuckets, interval) =>
  [...Array(numBuckets)].map((_, i) => field.minimum + interval * i);

const constrainCategorical = (field, constraint, array) => {
  const hasValue = ({ value }) => constraint.values.includes(value);
  return filterIndices(field.values, hasValue, array);
};

const constrainDiscreteNumeric = (field, constraint, array, interval) => {
  const values = numericValues(field, array.length, interval);
  const max = Math.max(...constraint.values);
  const min = Math.min(...constraint.values);
  const inRange = value => min < value + interval && value <= max;
  return filterIndices(values, inRange, array);
};

const constrainContinuousNumeric = (field, constraint, array, interval) => {
  const values = numericValues(field, array.length, interval);
  const inRange = value =>
    (constraint.minimum === undefined || constraint.minimum <= value) &&
    (constraint.maximum === undefined || value <= constraint.maximum);
  return filterIndices(values, inRange, array);
};

const constrainDateRange = (field, constraint, array, interval) => {
  const constraintMinimumInterval = constraint.minimum
    ?.clone()
    .startOf(interval);
  const constraintMaximumInterval = constraint.maximum?.clone().endOf(interval);

  const values = [...Array(array.length)].map((_, i) =>
    field.minimum.clone().startOf(interval).add(i, interval)
  );

  const inRange = bucket =>
    (!constraintMinimumInterval ||
      constraintMinimumInterval.isSameOrBefore(bucket, interval)) &&
    (!constraintMaximumInterval ||
      constraintMaximumInterval.isSameOrAfter(bucket, interval));

  return filterIndices(values, inRange, array);
};

export const filterBreakdown = (field, constraint, array, interval) => {
  switch (field.constructor) {
    case DateField:
      return constrainDateRange(field, constraint, array, interval);
    case NumericField:
    case ScoreField:
      if (constraint instanceof NumericRangeConstraint) {
        return constrainContinuousNumeric(field, constraint, array, interval);
      }
      return constrainDiscreteNumeric(field, constraint, array, interval);
    case CategoricalField:
      return constrainCategorical(field, constraint, array);
  }
};

export const filterBreakdownField = (field, filters) => {
  const constraint = findFilterMatchingBreakdown(field, filters);
  if (!constraint) {
    return field;
  }

  switch (field.constructor) {
    case DateField:
      return new DateField(
        field.name,
        constraint.minimum
          ? moment.max(field.minimum, constraint.minimum)
          : field.minimum,
        constraint.maximum
          ? moment.min(field.maximum, constraint.maximum)
          : field.maximum
      );
    case NumericField:
    case ScoreField:
      return new field.constructor(
        field.name,
        constraint.minimum || field.minimum,
        constraint.maximum || field.maximum,
        constraint.values || field.values
      );
    case CategoricalField:
      return new CategoricalField(field.name, constraint.values);
  }
};
