import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export default class Fade extends React.Component {
  render() {
    const { show, ...passedProps } = this.props;
    return (
      <CSSTransition
        {...passedProps}
        classNames="fade"
        in={show}
        timeout={200}
        appear
      />
    );
  }
}

Fade.propTypes = {
  // Show the component; triggers the fade in or fade out animation
  show: PropTypes.bool
};

Fade.defaultProps = {
  show: false
};
