import { css } from '@emotion/react';
import React from 'react';

export default function VisuallyHidden(props) {
  return (
    <span
      css={css`
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        clip: rect(0 0 0 0);
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
      `}
      {...props}
    />
  );
}
