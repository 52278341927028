import { useEffect, useRef } from 'react';

/** Hook that returns a ref to a boolean indicating if the component is mounted
 *
 * @returns {React.RefObject} ref whose current value indicates if the
 *                            component is mounted
 */
export function useMountedRef() {
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
