import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { Button } from './core/Button';
import { Mixins } from '../styles';
import { Icon, IconTypes } from './icons';
import { useBoundingClientRect } from '../utils/hooks';
import { CutoutOverlay } from './CutoutOverlay';

const wrapperStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    /* prevent buttons from sliding down if you expand the textarea */
    align-self: baseline;
  }
`;

/**
 * InlineEditor component.
 *
 * @property {string} name - Used as the aria label for the form and in the
 *                           placeholder text in the input
 * @property {string} value - Initial value of the input
 * @property {function} onChange - Callback to be used when something changes.
 * @property {JSX.Element} [children] - Rendered inline, to the right of the pen
 *                                      icon when the editor is not in the
 *                                      active editing mode
 * @property {string} [blankValue] - Default value of the input, used when the
 *                                   value is blank. Should be supplied if
 *                                   `required` is false
 * @property {string} [placeholder] - Placeholder, passed to the HTML input
 * @property {string} [className] - Passed to the underlying HTML form rendered
 *                                  in editing mode
 * @property {boolean} [required] - Defaults to false. If true, blank values are
 *                                  not accepted. If false, `blankValue` should
 *                                  be supplied.
 * @property {boolean} [fillWidth] - Defaults to false. If true, editor takes
 *                                   the full width of its parent.
 * @property {boolean} [multiline] - Defaults to false. If true, a custom text
 *                                   area is rendering in editing mode instead
 *                                   of an input.
 * @property {string} [action] - Defaults to 'Edit'. Sets the verb in the ARIA label
 *                               of the pen icon.
 * @property {boolean} [ellipsify] - Defaults to True. Determines whether to
 *                                   shorten long values.
 * @property {boolean} [storybookEditing] - Defaults to false. Only for use in
 *                                          storybook. Displays the editor in
 *                                          editing mode.
 */
export default function InlineEditor({
  name,
  value,
  onChange,
  children,
  blankValue,
  placeholder,
  className,
  required = false,
  fillWidth = false,
  multiline = false,
  action = 'Edit',
  ellipsify = true,
  NameWrapper = props => <span {...props} />,
  storybookEditing = false // for use in storybook only
}) {
  const [editing, setEditing] = useState(false);
  const nameStyles = css([
    ellipsify && Mixins.ellipsify,
    css`
      min-width: 0;
      padding: 0 0.5rem;
    `,
    fillWidth &&
      css`
        flex: 1;
      `,
    multiline &&
      css`
        white-space: pre-wrap; /* soft wrap long lines */
        /* set padding and box-sizing to prevent a really annoying problem
         * where the project details section of the * upload page would get
         * 8px taller when you went to edit the description
         */
        padding: 0.5rem; /* don't smush top of text against border */
        box-sizing: border-box; /* account for padding in min-height */
        /* Don't let the text area take over the whole screen */
        max-height: 40vh;
        overflow-y: auto;
      `
  ]);
  return (
    <>
      {editing || storybookEditing ? (
        <InlineEditorForm
          name={name}
          value={value}
          onChange={onChange}
          stopEditing={() => setEditing(false)}
          required={required}
          nameStyles={nameStyles}
          className={className}
          multiline={multiline}
          placeholder={placeholder}
          {...storybookOverrides(storybookEditing, setEditing)}
        />
      ) : (
        <span css={wrapperStyles} className={className}>
          <NameWrapper css={nameStyles}>
            {value || <em>{blankValue}</em>}
          </NameWrapper>
          <Button
            hiddenLabel={`${action} ${name}`}
            onClick={() => setEditing(true)}
          >
            <Icon type={IconTypes.PEN} size="1rem" />
          </Button>
          {children}
        </span>
      )}
    </>
  );
}

InlineEditor.propTypes = {
  /** Used as the aria label for the form and in the placeholder text in the input. */
  name: PropTypes.string.isRequired,
  /** Initial value of the input. */
  value: PropTypes.string.isRequired,
  /** Callback to be used when something changes. */
  onChange: PropTypes.func.isRequired,
  /** Rendered inline, to the right of the pen icon when the editor is not in the active editing mode.*/
  children: PropTypes.node,
  /** Default value of the input, used when the value is blank. Should be supplied if `required` is false.*/
  blankValue: PropTypes.string,
  /** Placeholder, passed to the HTML input.*/
  placeholder: PropTypes.string,
  /** Passed to the underlying HTML form rendered in editing mode.*/
  className: PropTypes.string,
  /** Defaults to false. If true, blank values are not accepted. If false, `blankValue` should be supplied.*/
  required: PropTypes.bool,
  /** Defaults to false. If true, editor takes the full width of its parent.*/
  fillWidth: PropTypes.bool,
  /** Defaults to false. If true, a custom text area is rendering in editing mode instead of an input.*/
  multiline: PropTypes.bool,
  /** Defaults to 'Edit'. Sets the verb in the ARIA label of the pen icon.*/
  action: PropTypes.string,
  /** Defaults to True. Determines whether to shorten long values.*/
  ellipsify: PropTypes.bool,
  /** Defaults to false. Only for use in storybook. Displays the editor in editing mode. */
  storybookEditing: PropTypes.bool,
  /** Element that wraps the name. Defaults to a span. */
  NameWrapper: PropTypes.elementType
};

const storybookOverrides = (storybookEditing, setEditing) =>
  storybookEditing && {
    stopEditing: () => setEditing(true)
  };

/**
 * The form rendered by the inline editor when in editing mode.
 */
function InlineEditorForm({
  name,
  value,
  onChange,
  stopEditing,
  required,
  nameStyles,
  className,
  multiline,
  placeholder
}) {
  const inputRef = useRef();
  const formRef = useRef();
  const [currentValue, setCurrentValue] = useState(value);
  const trimmedCurrentValue = currentValue.trim();
  useLayoutEffect(() => {
    inputRef.current.select();
  }, []);
  const FormControl = multiline ? AutoGrowingTextArea : 'input';
  const submitForm = event => {
    event.preventDefault();
    if (trimmedCurrentValue !== '' || !required) {
      onChange(trimmedCurrentValue);
    }
    stopEditing();
  };
  return (
    <>
      <form
        ref={formRef}
        css={wrapperStyles}
        className={className}
        onBlur={event => {
          if (!formRef.current.contains(event.relatedTarget)) {
            submitForm(event);
          }
        }}
        onSubmit={submitForm}
      >
        <FormControl
          ref={inputRef}
          aria-label={_.upperFirst(name)}
          value={currentValue}
          placeholder={placeholder ?? `Type a new ${name}`}
          onChange={event => setCurrentValue(event.target.value)}
          autoFocus
          css={css([
            nameStyles,
            css`
              margin: 0;
              /* Adjust input height to match button height */
              min-height: 2.2rem;
            `
          ])}
        />
        <Button palette="red" onClick={stopEditing} aria-label="Cancel">
          <Icon type={IconTypes.CLOSE} size="1rem" />
        </Button>
      </form>
      <ClickBlocker formRef={formRef} onClose={stopEditing} />
    </>
  );
}

const AutoGrowingTextArea = forwardRef(({ value, ...props }, ref) => {
  const [height, setHeight] = useState(1);
  useLayoutEffect(() => {
    setHeight(ref.current.scrollHeight);
  }, [value]);
  return (
    <textarea
      css={css`
        height: ${height}px;
      `}
      ref={ref}
      value={value}
      {...props}
    />
  );
});

// This is a clear div that covers the whole page *except* for the InlineEditor.
// Since we submit on a blur effect, we want to avoid a situation in which the
// cause of the blur also triggers a different event that interacts weirdly with
// the submit (e.g. clicking on a delete button while editing a name).
const ClickBlocker = ({ formRef, onClose }) => {
  const rect = useBoundingClientRect(formRef);
  return createPortal(
    <CutoutOverlay rect={rect} onClick={onClose} />,
    document.body
  );
};
