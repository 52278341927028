import { useHistory, useLocation } from 'react-router-dom';

/**
 * @returns {string} - The current URL, including protocol, host, and
 *                     application's basename
 */
export function useCurrentUrl() {
  const location = useLocation();
  const history = useHistory();
  return window.location.origin + history.createHref(location);
}
