import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Minus = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper alt="minus" {...props}>
    <path
      d="M3.01611 8L12.9839 8"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
