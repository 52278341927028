// This function takes a route definition and an optional object of URL parameters and returns a
// route path. URL parameters are prefaced with a ":". If a URL parameter is present in the route
// definition, but no property with that name exists on the query object, the function will throw.

export default function (routeDef, params = {}) {
  return routeDef.split('/').reduce((path, segment) => {
    if (['', '?'].includes(segment)) {
      // This segment is the result of routes ending with '/' or '/?' and can be ignored
      return path;
    }

    // is the segment a route parameter?
    if (segment[0] === ':') {
      const optional = segment.endsWith('?');
      const name = segment.replace(/(^:)|(\?$)/g, '');
      const param = params[name];

      if (param == null) {
        if (optional) {
          return path;
        }
        throw new Error(`Route "${routeDef}" requires a "${name}" parameter`);
      }

      return path + '/' + param;
    }

    return path + '/' + segment;
  });
}
