import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Clock = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper {...props}>
      <path
        d="M7.04396 5.27747L7.04396 8.10933M7.07524 8.13809L10.0224 9.71494M14.4248 7.99999C14.4248 11.5483 11.5484 14.4248 8.00002 14.4248C4.45169 14.4248 1.5752 11.5483 1.5752 7.99999C1.5752 4.45166 4.45169 1.57516 8.00002 1.57516C11.5484 1.57516 14.4248 4.45166 14.4248 7.99999Z"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
    </SVGWrapper>
  );
};
