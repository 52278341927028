import { css, keyframes } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

const spin = keyframes`
  0% {
    transform: rotate(-30deg);
  }
  30% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(150deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(330deg);
  }
`;

const SPINNER_SIZES = {
  small: '1rem',
  medium: '2.25rem',
  large: '4rem'
};

const Spinner = ({ color, size = 'small' }) => {
  const spinnerSize = SPINNER_SIZES[size];
  return (
    <svg
      className="spinner"
      css={css`
        width: ${spinnerSize};
        height: ${spinnerSize};
        color: ${color};
        fill: none;
        vertical-align: text-bottom;
        overflow: visible !important;
      `}
      height="1px" // Prevent giant SVG showing up if CSS hasn't loaded yet
      viewBox="0 0 24 24"
    >
      <g
        css={css`
          transform-origin: center;
          animation: ${spin} 1.75s infinite ease-in-out;
        `}
      >
        <path
          d="M6.50572 7.86671L6.50573 3.70209C6.50573 3.65157 6.51135 3.60115 6.51323 3.55067C6.52593 3.21143 6.25387 2.92004 5.90444 2.92004L1.55905 2.92002M17.5 16.1918L17.5 20.3533C17.5 20.4038 17.4944 20.4542 17.4925 20.5046C17.4798 20.8436 17.7516 21.1348 18.1008 21.1348L22.443 21.1348M12 22.443C6.23252 22.443 1.55704 17.7675 1.55704 12C1.55704 8.5489 3.2311 5.48877 5.81148 3.5874M12 1.55704C17.7675 1.55704 22.443 6.23252 22.443 12C22.443 15.5391 20.6825 18.667 17.9895 20.5558"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string.isRequired
};

Spinner.defaultProps = {
  large: false,
  color: 'currentColor'
};

export default Spinner;
