import { useCallback } from 'react';

import { hideFilterInfoSpinner } from '../actions';
import { encodeFilter, useSearchParams } from './index';

export const useFilter = () => {
  const { searchParams } = useSearchParams();
  return searchParams.filter;
};

export const useSetFilter = () => {
  const { searchParams, updateSearch } = useSearchParams();
  return useCallback(
    newFilter => {
      if (encodeFilter(searchParams.filter) !== encodeFilter(newFilter)) {
        updateSearch({ filter: newFilter });
      } else {
        hideFilterInfoSpinner();
      }
    },
    [searchParams, updateSearch]
  );
};
