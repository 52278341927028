import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Check = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M1.33331 7.6048L5.92215 12.6667L14.6666 3.33333"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGWrapper>
);
