# This function exposes the provided abort() function on the provided promise and chains off of it.
# It was taken from mjackson's MIT-licensed mach library's AbortablePromise module.
# https://github.com/mjackson/mach/blob/4738db001aa602aaaa/modules/utils/AbortablePromise.js#L3-L13
#
# The primary use case for this function in analytics_ui is to enable aborting XHRs wrapped in
# Promise chains, (e.g. from within a React component's `componentWillUnmount()`).
#
# @example
# # Assume that we already have a variable `xhr` containing an unsent XHR
# abortXHR = xhr.abort.bind(xhr)
#
# # Wrap the XHR in a promise and send it
# promiseWrappedXHR = new Promise(resolve, reject) ->
#  xhr.on('load', -> resolve(xhr.response))
#  xhr.on('error', -> reject(xhr.statusText))
#  xhr.send()
#
# # Use `makeAbortable()` to expose `abortXHR()` as `abort()` on `promiseWrappedXHR`
# # and Promise chains built off of it.
# makeAbortable(promiseWrappedXHR, abortXHR)
#
# # Wrap the resulting abortable, Promise-wrapped XHR in "action creator"-like logic
# promiseChainWrappedXHR = promiseWrappedXHR
#   .then (result) -> dispatch('ACTION_TYPE', {status: 'FULFILLED', result})
#   .catch (error) -> dispatch('ACTION_TYPE', {status: 'REJECTED', error})
#
# # The XHR can be aborted as follows, if necessary, despite being wrapped in a Promise chain:
# promiseChainWrappedXHR.abort()

makeAbortable = (promise, abort) ->
  promise.abort = abort

  # Hijack promise.then so it returns an abortable promise
  _then = promise.then
  promise.then = -> makeAbortable(_then.apply(promise, arguments), abort)

  promise

module.exports = makeAbortable
