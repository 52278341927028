import { RequestStatuses } from './constants';
import Dispatcher from './Dispatcher';

export const dispatchData = (type, data = {}) => {
  if (type == null) {
    console.warn('Dispatching action without a type. Data = ', data);
  }

  data.type = type;
  Dispatcher.dispatch(data);
};

export const dispatchDataAsPayload = (type, payload) => {
  dispatchData(type, payload != null ? { payload } : {});
};

const genStatusDispatch = status => (type, payload) => {
  dispatchData(type, { status, payload });
};

export const fulfillRequest = genStatusDispatch(RequestStatuses.FULFILLED);
export const rejectRequest = genStatusDispatch(RequestStatuses.REJECTED);
export const startRequest = genStatusDispatch(RequestStatuses.PENDING);
