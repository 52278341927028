import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const CircularClose = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper {...props}>
      <path
        d="M10.4591 5.51077L5.56626 10.4036M5.55331 5.49788L10.4461 10.3907M14.6263 7.93666C14.6263 11.5964 11.6595 14.5632 7.99971 14.5632C4.33997 14.5632 1.37317 11.5964 1.37317 7.93666C1.37317 4.27692 4.33997 1.31012 7.99971 1.31012C11.6595 1.31012 14.6263 4.27692 14.6263 7.93666Z"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
    </SVGWrapper>
  );
};
