import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Reviews = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper defaultSize="24" {...props}>
      <path
        fill={iconColor}
        d="M20,0H4A4,4,0,0,0,0,4V16a4,4,0,0,0,4,4H6.9l4.451,3.763a1,1,0,0,0,1.292,0L17.1,20H20a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0ZM7,5h5a1,1,0,0,1,0,2H7A1,1,0,0,1,7,5ZM17,15H7a1,1,0,0,1,0-2H17a1,1,0,0,1,0,2Zm0-4H7A1,1,0,0,1,7,9H17a1,1,0,0,1,0,2Z"
      />
    </SVGWrapper>
  );
};
