import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Briefcase = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M10 4.66667V2.66667C10 2.29848 9.70152 2 9.33333 2H6.66667C6.29848 2 6 2.29848 6 2.66667V4.66667M2 7.63346H6.63923M9.35666 7.63353H14M2.66667 13.3333H13.3333C13.7015 13.3333 14 13.0349 14 12.6667V5.33333C14 4.96514 13.7015 4.66667 13.3333 4.66667H2.66667C2.29848 4.66667 2 4.96514 2 5.33333V12.6667C2 13.0349 2.29848 13.3333 2.66667 13.3333ZM7.37649 9.33333H8.62352C8.99171 9.33333 9.29019 9.03486 9.29019 8.66667V7.33333C9.29019 6.96514 8.99171 6.66667 8.62352 6.66667H7.37649C7.0083 6.66667 6.70982 6.96514 6.70982 7.33333V8.66667C6.70982 9.03486 7.0083 9.33333 7.37649 9.33333Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
  </SVGWrapper>
);
