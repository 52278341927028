import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import SimpleModal from './core/SimpleModal';
import { Colors, standardRadius } from '../styles';
import { getScienceChangeLog } from '../utils/ApiUtilsV5';
import { Button } from './core/Button';

export default function ScienceChangeLog() {
  const [showLogModal, setShowLogModal] = useState(false);

  return (
    <>
      <ScienceChangeLogLink
        onClick={() => {
          setShowLogModal(true);
        }}
      />
      <ScienceChangeLogModal
        isOpen={showLogModal}
        onHide={() => {
          setShowLogModal(false);
        }}
      />
    </>
  );
}

export function ScienceChangeLogLink({ onClick }) {
  return (
    <span
      css={css`
        display: inline-flex;
      `}
    >
      (
      <Button flavor="subtle" type="submit" onClick={onClick} padded={false}>
        <u>Science change log</u>
      </Button>
      )
    </span>
  );
}

function ScienceChangeLogModal({ isOpen, onHide }) {
  const [logContents, setLogContents] = useState(
    'Loading science change log...'
  );

  useEffect(() => {
    getScienceChangeLog().then(scienceChangeLog => {
      setLogContents(formatLog(scienceChangeLog));
    });
  }, []);

  return (
    <SimpleModal
      isOpen={isOpen}
      onHide={onHide}
      header={<h4>Science build log</h4>}
    >
      {logContents}
    </SimpleModal>
  );
}

function formatLog(logJSON) {
  let logEntries = Object.keys(logJSON);

  return (
    <div
      css={css`
        overflow-y: auto;
        max-height: 50vh;
        border: 1px solid ${Colors.gray1};
        border-radius: ${standardRadius};
        margin: 0.5rem;
        padding: 0.5rem;
      `}
    >
      {logEntries.map((entryKey, index) => {
        const logEntry = logJSON[entryKey];

        return (
          <div
            key={index}
            css={css`
              margin-bottom: 1rem;
              :not(:last-child) {
                border-bottom: 1px solid ${Colors.gray1};
              }
            `}
          >
            <h6> {logEntry.name} </h6>
            <p>
              Science: <b> {entryKey} </b>
            </p>
            <p>
              Date: <b> {logEntry.date} </b>
            </p>
            <p
              css={css`
                white-space: pre-line;
              `}
            >
              {formatURLs(logEntry.description)}
            </p>
          </div>
        );
      })}
    </div>
  );
}

// Replace urls in the text of the description with their corresponding tags.
// Currently limited to links to luminoso.com style urls only.
function formatURLs(text) {
  const urlRegexp = new RegExp(/(https?:\/\/www\.luminoso\.com\/\S*)/);

  // https://stackoverflow.com/questions/33235890/react-replace-links-in-a-text

  let parts = text.split(urlRegexp);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <a
        key={'link' + i}
        href={parts[i]}
        rel="noopener noreferrer"
        target="_blank"
      >
        {parts[i]}
      </a>
    );
  }

  return parts;
}
