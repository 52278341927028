import React from 'react';
import styled from '@emotion/styled';

import { Mixins, ZIndexes } from '../styles';

export function CutoutOverlay({ rect, onClick, ...props }) {
  const overlayProps = { rect, onClick, 'data-test-id': 'overlay', ...props };
  return (
    <>
      <LeftOverlay {...overlayProps} />
      <TopOverlay {...overlayProps} />
      <RightOverlay {...overlayProps} />
      <BottomOverlay {...overlayProps} />
    </>
  );
}

const Overlay = styled.div`
  ${Mixins.fillViewport};
  position: fixed;
  z-index: ${ZIndexes.modalBackground};
`;

const LeftOverlay = styled(Overlay)`
  width: ${props => props.rect.left}px;
`;

const TopOverlay = styled(Overlay)`
  left: ${props => props.rect.left}px;
  width: ${props => props.rect.width}px;
  height: ${props => props.rect.top}px;
`;

const RightOverlay = styled(Overlay)`
  left: ${props => props.rect.right}px;
`;

const BottomOverlay = styled(Overlay)`
  top: ${props => props.rect.top + props.rect.height}px;
  left: ${props => props.rect.left}px;
  width: ${props => props.rect.width}px;
`;
