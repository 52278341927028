import URI from 'urijs';

import Router from '../../utils/router';

export const miscErrMsg =
  'Something went wrong! Please contact an administrator for help.';

export const getSSOLoginURL = () => {
  const originalDest = Router.param('dest');
  let dest;
  if (originalDest) {
    dest = URI(originalDest).is('absolute') ? originalDest : '/' + originalDest;
  } else {
    dest = '/';
  }
  return `/api/saml/sso?dest=${encodeURIComponent(dest)}`;
};

export const ssoHasFailed = () => Router.param('ssoErr') === '1';

export const registerUserTypeInGTM = username => {
  const userType =
    username &&
    username.startsWith('selenium') &&
    username.endsWith('@luminoso.com')
      ? 'testing'
      : username &&
        (!username.includes('@') || username.endsWith('@luminoso.com'))
      ? 'internal'
      : 'external';
  window.dataLayer?.push({ luminosoUserType: userType });
};
