# converted from https://github.com/LuminosoInsight/pack64

###
pack64.pack: encodes a vector into a pack64'd string.
pack64.unpack: decodes a pack64'd string into a vector.

This library is for decoding a packed vector format, defined in the Python
package `pack64`.

The format uses URL-safe base64 to encode an exponent followed by several
18-bit signed integers.
###

SIGN_BIT = 131072
ROUND_MARGIN = SIGN_BIT / (SIGN_BIT - 0.5)

alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
alphabet_map = {}

i = 0

while i < 64
  alphabet_map[alphabet.charAt(i)] = i
  i++

module.exports = {
  pack: (vec) ->
    # Calculate the smallest power of 2 we *don't* need to represent.
    # The exponent we want will be 17 lower than that.
    max = 0

    for vector in vec
      v = Math.abs(vector) * ROUND_MARGIN

      if v > max
        max = v

    upperBound = Math.floor(1 + Math.log(max) / Math.log(2))
    exponent = upperBound - 17

    if exponent > 23
      # Overflow. Return the flag vector for "almost infinity"
      return '-'

    if exponent < -40
      # Underflow. Lose some precision. Or maybe all of it.
      exponent = -40

    power = Math.pow(2, exponent)
    res = [alphabet[exponent + 40]]

    for vector in vec
      num = Math.round(vector / power)

      if num < 0
        num += SIGN_BIT * 2

      # Do the signed arithmetic to represent an 18-bit integer.
      res.push(alphabet[(num % (1 << 18)) >> 12])
      res.push(alphabet[(num % (1 << 12)) >> 6])
      res.push(alphabet[num % (1 << 6)])

    return res.join('')

  unpack: (str) ->
    hexes = []

    for vector, i in str
      hexes[i] = alphabet_map[str.charAt(i)]

    K = (hexes.length - 1) / 3
    vector = new Float32Array(Math.ceil(K))
    unit = Math.pow(2, hexes[0] - 40)

    i = 0

    while i < K
      base = i * 3
      integer = 4096 * hexes[base + 1] + 64 * hexes[base + 2] + hexes[base + 3]

      if integer >= SIGN_BIT
        integer -= SIGN_BIT * 2

      vector[i] = integer * unit
      i++

    return vector
}
