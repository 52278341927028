import React from 'react';

/**
 * SVG container for icons.
 *
 * "size" and "defaultSize" are used to set the height and width values.
 *
 * "defaultSize" is used to determine the view box of an icon (and, as such,
 * should be unit-less).
 *
 * Additionally, if size is not specified, the icon will use the view box height
 * and width values for its sizing. If you need to render a different sized
 * icon, use the regular "size" prop.
 */
export const SVGWrapper = ({
  children,
  alt,
  defaultSize = '16',
  size = defaultSize,
  className,
  'aria-label': ariaLabel,
  isPrinted = true,
  ...props
}) => {
  return (
    <svg
      alt={alt}
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${defaultSize} ${defaultSize}`}
      fill="none"
      {...(!isPrinted ? { 'data-html2canvas-ignore': true } : {})}
      aria-label={ariaLabel}
      {...props}
    >
      {children}
    </svg>
  );
};
