import { useEffect, useState } from 'react';

/**
 * Some users will be harmed by animations with a lot of motion.  There is a
 * CSS media query that allows them to indicate that they don't want such
 * animation.  This hook allows us to respect that preference.
 *
 * @returns {boolean} - The result of a prefers-reduced-motion CSS media query
 *
 * This code is borrowed, with modifications, from
 * https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
 */
export function usePrefersReducedMotion() {
  const QUERY = '(prefers-reduced-motion: no-preference)';
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(() => {
    return !window.matchMedia(QUERY).matches;
  });
  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);
    const listener = event => {
      setPrefersReducedMotion(!event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);
  return prefersReducedMotion;
}
