import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function RadioIcon({ iconColor, checkedState, lineWidth, ...props }) {
  const checked = checkedState === 'checked';

  return (
    <SVGWrapper {...props}>
      <circle
        cx="8.00002"
        cy="7.99935"
        r="5.33333"
        stroke={iconColor}
        strokeWidth={lineWidth}
      />
      {checked && (
        <circle cx="8.00002" cy="7.99935" r="3.33333" fill={iconColor} />
      )}
    </SVGWrapper>
  );
}
