import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Equals = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M3.33331 6H13.2666M3.33331 9.33333H13.2666"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
