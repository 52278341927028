import { css } from '@emotion/react';
import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { useApiClient } from '../utils/apiClientHook';
import { naturalSortByName } from '../utils/NaturalSort';
import { setToken } from '../utils/apiToken';
import { objHasPerm } from '../utils/common';
import { percentify } from '../utils/NumFmtUtils';

export class Profile {
  constructor(api, profile) {
    this._api = api;
    this.username = profile.username;
    this.fullName = profile.full_name;
    this.globalPermissions = profile.global_permissions;
    this.workspaces = profile.workspaces
      .sort(naturalSortByName)
      .map(
        w =>
          new Workspace(
            api,
            this,
            w.workspace_id,
            w.organization_id,
            w.name,
            w.permissions
          )
      );
    this.workspacesById = {};
    for (const workspace of this.workspaces) {
      this.workspacesById[workspace.workspace_id] = workspace;
    }
    this.defaultWorkspace = profile.default_workspace;
    this.organizations = profile.organizations
      .sort(naturalSortByName)
      .map(
        w =>
          new Organization(api, this, w.organization_id, w.name, w.permissions)
      );
    this.organizationsById = {};
    for (const organization of this.organizations) {
      this.organizationsById[organization.organization_id] = organization;
    }
    this.organizationId = profile.organization_id;
  }

  modify({ fullName, defaultWorkspace, booleanSearch }) {
    const newProfile = {
      full_name: fullName
    };
    if (defaultWorkspace !== null) {
      newProfile.default_workspace = defaultWorkspace;
    }
    if (booleanSearch != null) {
      newProfile.boolean_search = booleanSearch;
    }
    return this._api.put('/profile/', newProfile).then(() => {
      this._api.clearCache('/profile/');
    });
  }

  changePassword(oldPass, newPass) {
    return this._api
      .put('/profile/password/', {
        old_password: oldPass,
        new_password: newPass
      })
      .then(() => this._refreshLoginSession(newPass));
  }

  _refreshLoginSession(password) {
    return this._api
      .post(
        '/login/',
        { username: this.username, password, acknowledged: true },
        {},
        'form'
      )
      .then(({ token }) => {
        setToken(token);
      });
  }

  organizationsForPermission(permission) {
    return this.organizations.filter(o => o.permissions.includes(permission));
  }

  workspacesForPermission(permission) {
    return this.workspaces.filter(w => w.permissions.includes(permission));
  }

  workspacesWhoseUsersYouCanSee() {
    return this.workspaces.filter(w => {
      return (
        w.organization_id === this.organizationId ||
        w.permissions.includes('account_manage')
      );
    });
  }

  /**
   * Out of a list of workspaces, return the ID of:
   *  - your default workspace, if it is in the list, or
   *  - the first workspace, or
   *  - null, if the list is empty
   *
   * @param workspaces
   * @returns {string|null}
   */
  pickDefault(workspaces) {
    const workspaceIds = workspaces.map(w => w.workspace_id);
    if (
      this.defaultWorkspace !== null &&
      workspaceIds.includes(this.defaultWorkspace)
    ) {
      return this.defaultWorkspace;
    }
    if (workspaceIds.length > 0) {
      return workspaceIds[0];
    }
    return null;
  }

  getTokens() {
    const { response } = this._api.get('/tokens/');
    return response;
  }

  createToken(formData) {
    return this._api.post('/tokens/', formData).then(token => {
      this._api.clearCache('/tokens/');
      return token;
    });
  }

  deleteTokens(tokenIds) {
    return this._api
      .post('/tokens/delete/', { token_ids: tokenIds })
      .then(() => this._api.clearCache('/tokens/'));
  }
}

export class Workspace {
  constructor(api, profile, workspaceId, organizationId, name, permissions) {
    this._api = api;
    this._profile = profile;
    this.workspace_id = workspaceId;
    this.organization_id = organizationId;
    this.name = name;
    this.permissions = permissions;
    this.editable = objHasPerm(this, 'account_manage');
    this.identifier = 'workspace';
  }

  modifyName(name) {
    return this._api
      .put(`/workspaces/${this.workspace_id}/`, { name })
      .then(() => {
        this._api.clearCache('/profile/');
      });
  }

  /**
   * Remove users from this workspace
   *
   * @param usernames - A list of usernames to remove
   */
  removeUsers(usernames) {
    return this._api
      .post(`/workspaces/${this.workspace_id}/users/remove/`, { usernames })
      .then(() => {
        this._api.clearCache(`/workspaces/${this.workspace_id}/`);
        return { removedSelf: false };
      });
  }

  /**
   * Edit the role of users from this workspace
   *
   * @param usernames - A list of usernames whose role to edit
   * @param role - The role to grant the given users on this workspace
   */
  editUsers(usernames, role) {
    return this._api
      .put(`/workspaces/${this.workspace_id}/users/`, { usernames, role })
      .then(() => {
        this._api.clearCache(`/workspaces/${this.workspace_id}/`);
      });
  }

  getUsers() {
    const { response } = this._api.get(`/workspaces/${this.workspace_id}/`);
    return response?.users;
  }

  getProjects() {
    const { response } = this._api.get(`/workspaces/${this.workspace_id}/`);
    return response?.projects;
  }

  getDefaultLanguage() {
    const { response } = this._api.get(`/workspaces/${this.workspace_id}/`);
    return response?.default_language;
  }

  /**
   * Modify the default language of this workspace
   *
   * @param language - The new default language
   */
  modifyDefaultLanguage(language) {
    return this._api
      .put(`/workspaces/${this.workspace_id}/`, { default_language: language })
      .then(() => {
        this._api.clearCache(`/workspaces/${this.workspace_id}/`);
      });
  }

  inviteUser(email, role) {
    return this._api.post(
      `/workspaces/${this.workspace_id}/users/`,
      {
        emails: [email],
        role
      },
      {},
      'form'
    );
  }

  deactivate() {
    return this._api.del(`/workspaces/${this.workspace_id}/`).then(() => {
      this._api.clearCache('/profile/');
    });
  }
}

export class UsageReport {
  constructor({ billing_periods: billingPeriods, months }) {
    this.billingPeriods =
      billingPeriods?.map(bp => ({
        ...bp,
        start: moment.utc(bp.start),
        end: moment.utc(bp.end)
      })) ?? [];
    this.months =
      months?.map(month => ({
        ...month,
        total_usage: month.docs_uploaded - month.docs_refunded,
        timestamp: moment.utc(month.start),
        workspaces: month.workspaces?.map(workspace => ({
          ...workspace,
          total_usage: workspace.docs_uploaded - workspace.docs_refunded
        }))
      })) ?? [];
  }

  getBillingPeriods() {
    const notInFuture = p => p.start.isSameOrBefore(moment());
    return this.billingPeriods
      .filter(notInFuture)
      .map(p => this._billingPeriodReport(p));
  }

  _billingPeriodReport(period) {
    const start = period.start.format('MMM D, YYYY');
    const end = period.end.format('MMM D, YYYY');
    const documents = period.docs_uploaded - period.docs_refunded;
    const limit = period.document_limit;
    const percentUsed =
      limit === 0
        ? 'n/a'
        : period.document_limit && percentify(documents, limit, 0);
    return {
      title: `${start} to ${end}`,
      uploaded: period.docs_uploaded,
      refunded: period.docs_refunded,
      totalUsage: documents,
      limit,
      percentUsed,
      months: this.months.filter(m => this._inPeriod(period, m)),
      includesRefund: period.docs_refunded !== 0
    };
  }

  _inPeriod(period, month) {
    const monthStart = month.timestamp.clone().startOf('month');
    const monthEnd = month.timestamp.clone().endOf('month');
    return period.start <= monthEnd && monthStart <= period.end;
  }
}

export class Organization {
  constructor(api, profile, organizationId, name, permissions) {
    this._api = api;
    this._profile = profile;
    this.organization_id = organizationId;
    this.name = name;
    this.permissions = permissions;
    this.editable = objHasPerm(this, 'organization_manage');
    this.identifier = 'organization';
  }

  modifyName(name) {
    return this._api
      .put(`/organizations/${this.organization_id}/`, { name })
      .then(() => {
        this._api.clearCache('/profile/');
      });
  }

  getUsers() {
    const { response } = this._api.get(
      `/organizations/${this.organization_id}/`
    );
    return response?.users;
  }

  /**
   * Delete the specified users.
   *
   * @param usernames
   */
  deleteUsers(usernames) {
    return this._api.post('/users/delete/', { usernames }).then(() => {
      this._api.clearCache(`/organizations/${this.organization_id}/`);
      for (let w of this._profile.workspaces) {
        this._api.clearCache(`/workspaces/${w.workspace_id}/`);
      }
    });
  }

  /**
   * Edit the role of users from this organization
   *
   * @param usernames - A list of usernames whose role to edit
   * @param role - The role to grant the given users on this organization
   */
  editUsers(usernames, role) {
    return this._api
      .put(`/organizations/${this.organization_id}/users/`, { usernames, role })
      .then(this._api.clearCache(`/organizations/${this.organization_id}/`));
  }

  createWorkspace(name) {
    return this._api
      .post(`/workspaces/`, { name, organization_id: this.organization_id })
      .then(({ workspace_id: workspaceId }) => {
        this._api.clearCache('/profile/');
        return workspaceId;
      });
  }

  getUsageReport() {
    const { response } = this._api.get(
      `/organizations/${this.organization_id}/usage/`
    );
    if (response == null) {
      return null;
    }
    return new UsageReport(response);
  }
}

export function formatFullName(name) {
  return name || 'n/a';
}

export function formatRoleName(role) {
  return _.upperFirst(role).replace(/_/g, ' ');
}

export function formatTokenDate(timestamp) {
  if (timestamp === null) {
    return 'Never used';
  } else if (timestamp === undefined) {
    return 'Unknown';
  }
  return moment.utc(timestamp * 1000).format('YYYY-MM-DD');
}

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const api = useApiClient();

  const profileResponse = api.get('/profile/').response;
  const statusResponse = api.get('/status/', {
    include_secrets: true
  }).response;
  useRecordAnalytics(profileResponse);
  useUserflow(profileResponse);
  if (profileResponse == null || statusResponse == null) {
    return null;
  }

  const profile = new Profile(api, profileResponse);
  const serverStatus = statusResponse;
  const roles = statusResponse.roles;
  const workspaceRoles = roles
    .filter(
      role => role.name !== 'none' && role.valid_scopes.includes('workspace')
    )
    .map(role => role.name);
  const organizationRoles = roles
    .filter(role => role.valid_scopes.includes('organization'))
    .map(role => role.name);

  return (
    <AuthContext.Provider
      value={{ profile, workspaceRoles, organizationRoles, serverStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function getUserRowsAndColumns(users, username) {
  const rows = users?.map(user => {
    return user.username === username
      ? {
          ...user,
          full_name: formatFullName(user.full_name) + ' (you)',
          clickable: false,
          cssAttributes: css`
            font-style: italic;
          `
        }
      : { ...user, clickable: true };
  });
  const columns = [
    {
      key: 'full_name',
      label: 'Full name',
      width: '1fr',
      render: user => formatFullName(user.full_name),
      defaultSortColumn: true,
      defaultSortDirection: 'asc'
    },
    { key: 'username', label: 'Email', width: '1fr' },
    {
      key: 'role',
      label: 'Role',
      width: '0.4fr',
      render: user => formatRoleName(user.role)
    }
  ];
  return { rows, columns };
}

function useRecordAnalytics(rawProfile) {
  const [hasSent, setHasSent] = useState(false);
  useEffect(() => {
    if (window.heap && !hasSent && rawProfile) {
      const { username, full_name, organizations, organization_id } =
        rawProfile;
      const organization = _.find(organizations, { organization_id });
      const organization_name = organization?.name ?? '';
      window.heap.identify(username);
      window.heap.addUserProperties({
        name: full_name,
        email: username,
        organization_id,
        organization_name
      });
      setHasSent(true);
    }
  }, [rawProfile, hasSent]);
}

function useUserflow(rawProfile) {
  const [hasIdentified, setHasIdentified] = useState(false);

  useEffect(() => {
    if (window.userflow && !hasIdentified && rawProfile) {
      const { username, full_name, organizations, organization_id } =
        rawProfile;
      const organization = _.find(organizations, { organization_id });
      const organization_name = organization?.name ?? '';
      window.userflow.identify(username, {
        name: full_name,
        email: username,
        organization_id,
        organization_name,
        // __BUILD_TIME__ is a webpack global
        // eslint-disable-next-line no-undef
        build_time: __BUILD_TIME__
      });
      setHasIdentified(true);
    }
  }, [rawProfile, hasIdentified]);
}
