import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function Calender({ iconColor, widthName, lineWidth, ...props }) {
  return (
    <SVGWrapper {...props}>
      <CalendarIcon
        iconColor={iconColor}
        widthName={widthName}
        lineWidth={lineWidth}
      />
      <path
        d="M4.00002 7.69727C3.81593 7.69727 3.66669 7.8465 3.66669 8.0306V9.36393C3.66669 9.54803 3.81593 9.69727 4.00002 9.69727H5.33335C5.51745 9.69727 5.66669 9.54803 5.66669 9.36393V8.0306C5.66669 7.8465 5.51745 7.69727 5.33335 7.69727H4.00002Z"
        fill={iconColor}
      />
      <path
        d="M7.33335 7.69727C7.14926 7.69727 7.00002 7.8465 7.00002 8.0306V9.36393C7.00002 9.54803 7.14926 9.69727 7.33335 9.69727H8.66669C8.85078 9.69727 9.00002 9.54803 9.00002 9.36393V8.0306C9.00002 7.8465 8.85078 7.69727 8.66669 7.69727H7.33335Z"
        fill={iconColor}
      />
      <path
        d="M10.3334 8.0306C10.3334 7.8465 10.4826 7.69727 10.6667 7.69727H12C12.1841 7.69727 12.3334 7.8465 12.3334 8.0306V9.36393C12.3334 9.54803 12.1841 9.69727 12 9.69727H10.6667C10.4826 9.69727 10.3334 9.54803 10.3334 9.36393V8.0306Z"
        fill={iconColor}
      />
      <path
        d="M4.00002 11.0306C3.81593 11.0306 3.66669 11.1798 3.66669 11.3639V12.6973C3.66669 12.8814 3.81593 13.0306 4.00002 13.0306H5.33335C5.51745 13.0306 5.66669 12.8814 5.66669 12.6973V11.3639C5.66669 11.1798 5.51745 11.0306 5.33335 11.0306H4.00002Z"
        fill={iconColor}
      />
      <path
        d="M7.00002 11.3639C7.00002 11.1798 7.14926 11.0306 7.33335 11.0306H8.66669C8.85078 11.0306 9.00002 11.1798 9.00002 11.3639V12.6973C9.00002 12.8814 8.85078 13.0306 8.66669 13.0306H7.33335C7.14926 13.0306 7.00002 12.8814 7.00002 12.6973V11.3639Z"
        fill={iconColor}
      />
      <path
        d="M10.6667 11.0306C10.4826 11.0306 10.3334 11.1798 10.3334 11.3639V12.6973C10.3334 12.8814 10.4826 13.0306 10.6667 13.0306H12C12.1841 13.0306 12.3334 12.8814 12.3334 12.6973V11.3639C12.3334 11.1798 12.1841 11.0306 12 11.0306H10.6667Z"
        fill={iconColor}
      />
    </SVGWrapper>
  );
}

const CalendarIcon = ({ iconColor, widthName, lineWidth }) => {
  switch (widthName) {
    case 'light':
      return (
        <path
          d="M5.04366 3.25169C5.04366 3.52783 5.26751 3.75169 5.54366 3.75169C5.8198 3.75169 6.04366 3.52783 6.04366 3.25169H5.04366ZM11.4727 3.25169C11.4727 3.52783 11.6966 3.75169 11.9727 3.75169C12.2489 3.75169 12.4727 3.52783 12.4727 3.25169H11.4727ZM9.86548 3.25169C9.86548 3.52783 10.0893 3.75169 10.3655 3.75169C10.6416 3.75169 10.8655 3.52783 10.8655 3.25169H9.86548ZM2.97363 3.75169H12.952V2.75169H2.97363V3.75169ZM12.952 14.5038H2.97363V15.5038H12.952V14.5038ZM1.47363 13.0038V5.99267H0.473633V13.0038H1.47363ZM1.47363 5.99267V5.25169H0.473633V5.99267H1.47363ZM14.452 5.25169V5.99267H15.452V5.25169H14.452ZM14.452 5.99267V13.0038H15.452V5.99267H14.452ZM0.973633 6.49267H14.952V5.49267H0.973633V6.49267ZM5.04366 1.78313V3.25169H6.04366V1.78313H5.04366ZM4.43639 3.25169V1.78313H3.43639V3.25169H4.43639ZM4.74002 1.49609C4.9176 1.49609 5.04366 1.63439 5.04366 1.78313H6.04366C6.04366 1.06253 5.45011 0.496094 4.74002 0.496094V1.49609ZM4.74002 0.496094C4.02993 0.496094 3.43639 1.06253 3.43639 1.78313H4.43639C4.43639 1.63439 4.56244 1.49609 4.74002 1.49609V0.496094ZM11.4727 1.78313V3.25169H12.4727V1.78313H11.4727ZM10.8655 3.25169V1.78313H9.86548V3.25169H10.8655ZM11.1691 1.49609C11.3467 1.49609 11.4727 1.63439 11.4727 1.78313H12.4727C12.4727 1.06253 11.8792 0.496094 11.1691 0.496094V1.49609ZM11.1691 0.496094C10.459 0.496094 9.86548 1.06253 9.86548 1.78313H10.8655C10.8655 1.63439 10.9915 1.49609 11.1691 1.49609V0.496094ZM2.97363 14.5038C2.14521 14.5038 1.47363 13.8322 1.47363 13.0038H0.473633C0.473633 14.3845 1.59292 15.5038 2.97363 15.5038V14.5038ZM12.952 15.5038C14.3327 15.5038 15.452 14.3845 15.452 13.0038H14.452C14.452 13.8322 13.7804 14.5038 12.952 14.5038V15.5038ZM12.952 3.75169C13.7804 3.75169 14.452 4.42326 14.452 5.25169H15.452C15.452 3.87098 14.3327 2.75169 12.952 2.75169V3.75169ZM2.97363 2.75169C1.59292 2.75169 0.473633 3.87098 0.473633 5.25169H1.47363C1.47363 4.42326 2.14521 3.75169 2.97363 3.75169V2.75169Z"
          fill={iconColor}
        />
      );
    case 'bolder':
      return (
        <path
          d="M4.64363 3.25168C4.64363 3.74874 5.04657 4.15168 5.54363 4.15168C6.04068 4.15168 6.44363 3.74874 6.44363 3.25168H4.64363ZM11.0727 3.25168C11.0727 3.74874 11.4757 4.15168 11.9727 4.15168C12.4698 4.15168 12.8727 3.74874 12.8727 3.25168H11.0727ZM9.46545 3.25168C9.46545 3.74874 9.86839 4.15168 10.3654 4.15168C10.8625 4.15168 11.2654 3.74874 11.2654 3.25168H9.46545ZM4 4.15168H12V2.35168H4V4.15168ZM12 14.1038H4V15.9038H12V14.1038ZM2.9 13.0038V5.99266H1.1V13.0038H2.9ZM2.9 5.99266V5.25168H1.1V5.99266H2.9ZM13.1 5.25169V5.99266H14.9V5.25169H13.1ZM13.1 5.99266V13.0038H14.9V5.99266H13.1ZM2 6.89266H14V5.09266H2V6.89266ZM4.64363 1.78312V3.25168H6.44363V1.78312H4.64363ZM4.83636 3.25168V1.78312H3.03636V3.25168H4.83636ZM4.73999 1.89609C4.70457 1.89609 4.64363 1.86313 4.64363 1.78312H6.44363C6.44363 0.833785 5.66308 0.0960906 4.73999 0.0960906V1.89609ZM4.73999 0.0960906C3.8169 0.0960906 3.03636 0.833785 3.03636 1.78312H4.83636C4.83636 1.86313 4.77541 1.89609 4.73999 1.89609V0.0960906ZM11.0727 1.78312V3.25168H12.8727V1.78312H11.0727ZM11.2654 3.25168V1.78312H9.46545V3.25168H11.2654ZM11.1691 1.89609C11.1337 1.89609 11.0727 1.86313 11.0727 1.78312H12.8727C12.8727 0.833785 12.0922 0.0960906 11.1691 0.0960906V1.89609ZM11.1691 0.0960906C10.246 0.0960906 9.46545 0.833785 9.46545 1.78312H11.2654C11.2654 1.86313 11.2045 1.89609 11.1691 1.89609V0.0960906ZM4 14.1038C3.39249 14.1038 2.9 13.6113 2.9 13.0038H1.1C1.1 14.6054 2.39837 15.9038 4 15.9038V14.1038ZM12 15.9038C13.6016 15.9038 14.9 14.6054 14.9 13.0038H13.1C13.1 13.6113 12.6075 14.1038 12 14.1038V15.9038ZM12 4.15168C12.6075 4.15168 13.1 4.64417 13.1 5.25169H14.9C14.9 3.65006 13.6016 2.35168 12 2.35168V4.15168ZM4 2.35168C2.39837 2.35168 1.1 3.65006 1.1 5.25168H2.9C2.9 4.64417 3.39249 4.15168 4 4.15168V2.35168Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <>
          <path
            d="M3.93639 2.44805C3.93639 2.89189 4.29619 3.25169 4.74002 3.25169C5.18386 3.25169 5.54366 2.89189 5.54366 2.44805V1.78313C5.54366 1.34846 5.18386 0.996094 4.74002 0.996094C4.29619 0.996094 3.93639 1.34846 3.93639 1.78313V2.44805Z"
            fill={iconColor}
          />
          <path
            d="M10.3655 2.44805C10.3655 2.89189 10.7253 3.25169 11.1691 3.25169C11.6129 3.25169 11.9727 2.89189 11.9727 2.44805V1.78313C11.9727 1.34846 11.6129 0.996094 11.1691 0.996094C10.7253 0.996094 10.3655 1.34846 10.3655 1.78313V2.44805Z"
            fill={iconColor}
          />
          <path
            d="M0.973633 5.99267V13.0038C0.973633 14.1084 1.86906 15.0038 2.97363 15.0038H12.952C14.0565 15.0038 14.952 14.1084 14.952 13.0038V5.99267M0.973633 5.99267V5.25169C0.973633 4.14712 1.86906 3.25169 2.97363 3.25169H12.952C14.0565 3.25169 14.952 4.14712 14.952 5.25169V5.99267M0.973633 5.99267H14.952M5.54366 1.78313V2.44805C5.54366 2.89189 5.18386 3.25169 4.74002 3.25169V3.25169C4.29619 3.25169 3.93639 2.89189 3.93639 2.44805V1.78313C3.93639 1.34846 4.29619 0.996094 4.74002 0.996094C5.18386 0.996094 5.54366 1.34846 5.54366 1.78313ZM11.9727 1.78313V2.44805C11.9727 2.89189 11.6129 3.25169 11.1691 3.25169V3.25169C10.7253 3.25169 10.3655 2.89189 10.3655 2.44805V1.78313C10.3655 1.34846 10.7253 0.996094 11.1691 0.996094C11.6129 0.996094 11.9727 1.34846 11.9727 1.78313Z"
            stroke={iconColor}
            strokeWidth={lineWidth}
          />
        </>
      );
  }
};
