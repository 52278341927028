import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Hr = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper defaultSize="24" {...props}>
      <path
        fill={iconColor}
        xmlns="http://www.w3.org/2000/svg"
        d="m8,12c-3.309,0-6-2.691-6-6S4.691,0,8,0s6,2.691,6,6-2.691,6-6,6Zm-1,9v-6c0-.324.037-.638.096-.945-3.986.451-7.096,3.84-7.096,7.945v1c0,.552.448,1,1,1h7c.008,0,.014-.004.022-.004-.633-.837-1.022-1.868-1.022-2.996Zm12.5-3.6h-.9v-1.8h.9c.496,0,.9.404.9.9s-.404.9-.9.9Zm4.5-2.4v6c0,1.657-1.343,3-3,3h-9c-1.657,0-3-1.343-3-3v-6c0-1.657,1.343-3,3-3h9c1.657,0,3,1.343,3,3Zm-8.8-1h0c-.442,0-.8.358-.8.8v2.395h-1.8v-2.395c0-.442-.358-.8-.8-.8h0c-.442,0-.8.358-.8.8v6.4c0,.442.358.8.8.8h0c.442,0,.8-.358.8-.8v-2.405h1.8v2.405c0,.442.358.8.8.8h0c.442,0,.8-.358.8-.8v-6.4c0-.442-.358-.8-.8-.8Zm5.733,4.544c.723-.508,1.168-1.386,1.047-2.362-.158-1.274-1.33-2.182-2.613-2.182h-1.567c-.442,0-.8.358-.8.8v6.4c0,.442.358.8.8.8h0c.442,0,.8-.358.8-.8v-2.2h.784l.705,2.423c.099.342.412.577.768.577h.063c.537,0,.922-.519.765-1.033l-.739-2.43c.023-.017.024-.016.042-.029-.019.012-.031.021-.055.035Z"
      />
    </SVGWrapper>
  );
};
