import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Pen = ({ iconColor, lineWidth, widthName, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M1.36485 14.5289C1.25914 14.773 1.49985 15.0238 1.74805 14.9281L4.84455 13.7344L2.68374 11.4836L1.36485 14.5289Z"
      fill={iconColor}
    />
    <path
      d="M4.84455 13.7344L1.74805 14.9281C1.49985 15.0238 1.25914 14.773 1.36485 14.5289L2.68374 11.4836M4.84455 13.7344L2.68374 11.4836M4.84455 13.7344L13.7603 5.17495M2.68374 11.4836L11.5536 2.96825M11.5536 2.96825L12.5768 1.98592C12.776 1.79468 13.0925 1.80113 13.2838 2.00034L14.752 3.52971C14.9433 3.72892 14.9368 4.04543 14.7376 4.23667L13.7603 5.17495M11.5536 2.96825L13.7603 5.17495"
      stroke={iconColor}
      strokeWidth={widthName !== 'light' ? lineWidth : null}
    />
  </SVGWrapper>
);
