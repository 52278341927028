import React from 'react';

export const G2 = ({ iconColor = '#FF492C' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={'100px'}
			height={'30px'}
			viewBox="0 0 81.9 22.9"
			style={{ fill: iconColor }}
		>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path className="st0" d="M11.3,0.6c0,1,0,2.1,0,3.2c0,0.4-0.3,0.4-0.5,0.4C9,4.3,7.5,4.9,6.3,6.1c-2,2-2.8,4.4-2.1,7.2
						c0.7,2.7,2.4,4.5,5,5.4c3.6,1.1,7.4-0.7,8.9-4.1c0.1-0.2,0.1-0.4,0.2-0.6h-4.7V9.8h8.9c0.3,1.7,0.2,3.3-0.3,4.9
						c-1.5,4.6-4.6,7.4-9.4,8.1c-6.2,0.9-11.9-3.5-12.7-9.7c-0.8-5.9,3-11.3,8.7-12.6c0.7-0.2,1.3-0.2,2-0.3
						C11.1,0.2,11.3,0.3,11.3,0.6L11.3,0.6z M26.9,0c0.1,0,0.2,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3c0,7.2,0,14.4,0,21.6c0,0,0,0.1,0,0.1
						l0,0.1c0,0.2,0,0.4-0.3,0.3c-0.1,0-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2,0-0.4V11.4l0-10.9C26.6,0.3,26.5,0,26.9,0z M55.9,4.8
						c1.5,0.5,2.3,1.5,2.3,3.1l0,1l0,2.1l0.1,0c-0.1,1.2-0.1,2.5-0.2,3.7c-0.1,0.9-0.6,1.6-1.3,2c-1.7,0.9-3.5,0.9-5.2,0.1
						c-0.9-0.5-1.5-1.3-1.5-2.4c0-2.2,0-4.4,0-6.6c0-1.7,1.1-2.7,2.5-3.1C53.6,4.5,54.7,4.5,55.9,4.8z M37.5,5.1
						c1.1,0.5,1.7,1.3,1.6,2.6c0,1.3,0,1.3-1.3,1.2l-0.3,0H37l-0.6,0c0-0.5,0-0.9,0-1.3c0-0.7-0.2-0.9-0.8-0.9c-0.3,0-0.7-0.1-1,0.1
						c-0.2,0.1-0.5,0.4-0.6,0.7c-0.1,0.3-0.1,0.7-0.1,1c0,1.7,0,3.5,0,5.2v0.2c0,1.6,0.4,1.6,1.8,1.5c0.5,0,0.7-0.3,0.7-0.8
						c0-0.5,0.1-0.9,0.1-1.5H39c0.3,2.1-0.3,3.6-2.6,4.2c-1,0.3-2,0.2-3-0.1c-1.3-0.4-2.1-1.3-2.1-2.6c-0.1-2.4-0.1-4.8,0-7.2
						c0.1-1.3,1-2.1,2.2-2.4C34.8,4.4,36.2,4.4,37.5,5.1L37.5,5.1z M41.1,4.8c1.6,0.1,3.2,0,4.8,0.1c1.6,0.1,2.2,0.9,2.4,2.5
						c0.1,0.7,0,1.4-0.1,2.1c-0.1,0.7-0.5,1.1-1.2,1.2c-0.1,0-0.3,0.1-0.6,0.1c0.3,0.1,0.5,0.2,0.6,0.2c0.8,0.3,1.2,0.9,1.3,1.7
						c0,1.3,0,2.6,0,3.9c0,0.1-0.2,0.4-0.3,0.4c-0.8,0-1.5,0-2.3,0c0-0.4,0-0.8,0-1.2c0-1,0-2.1-0.1-3.1c0-0.5-0.3-0.8-0.8-0.9
						c-0.4-0.1-0.8-0.1-1.1-0.1c-0.4,0-0.5,0.1-0.5,0.5c0,1.4,0,2.8,0,4.2v0.7c-0.8,0-1.6,0-2.4,0c-0.1,0-0.2-0.2-0.2-0.3
						c0-0.3,0-0.6,0-0.9c0-3.5,0-7.1,0-10.6C40.6,5,40.7,4.8,41.1,4.8L41.1,4.8z M71.1,4.8c0.6,0,1.2,0,1.9,0
						c-0.1,0.6-0.2,1.1-0.3,1.7c-0.7,3.4-1.4,6.7-2.1,10.1c-0.1,0.4-0.2,0.5-0.6,0.5c-0.6,0-1.3,0-1.9,0c-0.3,0-0.4-0.1-0.4-0.4
						c-0.5-2.5-0.9-5-1.4-7.5c-0.1-0.3-0.1-0.6-0.3-0.9c-0.1,0.6-0.2,1.1-0.4,1.7c-0.4,2.2-0.9,4.4-1.3,6.7
						c-0.1,0.3-0.2,0.4-0.5,0.4c-0.7,0-1.4,0-2.1,0c-0.4,0-0.5-0.1-0.6-0.5c-0.4-2.4-0.9-4.8-1.4-7.2l-0.9-4.6h1.8
						c0.8,0,0.8,0,0.9,0.8c0.3,2.4,0.7,4.8,1,7.2c0.1,0.4,0.1,0.8,0.1,1.1l0.1,0l0.2-1.4c0-0.2,0.1-0.5,0.1-0.7
						c0.4-2.2,0.9-4.4,1.3-6.7c0-0.1,0.2-0.3,0.3-0.3c0.8,0,1.6,0,2.4,0c0.1,0,0.4,0.2,0.4,0.3c0.3,1.4,0.5,2.7,0.8,4.1
						c0.2,1.5,0.5,3.1,0.8,4.6l0.1-1.1c0-0.2,0-0.4,0.1-0.6c0.4-2.3,0.7-4.6,1.1-7C70.7,5,70.8,4.8,71.1,4.8L71.1,4.8z M74.6,4.8
						c1.6,0.1,3.3,0.1,4.9,0.2c1.4,0.1,2.3,1,2.4,2.1c0,0.6,0.1,1.2,0.1,1.8c0,2,0,3.9-0.1,5.9c0,1.8-1.1,2.2-2.3,2.2
						c-1.7,0.1-3.4,0-5,0.1c-0.4,0-0.4-0.2-0.4-0.5c0-1.9,0-3.8,0-5.6c0-1.9,0-3.8,0-5.6C74.1,5,74.2,4.8,74.6,4.8L74.6,4.8z
						 M53.6,6.7c-0.8,0-1.1,0.3-1.1,1.2c0,1,0,2.1,0,3.1h0c0,1.1,0,2.2,0,3.3c0,0.8,0.2,1,1,1.1c0.3,0,0.6,0,0.9,0
						c0.7-0.1,1-0.3,1-1c0-1.5,0-2.9,0.1-4.4c0-0.7,0-1.5,0-2.2c-0.1-0.8-0.3-1-1.1-1C54.1,6.6,53.8,6.7,53.6,6.7z M76.8,7.3
						c0,2.2,0,4.4,0,6.6v1.2c0.4,0,0.8,0,1.1,0c0.1,0,0.3,0,0.4-0.1c0.7-0.2,1-0.4,1-1.1c0-2.1,0-4.1,0-6.2c0-0.4-0.2-0.7-0.7-0.8
						c-0.4-0.1-0.9-0.1-1.4-0.2C76.9,6.7,76.8,6.9,76.8,7.3z M43.2,6.6v0.6l0,1.1c0,0.6,0,1.1,0,1.6c0,0.1,0.2,0.3,0.3,0.3
						c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.3,0.8-0.7c0.1-0.6,0.2-1.2,0.1-1.7c0-0.3-0.3-0.7-0.5-0.8C44.5,6.7,43.9,6.7,43.2,6.6z
						 M18.8,2.1c0.1,0.6,0,1.1-0.3,1.5c-0.6,0.8-1.3,1.6-1.9,2.4c-0.3,0.3-0.6,0.6-1,1c0.2,0,0.4,0,0.5,0c0.7,0,1.5,0,2.2,0
						c0.4,0,0.5,0.1,0.5,0.5c0,0.2,0,0.3,0,0.5c0,0.1-0.2,0.3-0.3,0.3c-1.6,0-3.3,0-5,0c0.2-0.4,0.2-0.7,0.4-0.9L14.9,6l2.1-2.6
						c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.7,0.2-1.2-0.2-1.6c-0.5-0.4-1.4-0.5-1.8,0c-0.3,0.3-0.5,0.6-0.8,1l-1-0.2
						c0.2-0.8,0.6-1.4,1.3-1.8C16.2-0.5,18.5,0.3,18.8,2.1z"  fill="#FF492C"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
