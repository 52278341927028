import { useRef } from 'react';

import { useBoundingClientRect } from './useBoundingClientRect';

/**
 * Get the number of pixels that correspond to 1rem
 * Values are pulled from "typography.styl"
 *
 * @return {number} Font size in pixels
 */
export function useDefaultFontSize() {
  // useBoundingClientRect expects a ref, so make one even though the current
  // value of htmlRef should never actually change.
  const htmlRef = useRef(document.querySelector('html'));
  const { width } = useBoundingClientRect(htmlRef);

  if (width > 900) {
    return 16;
  } else if (width > 600) {
    return 14;
  } else {
    return 12;
  }
}
