import {
  CategoricalBreakdown,
  DateBreakdown,
  NumericBreakdown,
  ScoreBreakdown
} from '../classes/Breakdowns';
import {
  CategoricalField,
  DateField,
  NumericField,
  ScoreField
} from '../classes/MetadataFields';

export const isBucketCountInvalid = breakdown => {
  const bucketCount = breakdown.getBucketCount();
  switch (breakdown.constructor) {
    case CategoricalBreakdown:
      return 100 < bucketCount;
    case DateBreakdown:
      return bucketCount <= 1 || 100 < bucketCount;
    case NumericBreakdown:
    case ScoreBreakdown:
      return bucketCount <= 1 || 100 < bucketCount;
    default:
      return true;
  }
};

export const isDateIntervalInvalid = (breakdownField, interval) => {
  const breakdown = new DateBreakdown(breakdownField, interval);
  return isBucketCountInvalid(breakdown);
};

export const isStringFieldDisabled = field => {
  const breakdown = new CategoricalBreakdown(field);
  return isBucketCountInvalid(breakdown);
};

export const numericFieldHasNoRange = field => {
  return field.minimum === field.maximum;
};

export const isBreakdownFieldDisabled = field => {
  switch (field.constructor) {
    case CategoricalField:
      return isBucketCountInvalid(new CategoricalBreakdown(field));
    case DateField:
      return DateBreakdown.INTERVALS.every(interval =>
        isBucketCountInvalid(new DateBreakdown(field, interval))
      );
    case NumericField:
    case ScoreField:
      return (
        numericFieldHasNoRange(field) ||
        NumericBreakdown.getIntervals(field).intervals.every(interval =>
          isBucketCountInvalid(new NumericBreakdown(field, interval))
        )
      );
    default:
      return true;
  }
};

export const defaultDateInterval = field =>
  DateBreakdown.INTERVALS.find(
    interval => !isDateIntervalInvalid(field, interval)
  );
