import { css } from '@emotion/react';
import React, { useMemo, useState, forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Mixins, Colors } from '../../styles';
import ConfirmationDialog from '../ConfirmationDialog';
import { countChildren } from '../../utils/common';
import VisuallyHidden from './VisuallyHidden';

/**
 * Our standard button component.
 * @property {JSX.Element} [children]
 * @property {string} [palette] - The `palette` prop determines the button's color scheme.
 * @property {string} [flavor]
 * @property {boolean} [padded]
 * @property {string} [trackingItem]
 * @property {string} [hiddenLabel]
 */
export const Button = memo(
  forwardRef(function Button(
    {
      children,
      padded = true,
      palette = 'blue',
      flavor = 'normal',
      hiddenLabel,
      hideOnPrint = false,
      trackingItem,
      ...passedProps
    },
    ref
  ) {
    // We want to add extra spacing to the right of an icon, IF it is followed
    // by some text, but NOT if it is the only thing in the button.  This
    // is (shockingly) the most straightforward way to check that.
    const multipleChildren = countChildren(children) > 1;
    const styles = useMemo(
      () => buttonStyles({ palette, flavor, padded, multipleChildren }),
      [palette, flavor, padded, multipleChildren]
    );
    return (
      <button
        ref={ref}
        type="button"
        css={styles}
        data-tracking-item={trackingItem}
        data-type={hideOnPrint ? 'transparent' : ''}
        {...passedProps}
      >
        {children}
        {hiddenLabel && <VisuallyHidden>{hiddenLabel}</VisuallyHidden>}
      </button>
    );
  })
);

// These are exported so they can be used in Storybook
export const PALETTES = ['blue', 'green', 'yellow', 'red', 'gray'];
export const FLAVORS = ['normal', 'subtle'];

Button.propTypes = {
  children: PropTypes.node,
  /** Determines the button's color scheme. */
  palette: PropTypes.oneOf(PALETTES),
  /** Determines the overall shape and style of the button. */
  flavor: PropTypes.oneOf(FLAVORS),
  /** Boolean value indicating whether the button should apply padding. */
  padded: PropTypes.bool,
  trackingItem: PropTypes.string,
  hiddenLabel: PropTypes.string
};

export function ConfirmableButton({
  confirmationMessage,
  confirmText,
  cancelText,
  autoFocusConfirm = false,
  trackingItemConfirm,
  trackingItemCancel,
  onClick,
  onConfirm,
  onCancel,
  ...passedProps
}) {
  const [displayModal, setDisplayModal] = useState(false);
  return (
    <>
      <Button
        onClick={event => {
          setDisplayModal(true);
          onClick?.(event);
        }}
        {...passedProps}
      />
      <ConfirmationDialog
        showing={displayModal}
        onCancel={() => {
          setDisplayModal(false);
          onCancel?.();
        }}
        onConfirm={() => {
          setDisplayModal(false);
          onConfirm?.();
        }}
        confirmText={confirmText}
        cancelText={cancelText}
        autoFocusConfirm={autoFocusConfirm}
        trackingItemConfirm={trackingItemConfirm}
        trackingItemCancel={trackingItemCancel}
      >
        {confirmationMessage}
      </ConfirmationDialog>
    </>
  );
}

ConfirmableButton.propTypes = {
  confirmationMessage: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  autoFocusConfirm: PropTypes.bool,
  trackingItemConfirm: PropTypes.string,
  trackingItemCancel: PropTypes.string,
  onClick: PropTypes.func,
  onCancel: PropTypes.func
};

const normalStyles = css`
  ${Mixins.shadowOutset};
  ${Mixins.roundedCorners};
`;

const paddedIconStyles = css`
  svg,
  .fa::before,
  &.fa::before {
    margin-right: 0.5em;
  }
`;

const buttonStyles = ({ palette, flavor, padded, multipleChildren }) => {
  const {
    foreground,
    icon,
    background,
    foregroundHover,
    iconHover,
    backgroundHover,
    disabled,
    disabledBackground
  } = getColors(palette, flavor);

  const padding = padded ? '0.5rem' : 0;

  return css`
    ${flavor === 'normal' && normalStyles};
    ${multipleChildren && paddedIconStyles};
    color: ${foreground};
    background: ${background};
    padding: ${padding};

    svg,
    .fa::before,
    &.fa::before {
      color: ${icon};
      vertical-align: bottom;
    }

    &:hover,
    &:active {
      color: ${foregroundHover};
      background: ${backgroundHover};
      svg,
      .fa::before,
      &.fa::before {
        color: ${iconHover};
      }
    }
    &:disabled,
    &:disabled:hover {
      color: ${disabled};
      background: ${disabledBackground};
      svg,
      .fa::before,
      &.fa::before {
        color: ${disabled};
      }
    }
  `;
};

const getColors = (palette, flavor) => {
  let foreground;
  let icon;
  let background;
  let foregroundHover;
  let iconHover;
  let backgroundHover;
  let disabledBackground;

  if (flavor === 'normal') {
    disabledBackground = Colors.gray0;
    switch (palette) {
      case 'blue':
        foreground = Colors.gray9;
        icon = Colors.blue4;
        background = Colors.blue0;
        iconHover = Colors.blue5;
        foregroundHover = Colors.gray9;
        backgroundHover = Colors.blue1;
        break;
      case 'green':
        foreground = Colors.gray9;
        icon = Colors.green5;
        background = Colors.green1;
        foregroundHover = Colors.gray9;
        iconHover = Colors.green6;
        backgroundHover = Colors.green2;
        break;
      case 'yellow':
        foreground = Colors.gray9;
        icon = Colors.yellow4;
        background = Colors.yellow0;
        foregroundHover = Colors.gray9;
        iconHover = Colors.yellow5;
        backgroundHover = Colors.yellow1;
        break;
      case 'red':
        foreground = Colors.gray9;
        icon = Colors.red3;
        background = Colors.red0;
        foregroundHover = Colors.gray9;
        iconHover = Colors.red4;
        backgroundHover = Colors.red1;
        break;
      case 'gray':
        foreground = Colors.gray9;
        icon = Colors.gray9;
        background = 'white';
        foregroundHover = Colors.gray9;
        iconHover = Colors.gray9;
        backgroundHover = Colors.gray0;
        break;
    }
  } else if (flavor === 'subtle') {
    background = 'transparent';
    backgroundHover = 'transparent';
    disabledBackground = 'transparent';

    switch (palette) {
      case 'blue':
        foreground = Colors.blue4;
        icon = foreground;
        foregroundHover = Colors.blue5;
        iconHover = foregroundHover;
        break;
      case 'green':
        foreground = Colors.green5;
        icon = Colors.green5;
        foregroundHover = Colors.green6;
        iconHover = foregroundHover;
        break;
      case 'yellow':
        foreground = Colors.yellow4;
        icon = Colors.yellow4;
        foregroundHover = Colors.yellow5;
        iconHover = foregroundHover;
        break;
      case 'red':
        foreground = Colors.red3;
        icon = foreground;
        foregroundHover = Colors.red4;
        iconHover = foregroundHover;
        break;
      case 'gray':
        foreground = Colors.gray6;
        icon = foreground;
        foregroundHover = Colors.gray8;
        iconHover = foregroundHover;
        break;
    }
  }

  return {
    foreground,
    icon,
    background,
    foregroundHover,
    iconHover,
    backgroundHover,
    disabled: Colors.gray5,
    disabledBackground
  };
};

export function PlainTextButton(props) {
  return (
    <Button
      flavor="subtle"
      padded={false}
      css={css`
        color: inherit;

        &:hover,
        &:active {
          color: inherit;
        }
      `}
      {...props}
    />
  );
}

export const LinkButton = ({
  palette = 'blue',
  linkTo,
  routerLink,
  children,
  ...passedProps
}) => {
  const styles = getLinkButtonStyles(palette);
  return routerLink ? (
    <Link to={linkTo} style={styles} />
  ) : (
    <a href={linkTo} css={styles} {...passedProps}>
      {children}
    </a>
  );
};

LinkButton.propTypes = {
  palette: PropTypes.oneOf(PALETTES),
  linkTo: PropTypes.string.isRequired,
  routerLink: PropTypes.bool,
  children: PropTypes.node
};

const getLinkButtonStyles = palette => {
  const {
    foreground,
    icon,
    background,
    foregroundHover,
    iconHover,
    backgroundHover,
    disabled,
    disabledBackground
  } = getColors(palette, 'normal');

  // Styling taken from actionLinkStyles
  // Ternary needed to override default link styling
  return css`
    padding: 0.375rem 0.5rem;
    background: ${background};
    color: ${foreground ? foreground : 'black'};
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    /* hack to make this the same height as the buttons it appears next to */
    min-height: 1.4375rem;

    ${normalStyles}

    svg,
    .fa::before,
    &.fa::before {
      margin-right: 0.5em;
      color: ${icon};
      vertical-align: bottom;
    }

    &:not([href]) {
      color: ${disabled};
      background: ${disabledBackground};
      &:active,
      &:hover {
        color: ${disabled};
        background: ${disabledBackground};
      }
    }
    &:hover {
      background: ${backgroundHover};
      color: ${foregroundHover ? foregroundHover : 'black'};
      text-decoration: none;
      svg,
      .fa::before,
      &.fa::before {
        color: ${iconHover};
      }
    }
    &:active {
      text-decoration: none;
    }
  `;
};
