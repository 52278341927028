import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Sentiment = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper defaultSize="32" {...props}>
    <path
      d="M17.6045 25.6498C18.7849 24.6048 20.3433 23.9695 22.0515 23.9695C23.7598 23.9695 25.3182 24.6048 26.4986 25.6498M13.5116 19.188C13.5207 19.1848 13.5299 19.1817 13.539 19.1785C16.1764 18.2645 18.2824 16.2308 19.2755 13.6533C19.2804 13.6405 19.2853 13.6276 19.2902 13.6148M13.5116 19.188C12.5368 19.5225 11.4899 19.7042 10.4001 19.7042C5.15575 19.7042 0.904352 15.4957 0.904352 10.3042C0.904352 5.11278 5.15575 0.904274 10.4001 0.904274C15.6445 0.904274 19.8959 5.11278 19.8959 10.3042C19.8959 11.4694 19.6817 12.5851 19.2902 13.6148M13.5116 19.188C13.185 20.113 13.0075 21.1073 13.0075 22.1427C13.0075 27.0872 17.0566 31.0956 22.0516 31.0956C27.0465 31.0956 31.0957 27.0872 31.0957 22.1427C31.0957 17.1982 27.0465 13.1898 22.0516 13.1898C21.0885 13.1898 20.1607 13.3388 19.2902 13.6148M8.28726 7.30588C7.86465 6.7749 7.20922 6.43399 6.47335 6.43399C5.73749 6.43399 5.08206 6.7749 4.65945 7.30588M16.1408 7.30588C15.7182 6.7749 15.0627 6.43399 14.3269 6.43399C13.591 6.43399 12.9356 6.7749 12.513 7.30588M15.1492 9.90815C15.1492 12.582 12.9596 14.7495 10.2585 14.7495C7.5575 14.7495 5.36786 12.582 5.36786 9.90815C5.36786 9.87623 7.79277 11.6187 10.4001 11.6187C13.0075 11.6187 15.1492 9.87623 15.1492 9.90815ZM19.1802 20.5746C19.7452 20.5746 20.2032 20.1203 20.2032 19.5599C20.2032 18.9995 19.7452 18.5452 19.1802 18.5452C18.6152 18.5452 18.1571 18.9995 18.1571 19.5599C18.1571 20.1203 18.6152 20.5746 19.1802 20.5746ZM25.83 19.5599C25.83 20.1203 25.372 20.5746 24.8069 20.5746C24.2419 20.5746 23.7839 20.1203 23.7839 19.5599C23.7839 18.9995 24.2419 18.5452 24.8069 18.5452C25.372 18.5452 25.83 18.9995 25.83 19.5599Z"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
