import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Download = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path;
  switch (widthName) {
    case 'light':
      path =
        'M7.99996 1.01767V11.6555M4.60069 8.65547L7.52856 11.5833C7.78891 11.8437 8.21102 11.8437 8.47137 11.5833L11.3992 8.65547M1.55084 10.6555V13.4159C1.55084 14.1523 2.21232 14.7492 3.0283 14.7492H13.0123C13.8283 14.7492 14.4898 14.1523 14.4898 13.4159V10.6555';
      break;
    case 'bolder':
      path =
        'M8 1.0177V11.6555M4.60072 8.6555L7.5286 11.5834C7.78895 11.8437 8.21106 11.8437 8.47141 11.5833L11.3993 8.6555M1.88727 10.6555V13.4159C1.88727 14.1523 2.54875 14.7493 3.36473 14.7493H12.6469C13.4629 14.7493 14.1243 14.1523 14.1243 13.4159V10.6555';
      break;
    default:
      path =
        'M8.00002 1.13422V11.772M4.60074 8.77202L7.52862 11.6999C7.78897 11.9602 8.21108 11.9602 8.47143 11.6999L11.3993 8.77202M1.74097 10.772V13.5324C1.74097 14.2688 2.40245 14.8658 3.21843 14.8658H12.7874C13.6034 14.8658 14.2649 14.2688 14.2649 13.5324V10.772';
  }
  return (
    <SVGWrapper {...props}>
      <path
        d={path}
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
};
