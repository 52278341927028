import { useEffect } from 'react';
import { getSSOLoginURL, ssoHasFailed } from './utils';
import { useQuery } from 'react-query';
import { getAPIStatus } from '../../../../main/scripts/utils/ApiUtilsV5';

export const useRedirectToSSOLoginURLIfOnlySSOIsEnabled = () => {
  const status = useStatus();
  useEffect(() => {
    if (
      status?.saml_authentication &&
      !status?.password_authentication &&
      !ssoHasFailed()
    ) {
      window.location.href = getSSOLoginURL();
    }
  }, [status]);
};

export const useStatus = () => {
  const { data } = useQuery('status', getAPIStatus);
  return data;
};
