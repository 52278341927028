import _ from 'lodash';

export default function (obj = {}) {
  return Object.keys(obj)
    .map(key => {
      const value = _.isObject(obj[key]) ? JSON.stringify(obj[key]) : obj[key];
      return [key, value].map(encodeURIComponent).join('=');
    })
    .join('&');
}
