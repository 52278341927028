import React, { useContext } from 'react';

import { useFetch } from './utils/hooks';
import { getAPIStatus } from './utils/ApiUtilsV5';
import SystemStatusContext from './SystemStatusContext';

export function SystemStatusProvider({ children }) {
  const { response } = useFetch(getAPIStatus);
  return (
    <SystemStatusContext.Provider value={response}>
      {children}
    </SystemStatusContext.Provider>
  );
}

export function useSystemStatus() {
  return useContext(SystemStatusContext);
}
