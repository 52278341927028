import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const CircularExclamation = ({
  iconColor,
  lineWidth,
  widthName,
  ...props
}) => {
  const ellipseR = widthName === 'light' ? '0.847286' : '1.09499';

  return (
    <SVGWrapper alt="exclamation" {...props}>
      <circle
        cx="8.00002"
        cy="7.93709"
        r="6.62654"
        stroke={iconColor}
        strokeWidth={lineWidth}
      />
      <path
        d="M7.98419 4.0021V8.03473"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="8.00002"
        cy="10.7771"
        rx={ellipseR}
        ry={ellipseR}
        fill={iconColor}
      />
    </SVGWrapper>
  );
};
