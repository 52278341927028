/**
 * apiClientHook - lightweight API client for use in React components

 * Key features:
 * - Provides a safe method for making GET requests in functional components.
 * - Provides methods suitable for making PUT, POST, and DELTE requests in
 *   event handlers.
 * - Caches results for the lifetime of the host component, unless explicitly
 *   cleared.
 * - Triggers rerenders as requests complete, or when results are cleared from
 *   the cache.
 * - Cleans up pending requests when the host component is unmounted.
 * - Can interact with both the V4 and V5 APIs.
 *
 * See the documentation on the ApiClient class for detailed usage.
 *
 * Possible future improvements to consider:
 * - Some GET endpoints in the API can also be sent as POSTs if you need to
 *   call them with large data. It would be nice if we could cache returns
 *   from these POST calls, and treat them interchangeably with the GETs.
 * - Currently, clearCache() takes a path and params. Should it be possible
 *   to clear _all_ cached results for a path, regardless of params?
 * - Should it be possible to call clearCache() with a wildcard?
 * - Should we automatically clear the cache for a path when a PUT, POST, or
 *   DELETE to that path occurs?
 * - This still occasionally produces situations where we have to call
 *   fastForward() twice in a row in tests. Can that be avoided?
 * - Currently it's hard to tell whether a call to get() from inside the
 *   then() handler of a promise from put()/post()/del() would be safe.
 *   Possibly there's no reason we'd ever need that anyway, but if we do
 *   discover we need it, it may take some fiddling to make it work.
 *
 * @example
 * function MyComponent() {
 *   const api = useApiClient();
 *   const { response, status } = api.get('/foo/');
 *   if (status !== RequestStatuses.FULFILLED) {
 *     return null;
 *   }
 *   return (
 *     <div>
 *       <p>Hello, {response.name}!</p>
 *       <button onClick={api.del('/bar/').then(() => api.clearCache('/foo/'))}>
 *         Click me
 *       </button>
 *     </div>
 *   );
 * }
 *
 */

import { useEffect, useRef } from 'react';

import { RequestStatuses } from '../constants';
import * as apiV5 from './apiV5';
import assembleUrl from './assembleUrl';
import { useForceUpdate } from './hooks';

/**
 * A client for the Daylight API
 */
class ApiClient {
  constructor(api, onChange, pendingRequests, cache, urlPrefix) {
    this.api = api;
    this.onChange = onChange;
    this.pendingRequests = pendingRequests;
    this.cache = cache;
    this.urlPrefix = urlPrefix;
    // The queue of GET requests that need to be made for the page currently
    // being rendered. It's okay that we create this here, rather than in
    // React hook, because we WANT a new list each time a component is rendered.
    this.requestQueue = [];
  }

  /**
   * Make a GET request
   *
   * This method is safe for use in a component-rendering method.
   *
   * The first time this is called with a given path and params, it will
   * trigger a GET request. In future calls, it will return a cached copy
   * of the response status, and the response body if the request has
   * completed. When the GET request completes, it will added to the
   * cache and the component will be redrawn.
   *
   * @param {string} path - Path to API endpoint (excluding initial '/api/v5/')
   * @param {Object} params - Search params for the call
   * @returns {{response: Object, status: string}} - Object containing the
   *          parsed JSON response and the status from RequestStatuses
   */
  get(path, params) {
    path = this.urlPrefix + path;
    const cacheKey = this._makeCacheKey(path, params);
    const result = this._getResult(cacheKey);
    // If the result doesn't have a completed status, then either we haven't
    // fetched it yet or we've called clearCache to invalidate the current
    // value.  Either way, this is a sign that we may need to re-request the
    // data.
    if (result.status === RequestStatuses.PENDING) {
      if (this.requestQueue === null) {
        // This means we're outside the render cycle for the component that
        // used the hook to create this ApiClient.  Adding requests to the
        // queue won't work, because the useEffect() has already run
        // flushRequestQueue() for this instance. So, trigger a redraw of the
        // component, which will ultimately send us back here with a NON-null
        // requestQueue.
        this.onChange();
      } else {
        this.requestQueue.push({ cacheKey, path, params });
      }
    }
    return result;
  }

  _getResult(cacheKey) {
    if (cacheKey in this.cache) {
      return this.cache[cacheKey];
    } else {
      return { status: RequestStatuses.PENDING, response: null };
    }
  }

  /**
   * Actually send any GET requests needed by the last render of the component
   * using this API client. This should only be called by the useEffect() in
   * the useApiHook() function defined in this file.
   */
  flushRequestQueue() {
    this.requestQueue.forEach(({ cacheKey, path, params }) => {
      if (!(cacheKey in this.pendingRequests)) {
        this.pendingRequests[cacheKey] = this.api
          .get(path, params)
          // the first then() and catch() standardize the result format
          .then(response => ({ status: RequestStatuses.FULFILLED, response }))
          .catch(response => ({ status: RequestStatuses.REJECTED, response }))
          // and the second then() handles the standardize result from both
          .then(result => {
            this.cache[cacheKey] = result;
            this.onChange();
            delete this.pendingRequests[cacheKey];
          });
      }
    });
    this.requestQueue = null;
  }

  /**
   * Make a DELETE request
   *
   * This method is NOT safe for use in a component-rendering method. It should
   * only be used in event hooks, such as an onClick handler.
   *
   * @param path {string} - Path to API endpoint
   * @param params {Object} - Search params for call
   * @returns {Promise} - Promise which will resolve when the call completes
   */
  del(path, params) {
    return this.api.del(this.urlPrefix + path, params);
  }

  /**
   * Make a PUT request
   *
   * This method is NOT safe for use in a component-rendering method. It should
   * only be used in event hooks, such as an onClick handler.
   *
   * @param path {string} - Path to API endpoint
   * @param payload {Object} - Object which will be JSON encoded, and sent as
   *                           the request body
   * @param params {Object} - Search params for call
   * @returns {Promise} - Promise which will resolve when the call completes
   */
  put(path, payload, params) {
    return this.api.put(this.urlPrefix + path, payload, params);
  }

  /**
   * Make a POST request
   *
   * This method is NOT safe for use in a component-rendering method. It should
   * only be used in event hooks, such as an onClick handler.
   *
   * @param path {string} - Path to API endpoint
   * @param payload {Object} - Object which will be JSON encoded, and sent as
   *                           the request body
   * @param params {Object} - Search params for call
   * @returns {Promise} - Promise which will resolve when the call completes
   */
  post(path, payload, params, type) {
    return this.api.post(this.urlPrefix + path, payload, params, type);
  }

  /**
   * Clear any cached results for the given path and params, triggering a new
   * call to the endpoint the next time it is needed.
   *
   * @param path {string}
   * @param params {Object}
   */
  clearCache(path, params) {
    const cacheKey = this._makeCacheKey(this.urlPrefix + path, params);
    if (cacheKey in this.cache) {
      this.cache[cacheKey].status = RequestStatuses.PENDING;
      this.onChange();
    }
  }

  _makeCacheKey(path, params) {
    return assembleUrl(path, params);
  }
}

/**
 * Hook returning a flexible Daylight API client for use in React components.
 * @returns {ApiClient}
 */
export function useApiClient() {
  const update = useForceUpdate();
  // Since the pendingRequests and cache refs will hold the same object for
  // their entire lives, it's safe to just get .current here, rather than
  // going through the ref object each time.
  const pendingRequests = useRef({}).current;
  const cache = useRef({}).current;
  // Because pendingRequest always refers to the same object, this useEffect
  // runs only on mount and unmount.
  useEffect(() => {
    return function cleanupPendingRequests() {
      for (let promise of Object.values(pendingRequests)) {
        promise.abort();
      }
    };
  }, [pendingRequests]);
  const client = new ApiClient(apiV5, update, pendingRequests, cache, '');
  // This useEffect runs on every commit, in order to trigger any necessary
  // requests. (Which needs to be deferred until a useEffect so that it won't
  // happen for requests from renders that weren't ultimately committed).
  useEffect(() => {
    client.flushRequestQueue();
  });
  return client;
}
