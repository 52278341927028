import { useState } from 'react';
import { random } from 'lodash';

/**
 * Generate a random number between lower and upper which is stable between
 * rerenders.
 *
 * @param {number} lower Lower bound
 * @param {number} upper Upper bound
 * @returns {number} A number between lower and upper (inclusive).
 */
export function useRandom(lower = 0, upper = 1) {
  const [number] = useState(() => random(lower, upper));
  return number;
}
