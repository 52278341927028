import {
  DateRangeConstraint,
  DiscreteConstraint,
  NumericRangeConstraint
} from '../classes/Constraints';

export const CURRENT_VERSION = 1;

const getCompressedValues = constraint => {
  switch (constraint.constructor) {
    case DiscreteConstraint:
      return constraint.values;
    case DateRangeConstraint:
    case NumericRangeConstraint:
      return { a: constraint.minimum, b: constraint.maximum };
  }
};

const compressConstraint = constraint => [
  constraint.name,
  getCompressedValues(constraint)
];

export const encodeFilter = filter =>
  filter?.length > 0
    ? JSON.stringify([CURRENT_VERSION, filter.map(compressConstraint)])
    : null;

const decompressConstraintV1 = ([name, values]) => {
  if (values instanceof Array) {
    return new DiscreteConstraint(name, values);
  }

  const { a: minimum, b: maximum } = values;
  // The min/max values for dates are encoded as strings
  if (typeof minimum === 'string' || typeof maximum === 'string') {
    return new DateRangeConstraint(name, minimum, maximum);
  }

  return new NumericRangeConstraint(name, minimum, maximum);
};

export function decodeFilter(param) {
  if (param == null) {
    return [];
  }

  let version, filter;

  try {
    [version, filter] = JSON.parse(param);
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error('Unable to parse filter. It is likely too long');
      return [];
    }

    throw error;
  }

  if (version === 1) {
    return filter.map(decompressConstraintV1);
  }

  throw new Error('Unrecognized filter version');
}
