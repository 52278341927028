import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const More = ({ iconColor, widthName, ...props }) => (
  <SVGWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66667 3.66732C9.66667 4.4037 9.06971 5.00065 8.33333 5.00065C7.59695 5.00065 7 4.4037 7 3.66732C7 2.93094 7.59695 2.33398 8.33333 2.33398C9.06971 2.33398 9.66667 2.93094 9.66667 3.66732ZM9.66667 8.00065C9.66667 8.73703 9.06971 9.33398 8.33333 9.33398C7.59695 9.33398 7 8.73703 7 8.00065C7 7.26427 7.59695 6.66732 8.33333 6.66732C9.06971 6.66732 9.66667 7.26427 9.66667 8.00065ZM8.33333 13.6673C9.06971 13.6673 9.66667 13.0704 9.66667 12.334C9.66667 11.5976 9.06971 11.0007 8.33333 11.0007C7.59695 11.0007 7 11.5976 7 12.334C7 13.0704 7.59695 13.6673 8.33333 13.6673Z"
      fill={iconColor}
    />
    {widthName === 'bolder' && (
      <path
        d="M8.33333 5.50065C9.34586 5.50065 10.1667 4.67984 10.1667 3.66732H9.16667C9.16667 4.12755 8.79357 4.50065 8.33333 4.50065V5.50065ZM6.5 3.66732C6.5 4.67984 7.32081 5.50065 8.33333 5.50065V4.50065C7.8731 4.50065 7.5 4.12755 7.5 3.66732H6.5ZM8.33333 1.83398C7.32081 1.83398 6.5 2.6548 6.5 3.66732H7.5C7.5 3.20708 7.8731 2.83398 8.33333 2.83398V1.83398ZM10.1667 3.66732C10.1667 2.6548 9.34586 1.83398 8.33333 1.83398V2.83398C8.79357 2.83398 9.16667 3.20708 9.16667 3.66732H10.1667ZM8.33333 9.83398C9.34586 9.83398 10.1667 9.01317 10.1667 8.00065H9.16667C9.16667 8.46089 8.79357 8.83398 8.33333 8.83398V9.83398ZM6.5 8.00065C6.5 9.01317 7.32081 9.83398 8.33333 9.83398V8.83398C7.8731 8.83398 7.5 8.46089 7.5 8.00065H6.5ZM8.33333 6.16732C7.32081 6.16732 6.5 6.98813 6.5 8.00065H7.5C7.5 7.54041 7.8731 7.16732 8.33333 7.16732V6.16732ZM10.1667 8.00065C10.1667 6.98813 9.34586 6.16732 8.33333 6.16732V7.16732C8.79357 7.16732 9.16667 7.54041 9.16667 8.00065H10.1667ZM9.16667 12.334C9.16667 12.7942 8.79357 13.1673 8.33333 13.1673V14.1673C9.34586 14.1673 10.1667 13.3465 10.1667 12.334H9.16667ZM8.33333 11.5007C8.79357 11.5007 9.16667 11.8737 9.16667 12.334H10.1667C10.1667 11.3215 9.34586 10.5007 8.33333 10.5007V11.5007ZM7.5 12.334C7.5 11.8737 7.8731 11.5007 8.33333 11.5007V10.5007C7.32081 10.5007 6.5 11.3215 6.5 12.334H7.5ZM8.33333 13.1673C7.8731 13.1673 7.5 12.7942 7.5 12.334H6.5C6.5 13.3465 7.32081 14.1673 8.33333 14.1673V13.1673Z"
        fill={iconColor}
      />
    )}
  </SVGWrapper>
);
