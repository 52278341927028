import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function Arrow({ iconColor, lineWidth, direction, ...props }) {
  return (
    <SVGWrapper {...props}>
      <DirectedArrow
        direction={direction}
        iconColor={iconColor}
        lineWidth={lineWidth}
      />
    </SVGWrapper>
  );
}

const DirectedArrow = ({ direction, iconColor, lineWidth }) => {
  switch (direction) {
    case 'up':
      return (
        <path
          d="M11.6842 6.68601L8.00004 3.0017M8.00004 3.0017L4.31572 6.68601M8.00004 3.0017L8.00004 12.9961"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'down':
      return (
        <path
          d="M4.31573 9.31204L7.9999 12.9963M7.9999 12.9963L11.6842 9.31204M7.9999 12.9963L7.9999 3.00195"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'right':
      return (
        <path
          d="M9.31289 4.31641L12.9972 8.00057M12.9972 8.00057L9.31289 11.6849M12.9972 8.00057H3.00281"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 'left':
      return (
        <path
          d="M6.68711 4.31641L3.0028 8.00057M3.0028 8.00057L6.68711 11.6849M3.0028 8.00057H12.9972"
          stroke={iconColor}
          strokeWidth={lineWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    default:
      throw 'Arrow icon requires a "direction" prop';
  }
};
