export class Version {
  constructor(version) {
    const versionParts = (version ?? '').split('.').map(v => parseInt(v));
    this.major = versionParts[0] || 0;
    this.minor = versionParts[1] || 0;
    this.patch = versionParts[2] || 0;
  }

  /**
   * Check if this version is newer than another version, but ignore changes in
   * the patch version.
   *
   * @param {string} otherVersion - The minimum required version
   * @return {boolean} - True if this version >= otherVersion, ignoring patches
   */
  isSignificantlyNewerThan(otherVersion) {
    otherVersion = new Version(otherVersion);
    if (this.major > otherVersion.major) {
      return true;
    }
    if (this.major === otherVersion.major && this.minor > otherVersion.minor) {
      return true;
    }
    return false;
  }

  /**
   * Check if this version is greater than or equal to another.
   *
   * @param {string} minimumVersion - The minimum required version
   * @return {boolean} - True if version >= minimumVersion
   */
  isAtLeast(minimumVersionString) {
    const minimumVersion = new Version(minimumVersionString);
    if (this.major > minimumVersion.major) {
      return true;
    }
    if (this.major === minimumVersion.major) {
      if (this.minor > minimumVersion.minor) {
        return true;
      }
      if (
        this.minor === minimumVersion.minor &&
        this.patch >= minimumVersion.patch
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if this version is less than another
   *
   * @param {string} maximumVersionString - The maximum version
   * @return {boolean} - True if version < maximum version
   */
  isLessThan(maximumVersionString) {
    return !this.isAtLeast(maximumVersionString);
  }
}
