import qs from 'querystring-es3';

import { isObject, isEqual, isEmpty, omitBy } from 'lodash';

const prune = object =>
  omitBy(
    object,
    value => value == null || value === '' || typeof value === 'object'
  );

const toObject = value => (isObject(value) ? value : parse(value));

/**
 * Convert an object into a search string.
 *
 * Strips any null or undefined properties.
 *
 * @param {Object.<string, any>} object - An object to be converted
 * into a search string.
 *
 * @return {string} A string of '&'-separated `key=value` pairs.
 */
export function stringify(object = {}) {
  if (isEmpty(object)) {
    return '';
  }

  return qs.stringify(prune(object));
}

/**
 * Take a search string and convert it to an object.
 *
 * All values will be parsed as strings.
 *
 * @param {string} string - A string of '&'-separated `key=value`
 * pairs. Optionally includes a leading question mark.
 *
 * @return {Object.<string, string>} An object made up of the keys and
 * values from the search string.
 */
export function parse(string = '') {
  const searchString = string && string[0] === '?' ? string.slice(1) : string;
  return prune(qs.parse(searchString));
}

/**
 * A helper for merging multiple search strings or objects.
 *
 * Merging is done right to left.
 *
 * @param  {...(string | Object.<string, any>)} searches - Searches to be
 * merged together.
 *
 * @return {string} A string of '&'-separated `key=value` pairs
 */
export function merge(...searches) {
  const object = {};

  for (const search of searches) {
    Object.assign(object, toObject(search));
  }

  return stringify(object);
}

/**
 * Check the equality of two search strings or objects.
 *
 * All search parameter values are compared in stringified form.
 * Example: {foo: 1} is equal to {foo: '1'}.
 *
 * @param {string | Object.<string, any>} searchA
 * @param {string | Object.<string, any>} searchB
 *
 * @return {boolean} true if both objects have the same keys and
 * values.
 */
export function areEqual(searchA, searchB) {
  const objectA = parse(stringify(toObject(searchA)));
  const objectB = parse(stringify(toObject(searchB)));
  return isEqual(objectA, objectB);
}
