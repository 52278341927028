import { useCallback, useEffect } from 'react';

import { useForceUpdate } from './useForceUpdate';

/**
 * Given a key and an initial value, returns a [value, setValue] pair, analogous
 * to "useState". Passing a function as the initial value is not supported, but
 * the "setValue" can accept either a new value or an update function.
 *
 * The value of the key must be unique for each use of this hook since it's not
 * possible to know if anything has updated local storage from the current tab.
 *
 * @param {string} key
 * @param {string} initialValue
 * @returns {[string, ((function(string): void)|string)]}
 */
export const useLocalStorage = (key, initialValue) => {
  const forceUpdate = useForceUpdate();
  // If the key is not set in localStorage, set it. We do this outside of a
  // useEffect because (a) React doesn't pay attention to localStorage updates
  // and (b) we don't want to trigger an unnecessary re-render
  const storedValue = localStorage.getItem(key);
  if (!storedValue) {
    localStorage.setItem(key, initialValue);
  }
  const value = storedValue ?? initialValue;

  const setValue = useCallback(
    update => {
      const currentValue = localStorage.getItem(key);
      const value =
        typeof update === 'function' ? update(currentValue) : update;

      if (value !== currentValue) {
        localStorage.setItem(key, value);
        // Since React doesn't know about localStorage updates, explicitly tell
        // it that something has changed.
        forceUpdate();
      }
    },
    [key, forceUpdate]
  );

  // Sync up the value between tabs
  useEffect(() => {
    window.addEventListener('storage', forceUpdate);
    return () => window.removeEventListener('storage', forceUpdate);
  }, [forceUpdate]);

  return [value, setValue];
};
