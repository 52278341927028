import keymirror from 'keymirror';

export const IconTypes = keymirror({
  ADD: null,
  AMAZON: null,
  APPROXIMATE: null,
  APPROXIMATE_CIRCLE: null,
  ARROW: null,
  AUDIBLE: null,
  BESTBUY: null,
  BRIEFCASE: null,
  CALENDAR: null,
  CARET_FILL: null,
  CARET_STROKE: null,
  CHART: null,
  CHECK: null,
  CIRCULAR_CHECK: null,
  CIRCULAR_CLOSE: null,
  CIRCULAR_EXCLAMATION: null,
  CIRCULAR_INFO: null,
  CLIPBOARD: null,
  CLOCK: null,
  CLOSE: null,
  CONSUMERAFFAIRS: null,
  COORDINATE: null,
  COPY: null,
  COSTCO: null,
  DASHBOARD: null,
  DOCUMENTS: null,
  DOUBLE_GEARS: null,
  DOWNLOAD: null,
  DRIVERS: null,
  EQUALS: null,
  EXACT_CIRCLE: null,
  EXCHANGE: null,
  FACE: null,
  FLASK: null,
  FLOPPY: null,
  FUNNEL: null,
  GALAXY: null,
  GEAR: null,
  GLASSDOOR: null,
  GOOGLE:null,
  HIGHLIGHTS: null,
  HR: null,
  INFO: null,
  LAUNCHER: null,
  LIST: null,
  MINUS: null,
  MORE: null,
  NOTES: null,
  PEN: null,
  PERSON: null,
  RADIO_BUTTON: null,
  REDDIT: null,
  REVIEWS: null,
  ROTATE: null,
  SAMSCLUB: null,
  SEARCH: null,
  SENTIMENT: null,
  SORT: null,
  SPINNER_DOTTED: null,
  SPINNER_STROKE: null,
  SURVEYS: null,
  TABLE: null,
  TARGETCOM: null,
  TRASH: null,
  UPLOAD: null,
  VOLUME: null,
  WALMART: null,
  TRUSTPILOT:null,
  G2:null
});

export const FEATURES = [
  'HIGHLIGHTS',
  'VOLUME',
  'GALAXY',
  'DRIVERS',
  'SENTIMENT',
  'DASHBOARD',
  'DOUBLE_GEARS'
];
