import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useUniqueId } from '../utils/hooks';
import { Checkbox } from '../components/core/Checkbox';
import { useSystemStatus } from '../systemStatus';

export default function SentimentSelector({
  checked,
  onChange,
  additionalCss,
  rebuild = false
}) {
  const checkboxId = useUniqueId();
  const systemStatus = useSystemStatus();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        ${additionalCss}
      `}
    >
      <Checkbox
        id={checkboxId}
        checked={checked}
        onChange={evt => onChange(evt.target.checked)}
        disabled={!systemStatus?.gpu}
      />
      <label
        htmlFor={checkboxId}
        css={css`
          margin-left: 0.5rem;
          font-size: 1rem;
        `}
      >
        {rebuild ? 'Rebuild' : 'Build'} without{' '}
        <a
          href="https://www.luminoso.com/getting-started-with-daylight/sentiment-feature%3A-detect-positive%2C-negative%2C-and-neutral
"
        >
          sentiment
        </a>
      </label>
    </div>
  );
}

SentimentSelector.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  additionalCss: PropTypes.object
};
