import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Search = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path;
  if (widthName === 'light') {
    path =
      'M11.6377 10.7123L14.875 13.9497C15.1576 14.2323 15.1576 14.6904 14.875 14.973C14.5924 15.2556 14.1343 15.2556 13.8517 14.973L10.6143 11.7357M13.3812 6.97934C13.3812 10.3837 10.6214 13.1435 7.21698 13.1435C3.81259 13.1435 1.0528 10.3837 1.0528 6.97934C1.0528 3.57495 3.81259 0.815155 7.21698 0.815155C10.6214 0.815155 13.3812 3.57495 13.3812 6.97934Z';
  } else if (widthName === 'bolder') {
    path =
      'M11.5692 10.6603L14.7444 13.8355C15.0216 14.1126 15.0216 14.562 14.7444 14.8392C14.4672 15.1164 14.0178 15.1164 13.7407 14.8392L10.5655 11.664M13.2792 6.99897C13.2792 10.338 10.5724 13.0448 7.23335 13.0448C3.89432 13.0448 1.1875 10.338 1.1875 6.99897C1.1875 3.65994 3.89432 0.953125 7.23335 0.953125C10.5724 0.953125 13.2792 3.65994 13.2792 6.99897Z';
  } else {
    path =
      'M11.6377 10.7123L14.875 13.9497C15.1576 14.2323 15.1576 14.6905 14.875 14.9731C14.5924 15.2556 14.1343 15.2556 13.8517 14.9731L10.6143 11.7357M13.3812 6.97937C13.3812 10.3838 10.6214 13.1436 7.21698 13.1436C3.81259 13.1436 1.0528 10.3838 1.0528 6.97937C1.0528 3.57498 3.81259 0.815186 7.21698 0.815186C10.6214 0.815186 13.3812 3.57498 13.3812 6.97937Z';
  }
  return (
    <SVGWrapper alt="search" {...props}>
      <path d={path} stroke={iconColor} strokeWidth={lineWidth} />
    </SVGWrapper>
  );
};
