// Utils for formatting number strings consistently throughout Daylight
import { format } from 'd3-format';

export function percentify(num, total, decimalPlaces = 1) {
  if (num == null || total == null) {
    return '';
  }

  if (total === 0) {
    if (num !== 0) {
      return '';
    }

    return '0%';
  }

  return format(`.${decimalPlaces}~%`)(num / total);
}

export function thousandify(num) {
  if (num == null) {
    return '';
  }

  return format(',')(num);
}
