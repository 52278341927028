import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Documents = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M10.987 11.4281H13.6556H13.959C14.2558 11.4281 14.4965 11.1875 14.4965 10.8907V1.55336C14.4965 1.25653 14.2558 1.0159 13.959 1.0159H5.55991C5.26309 1.0159 5.02246 1.25653 5.02246 1.55336V1.86709V5.17225M10.987 11.4281H5.55991C5.26309 11.4281 5.02246 11.1875 5.02246 10.8907V5.17225M10.987 11.4281V14.4485C10.987 14.7453 10.7464 14.9859 10.4496 14.9859H2.15475C1.85792 14.9859 1.61729 14.7453 1.61729 14.4485L1.46094 5.7097C1.46094 5.41288 1.70156 5.17225 1.99839 5.17225H5.02246M7.57105 6.23456H12.022M7.57105 8.52351H9.98641M7.57104 3.74731H12.022"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);
