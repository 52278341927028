import ko from 'knockout';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { resetPassword } from '../../../main/scripts/utils/ApiUtilsV5';
import SectionBase from '../classes/sectionbase';
import Router from '../utils/router';
import { createPasswordValidationErrors } from '../../../main/scripts/utils/password';
import {
  useDocumentTitle,
  useUniqueId
} from '../../../main/scripts/utils/hooks';
import { Colors } from '../../../main/scripts/styles';

export default class ResetPasswordSection extends SectionBase {
  sectionRequiresAuth = false;

  constructor() {
    super();
    ko.applyBindings(this);
    ReactDOM.render(<ResetPassword />, document.getElementById('main'));
  }
}

export const ResetPassword = () => {
  useDocumentTitle('Reset Password | Luminoso Daylight');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    const token = Router.param('token');

    if (!token) {
      // TODO: Nicer error-handling.
      alert(
        'That is not a valid link to reset a password. Redirecting to the overview.'
      );
      Router.go('index.html');
    } else {
      const updatedErrors = createPasswordValidationErrors(
        password,
        confirmPassword,
        null
      );
      setErrors(updatedErrors);
      if (Object.keys(updatedErrors).length === 0) {
        resetPassword(token, password)
          .then(() => alert('Your password has been reset.'))
          .catch(error => {
            let alertMessage = error.message;
            if (error.reason) {
              alertMessage += '\n' + error.reason;
            }
            alert(alertMessage);
          })
          .finally(() => Router.go('login.html'));
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <h1>Reset password</h1>
      <p>
        Note: resetting your password will log out all of your current sessions.
        If you have created a long-lived token for API use, it will
        <em> not</em> be removed.
      </p>
      <PasswordInput
        label="New password:"
        value={password}
        onChange={setPassword}
        errors={errors?.newPass}
      />
      <PasswordInput
        label="Confirm new password:"
        value={confirmPassword}
        onChange={setConfirmPassword}
        errors={errors?.confirmPass}
      />
      <button type="submit" className="button">
        Set password
      </button>
    </Form>
  );
};

const PasswordInput = ({ label, value, onChange, errors }) => {
  const id = useUniqueId();
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        value={value}
        onChange={event => onChange(event.target.value)}
        type="password"
        autoComplete="new-password"
        required
        css={css`
          margin-bottom: 0.5rem;
        `}
      />
      {errors && <span>{errors.join('\n')}</span>}
    </>
  );
};

const Form = styled.form`
  padding-top: 3.125rem;
  color: ${Colors.gray9};
  margin: 0 2rem;
`;
