import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Close = ({ iconColor, lineWidth, widthName, ...props }) => {
  let path = '';
  if (widthName === 'light') {
    path = 'M12.117 3.9047L3.90472 12.117M3.883 3.88304L12.0953 12.0953';
  } else {
    path = 'M12.015 4.00617L4.00617 12.015M3.98499 3.98505L11.9938 11.9939';
  }

  return (
    <SVGWrapper {...props}>
      <path
        d={path}
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
    </SVGWrapper>
  );
};
