import { useRouteMatch } from 'react-router-dom';

import { RoutePatterns } from '../../constants';

export function useCurrentFeature() {
  const isViewingDrivers = !!useRouteMatch(RoutePatterns.DRIVERS);
  const isViewingVolume = !!useRouteMatch(RoutePatterns.VOLUME);
  const isViewingSentiment = !!useRouteMatch(RoutePatterns.SENTIMENT);
  const isViewingGalaxy = !!useRouteMatch(RoutePatterns.GALAXY);
  if (isViewingDrivers) {
    return 'drivers';
  }
  if (isViewingGalaxy) {
    return 'galaxy';
  }
  if (isViewingVolume) {
    return 'volume';
  }
  if (isViewingSentiment) {
    return 'sentiment';
  }
}
