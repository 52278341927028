import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Surveys = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper defaultSize="24" {...props}>
      <path
        fill={iconColor}
        d="m8,12c-3.309,0-6-2.691-6-6S4.691,0,8,0s6,2.691,6,6-2.691,6-6,6Zm6.744,12c-.211,0-.422-.067-.6-.2-.34-.254-.481-.696-.354-1.101l.941-3.016-2.377-1.934c-.32-.271-.437-.713-.292-1.107.145-.394.519-.655.938-.655h3.001l1.062-2.98c.146-.391.52-.651.937-.651s.791.26.937.651l1.062,2.98h3.001c.42,0,.795.263.939.657s.026.837-.295,1.108l-2.366,1.927.979,2.98c.134.403-.002.847-.339,1.106-.337.259-.801.277-1.156.046l-2.754-1.793-2.708,1.812c-.168.113-.362.169-.556.169Zm-4.807,0H1c-.552,0-1-.448-1-1,0-4.962,4.038-9,9-9h.937c.346,0,.667.18.85.474.182.294.199.662.045.971-.552,1.11-.832,2.307-.832,3.555s.28,2.444.832,3.555c.154.31.137.678-.045.972-.182.294-.504.473-.85.473Z"
      />
    </SVGWrapper>
  );
};
