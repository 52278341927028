import React, { useEffect, useState } from 'react';

import {
  initialState,
  addChangeListener,
  removeChangeListener,
  getState
} from './stores/ProjectStore';

export const StoreContext = React.createContext(initialState);

export function StoreProvider({ children }) {
  const [state, setState] = useState(getState);
  useEffect(() => {
    let timeout;
    function handleStoreUpdate() {
      const nextState = getState();
      // let dispatch cycle close before re-rendering & potentially triggering new actions
      timeout = setTimeout(() => {
        setState(nextState);
      });
    }
    addChangeListener(handleStoreUpdate);
    return () => {
      clearTimeout(timeout);
      removeChangeListener(handleStoreUpdate);
    };
  }, []);
  return (
    <StoreContext.Provider value={state}>{children}</StoreContext.Provider>
  );
}
