import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const ConsumerAffairs = ({ iconColor, lineWidth, ...props }) => {
  return (
    <SVGWrapper defaultSize="329" {...props}>
      <path
        fill="#2976d1"
        d="M21.3 1.6c1.7-1 3.8-1 5.5 0l15.3 8.9c1.7 1 2.7 2.8 2.7 4.7v17.7c0 1.9-1 3.7-2.7 4.7l-15.3 8.9c-1.7 1-3.8 1-5.5 0L5.9 37.6c-1.7-1-2.7-2.8-2.7-4.7V15.1c0-1.9 1-3.7 2.7-4.7l15.4-8.8z"
      ></path>
      <path
        fill="#fff"
        d="m24.1 14.2 2.7 6.3 6.8.6-5.1 4.5 1.5 6.7-5.8-3.5-5.8 3.5 1.5-6.7-5.1-4.5 6.8-.6 2.5-6.3z"
      ></path>
      <g>
        <path
          fill={iconColor}
          className="ca-icon__colored-fill"
          d="M72.1 33.6c-1.2 1.6-2 2.5-3.4 2.5-2.4 0-3.7-2.5-3.7-12 0-8.4.9-11 3.3-11 1 0 1.7.6 2.9 2.1.9 1.2 2.7 3.8 4.1 6.1h.5l-.7-8.4c-1.7-.6-4-.8-6.8-.8C60.3 12 54 16 54 24.7c0 7.8 5 12.3 13.6 12.2 3 0 5.8-.4 8.1-1.3l.5-8.1h-.5c-1.2 2.2-2.3 4.3-3.6 6.1zM88.4 17.8c-5.1 0-10.7 2.5-10.7 9.7 0 6.5 5.1 9.6 10.7 9.6 5.3 0 10.7-2.5 10.7-9.7 0-6.6-5.4-9.6-10.7-9.6zm.1 18.2c-1.2 0-1.7-1-1.6-8.9 0-7.4.5-8.4 1.5-8.4 1.2 0 1.6 1 1.6 8.9 0 7.4-.4 8.4-1.5 8.4zM121.9 33.8V22.3c0-2.9-2-4.7-5-4.7-2.5 0-5 1.4-6.9 3h-.1l.1-2.7-.2-.2-10.5 1.1v.3l.6.4c1.4 1.1 1.4 1.1 1.4 2.9v11.2c0 1.6-.1 1.6-1.8 2.2l-.3.2v.5h12v-.6l-.2-.1c-.8-.2-.9-.5-.9-1.9V21.5c.2-.1.6-.1 1-.1 1.2 0 1.9.7 1.9 2.4V34c0 1.4-.2 1.7-1 1.9l-.2.1v.6h12V36l-.2-.1c-1.6-.5-1.7-.6-1.7-2.1zM133.7 23.1c-2.6-.8-3.4-1.4-3.4-2.5 0-1 .7-1.8 2-1.8 1.2 0 2 .4 3.4 1.9.8.8 1.7 1.8 2.6 3h.4l-.3-5.2c-1.6-.4-3.4-.7-5.4-.7-6.2 0-9 2.7-9 6.5 0 3.4 1.1 5.7 6.1 7.1 2.4.7 3.2 1.5 3.2 2.5 0 1.2-.6 2-2.1 2-1.1 0-2.2-.4-3.5-1.8-1.2-1.2-2-2.2-3.2-3.8h-.4l.2 5.9c1.7.5 4 .7 6.2.7 6.5 0 9.4-2.9 9.4-7.1.2-3.4-1.5-5.2-6.2-6.7zM164.6 35.1l-.6-.2c-1.2-.4-1.3-.6-1.3-2.4V17.9l-.2-.2-10.5 1.2v.4l.6.4c1.2.9 1.2 1 1.2 2.8h-.1v10.7c-.2.1-.6.2-1 .2-1.3 0-1.8-.7-1.8-2.7V17.9l-.2-.2-10.7 1.1v.4l.6.4c1.2 1 1.3 1 1.3 2.8V32c0 3.4 2.5 5 5.1 5 2.5 0 4.5-.9 6.6-2.9h.1l-.1 2.7.2.2 10.5-1.4v-.5z"
        ></path>
        <path
          fill={iconColor}
          className="ca-icon__colored-fill"
          d="M197.9 33.8V22.4c0-2.9-1.9-4.7-4.8-4.7-2.4 0-5.1 1.4-7 3h-.1c-.6-2-2.4-3-4.7-3-2.4 0-4.8 1.4-6.8 3h-.1l.1-2.8-.2-.2-10.5 1.1v.3l.6.4c1.3 1.1 1.4 1.1 1.4 2.9v11.2c0 1.6 0 1.6-1.8 2.2l-.1.1v.6h12V36l-.2-.1c-.8-.2-.9-.5-.9-1.8V21.5c.2-.1.7-.1.9-.1 1.2 0 1.8.7 1.8 2.4v10.4c0 1.3-.1 1.6-.9 1.8l-.2.1v.6h11.1V36l-.2-.1c-.7-.2-.9-.5-.9-1.8V22.8c0-.6 0-1.1-.1-1.3.2-.1.7-.1.9-.1 1.2 0 1.8.7 1.8 2.4h-.1v10.4c0 1.3-.1 1.6-.9 1.8v.6h11.8V36l-.2-.1c-1.6-.5-1.7-.6-1.7-2.1zM208.7 26.2h10.7c-.3-5.9-3.5-8.4-9.1-8.4-6.3 0-10.4 4.3-10.4 9.9 0 6.3 3.7 9.4 9.9 9.3 4.7 0 7.4-2 8.9-4.1v-.5c-1.2.6-2.7 1-4.2 1-3.8-.1-5.7-1.5-5.8-7.2zm1.3-7.6c1.1 0 1.3 1.4 1.3 6.4l-2.6.3c0-5.3.3-6.6 1.3-6.7zM238.7 21.7c0-2.5-1.5-3.9-3.7-3.9-2 0-3.8 1.4-5 3l.1-2.9-.2-.2-10.5 1.1v.3l.6.4c1.4 1.1 1.4 1.1 1.4 2.9h-.1v11.3c0 1.6 0 1.6-1.8 2.2l-.1.1v.6h13.1V36l-.4-.1c-1.7-.6-1.9-.6-1.9-2.2V22c.1-.1.2-.2.4-.2.4 0 .6.2.9 1.1.8 1.8 1.9 2.5 3.7 2.5 2 0 3.5-1.3 3.5-3.7zM273.6 15.5c0-3.4 1.4-4.7 2.7-4.7.6 0 .7.4.8 1.1.3 1.5.8 2 1.9 2 1 0 1.7-.7 1.7-1.6 0-1.2-1.3-2.2-3.5-2.2-4.2 0-7.2 2.7-7.2 6.9v1.8h-5.6V16c0-3.4 1.1-4.7 2.4-4.7.6 0 .7.4.8 1.1.2 1.4.7 1.9 1.8 1.9 1 0 1.6-.6 1.6-1.6 0-1.2-1.2-2.1-3.4-2.1-4.2 0-6.8 2.7-6.8 6.9v1.3l-2.3 1v.2h2.3v14.1c0 1.4-.1 1.6-1.3 1.8l-.9.2V36l-.7-.2c-1-.2-1.4-.9-2.4-3.5l-6.9-20h-1.9L239.6 32c-1.1 3.2-1.3 3.6-2.5 4l-.6.2h-.1v.3h6.2v-.4l-.9-.2c-1.1-.3-1.5-.7-1.5-1.6 0-.6.2-1.4.6-2.7l1.4-4h7.4l1.6 4.7c.2.6.4 1.6.4 2 0 .7-.2 1.2-1.4 1.4l-.8.2v.4h9.1v.1h8.4V36l-1.5-.3c-.9-.2-1.1-.4-1.1-1.7V20h5.6v14.2c0 1.4-.1 1.6-1.2 1.8l-.9.2v.4h8.4v-.4l-1.6-.4c-.9-.2-1.1-.4-1.1-1.7V20h3.5l.1-1.2h-3.6v-3.3zM242.5 27l3.4-10h.1l3.3 10h-6.8z"
        ></path>
        <path
          fill={iconColor}
          className="ca-icon__colored-fill"
          d="M291.3 33.6V22.8c0-3-1.9-4.6-6-4.6-3.2 0-6.5.9-6.5 3.1 0 1 .7 1.6 1.8 1.6 1.2 0 1.8-.6 2-2.4.2-1.2.6-1.6 2.1-1.6 2.9 0 3 1.6 3 4.1v3.8l-4.6 1.2c-2.7.7-5.3 1.6-5.3 4.8 0 2.7 2 4.3 5 4.2 2.2 0 3.9-1.1 4.9-2.2h.1c.2 1.4 1.2 2.2 3 2.2 1.3 0 2.5-.4 3-.8V36c-.7 0-1.3-.1-1.8-.4-.5-.5-.7-1-.7-2zm-3.6.3c-.6.8-1.7 1.4-3.3 1.4-1.9 0-3-1.2-3-3.3 0-2.5 1.4-3.4 4.2-3.9l2-.4v6.2zM299.9 34.2V22.5c0-2.2.1-3.4.1-4l-.2-.1-5.8.7v.2l.9.5c1.2.6 1.4.7 1.4 2v12.3c0 1.4-.1 1.6-1.3 1.8l-1 .2v.4h8.2v-.4l-.9-.2c-1.2-.2-1.4-.3-1.4-1.7zM298 15.8c1.2 0 2.1-.8 2.1-2s-.9-2-2.1-2-2.1.9-2.1 2c0 1.2.8 2 2.1 2zM313.7 18.2c-1.8 0-3.6 1.5-5 2.7h-.1l.1-2.5-.2-.1-5.6.7v.2l.9.5c1.2.7 1.3.7 1.3 2V34c0 1.4-.1 1.6-1.3 1.8l-1 .2v.3h8.6v-.4l-1.5-.2c-1.1-.2-1.2-.6-1.2-1.8V21.6c.9-.7 2.1-1.4 2.7-1.4.4 0 .6.1.8.7.3.7.7 1.3 1.8 1.3s1.9-.7 1.9-1.9c.1-1.3-.7-2.1-2.2-2.1zM323.2 25.8c-2.9-1.1-4.2-2-4.2-3.8 0-1.7 1.2-3 3.3-3 1.6 0 2.4.5 3.1 1.7.5.7.9 1.6 1.6 2.7h.4l-.1-4.4c-1.2-.4-2.7-.8-4.5-.8-3.8 0-6.3 2.2-6.3 5.3 0 2.9 1.7 4.3 4.9 5.6 3 1.2 4.1 2 4.1 3.9s-1.2 3.2-3.4 3.2c-2 0-3-.7-4-2.4-.5-.8-.9-1.7-1.5-2.9h-.4l.1 5.1c1.4.4 3.2.8 5.4.9 4.3 0 6.6-2.5 6.6-5.6.1-2.9-1.9-4.2-5.1-5.5z"
        ></path>
      </g>
    </SVGWrapper>
  );
};
