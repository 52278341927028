import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Colors, Mixins } from '../../styles';
import { Icon, IconTypes } from '../icons';

export function Checkbox({
  size = '1rem',
  checkedIcon = <Icon type={IconTypes.CHECK} />,
  indeterminate = false,
  uncheckedIcon = null,
  indeterminateIcon = <Icon type={IconTypes.MINUS} />,
  outlined = true,
  className,
  color = Colors.blue3,
  disabled = false,
  ...passedProps
}) {
  const ref = React.useRef();
  React.useLayoutEffect(() => {
    ref.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <span
      className={cx('lumi-checkbox', className)}
      css={css`
        display: inline-block; /* expand to size of contents while remaining inline */
        position: relative; /* facilitate positioning sub-elements */
        width: ${size};
        height: ${size};
        min-width: ${size};
        font-size: ${size};
        color: ${color};

        &:focus-within {
          ${Mixins.focusOutline};
        }

        & > * {
          width: 100%;
          height: 100%;
          &:not(input) {
            position: absolute;
            left: 0; /* superimpose on the <input> */
            top: 0;
            pointer-events: none;
          }
        }
      `}
    >
      <input
        {...passedProps}
        disabled={disabled}
        ref={ref}
        css={css`
          cursor: pointer;
          margin: 0;
          opacity: 0; /* hide input from view while maintaining its interactivity */

          &:disabled ~ * {
            ${outlined &&
            css`
              &:nth-of-type(1) {
                background: ${Colors.gray1};
              }
            `}
            color: ${Colors.gray3};
          }

          &:not(:indeterminate) {
            &:checked ~ :nth-of-type(2) {
              visibility: visible;
            }

            &:not(:checked) ~ :nth-of-type(3) {
              visibility: visible;
            }
          }

          &:indeterminate ~ :nth-of-type(4) {
            visibility: visible;
          }
        `}
        type="checkbox"
      />
      <span
        css={css`
          ${outlined && outlineStyles};
        `}
      />
      <IconContainer>{checkedIcon}</IconContainer>
      <IconContainer>{uncheckedIcon}</IconContainer>
      <IconContainer>{indeterminateIcon}</IconContainer>
    </span>
  );
}

Checkbox.propTypes = {
  size: PropTypes.string,
  outlined: PropTypes.bool,
  indeterminate: PropTypes.bool,
  checkedIcon: PropTypes.element,
  uncheckedIcon: PropTypes.element,
  indeterminateIcon: PropTypes.element
};

const outlineStyles = css`
  ${Mixins.roundedCorners};
  ${Mixins.shadowOutset};
  box-sizing: border-box;
  background: white;
`;

const IconContainerCss = css`
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

const IconContainer = ({ children }) => {
  return <span css={IconContainerCss}>{children}</span>;
};
