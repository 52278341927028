import { useState } from 'react';
import { uniqueId } from 'lodash';

/**
 * Get a unique id which is stable across rerenders.
 *
 * @return {string} A unique id.
 */
export function useUniqueId() {
  const [id] = useState(() => uniqueId());
  return '__UNIQUEID__' + id;
}
