// These are legacy values. Don't add new values here.
const NON_STRINGIFIED_KEYS = ['username', 'sortProjectsBy'];

export const save = (key, value) => {
  // Save a value to local storage.
  const valueToStore = NON_STRINGIFIED_KEYS.includes(key)
    ? value
    : JSON.stringify(value);
  localStorage.setItem(key, valueToStore);
};

export const load = (key, defaultValue) => {
  // Load a value from local storage, optionally returning a default value.
  if (key in localStorage) {
    if (NON_STRINGIFIED_KEYS.includes(key)) {
      return localStorage.getItem(key);
    } else {
      return JSON.parse(localStorage.getItem(key));
    }
  } else {
    return defaultValue;
  }
};
