import naturalSort from '../externalDependencies/naturalSort';

// Note: this modifies the function globally, not locally.
naturalSort.insensitive = true;

export default naturalSort;

export const naturalSortBy = field => (a, b) => naturalSort(a[field], b[field]);

export function naturalSortByTwoProps(a, b) {
  const conceptComparison = a.concept.localeCompare(b.concept);
  if (conceptComparison !== 0) return conceptComparison;
  return a.merge_with.localeCompare(b.merge_with);
}

export const naturalSortByName = naturalSortBy('name');
