import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Add = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper alt="add" isPrinted={false}  {...props}>
    <path
      d="M8.02636 3.01611V12.9839M3.01611 7.99997L12.9839 7.99997"
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </SVGWrapper>
);


export const AddForSvg = ({
                            iconColor = 'white',
                            lineWidth = '2',
                            size = 24,
                            onClick,
                            x,
                            y
                          }) => (
  <g onClick={onClick} cursor="pointer">
    <rect x={x} y={y} width={size} height={size} fill="none" />
    <path
      d={`M${x + size / 2} ${y + size / 4} v${size / 2} M${x + size / 4} ${y + size / 2} h${size / 2}`}
      stroke={iconColor}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </g>
);
