import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export const Copy = ({ iconColor, lineWidth, ...props }) => (
  <SVGWrapper {...props}>
    <path
      d="M14.0281 4.95628V11.0636C14.0281 11.4318 13.7296 11.7303 13.3614 11.7303H11.0811M14.0281 4.95628L9.99072 0.982422M14.0281 4.95628L10.6574 4.95623C10.2892 4.95623 9.99072 4.65776 9.99072 4.28957V0.982422M9.99072 0.982422L6.48646 0.982469C6.11827 0.982469 5.81979 1.28095 5.81979 1.64914V4.28961M5.81979 4.28961H2.66477C2.29658 4.28961 1.99811 4.58809 1.99811 4.95628V14.2801C1.99811 14.6483 2.29658 14.9468 2.66477 14.9468H10.4144C10.7826 14.9468 11.0811 14.6483 11.0811 14.2801V11.7303M5.81979 4.28961V11.0636C5.81979 11.4318 6.11827 11.7303 6.48646 11.7303H11.0811"
      stroke={iconColor}
      strokeWidth={lineWidth}
    />
  </SVGWrapper>
);
