import React, { useState } from 'react';

export const DataUseAgreement = ({ message, onAcknowledge, onDecline }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="data-use-acknowledgement">
      <h1>General data security acknowledgement</h1>
      <span className="message">{message}</span>
      <button
        type="button"
        className="primary button"
        onClick={() => {
          setLoading(true);
          onAcknowledge();
        }}
      >
        {loading && <span className="fade-in-loading-stripes" />}
        <span className="text">I have read and accept these terms</span>
      </button>
      <button type="button" className="button decline" onClick={onDecline}>
        Decline
      </button>
    </div>
  );
};
