import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { isEqual } from 'lodash';

const DEFAULT_BOUNDING_RECT = {
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: 0,
  height: 0
};

/**
 * Get the bounding client rect of an element.
 *
 * Returns an object similar to the one returned from
 * getBoundingClientRect(). The object will not have x or y properties.
 *
 * @param ref A React ref pointing at the element to be measured.
 *
 * @return {Object} A copy of bounding client rect. Note: this is not an
 * actual DOMRect instance.
 */
export function useBoundingClientRect(ref) {
  const [rect, setRect] = useState(DEFAULT_BOUNDING_RECT);
  const makeMeasurement = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const domRect = ref.current.getBoundingClientRect();
    const newRect = {
      top: domRect.top,
      bottom: domRect.bottom,
      right: domRect.right,
      left: domRect.left,
      width: domRect.width,
      height: domRect.height
    };

    // only update the measured rect if the values have really changed
    if (!isEqual(newRect, rect)) {
      setRect(newRect);
    }
  }, [ref, rect]);

  // make an initial measurement
  useLayoutEffect(() => {
    makeMeasurement();
  });

  // measure each time the window resizes or the document scrolls
  useLayoutEffect(() => {
    window.addEventListener('resize', makeMeasurement);
    document.addEventListener('scroll', makeMeasurement);
    return () => {
      window.removeEventListener('resize', makeMeasurement);
      document.removeEventListener('scroll', makeMeasurement);
    };
  }, [makeMeasurement]);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const observer = new IntersectionObserver(makeMeasurement);
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [makeMeasurement, ref]);

  return rect;
}
