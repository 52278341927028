import * as storage from '../utils/storage';

export function runMigrations() {
  migrateProjectListDefaultAccountId();
  migrateSidebarToSidePanel();
}

function migrateProjectListDefaultAccountId() {
  const oldKey = 'projectListDefaultAccountId';
  const newKey = 'projectListWorkspaceId';
  const oldValue = localStorage.getItem(oldKey);
  if (oldValue != null) {
    storage.save(newKey, oldValue);
    delete localStorage[oldKey];
  }
}

const sidebarKeyRegex = /^(.*)-sidebar-metadata$/;

// TODO: delete anytime in 2022
function migrateSidebarToSidePanel() {
  Object.keys(localStorage).forEach(key => {
    const match = key.match(sidebarKeyRegex);
    if (match) {
      const projectId = match[1];
      const value = localStorage.getItem(key);
      localStorage.setItem(`${projectId}-side-panel-metadata`, value);
      localStorage.removeItem(key);
    }
  });
}
