import { useEffect, useReducer } from 'react';
import _ from 'lodash';

const INITIAL_SELECTION_STATE = { selectedRows: {}, lastChecked: null };

export function useSelections(rows, primaryKey, ...inputs) {
  const [{ selectedRows }, dispatch] = useReducer((state, event) => {
    switch (event.type) {
      case 'toggleRow': {
        if (event.shiftClick && state.lastChecked) {
          // If the user is holding down the shift key when they click a
          // checkbox, modify not only that checkbox but all the ones between it
          // and the last checkbox clicked, inclusive.
          const rowKeys = rows.map(row => row[primaryKey]);
          const lastCheckedIndex = rowKeys.indexOf(state.lastChecked);
          const index = rowKeys.indexOf(event.row);
          const start = Math.min(lastCheckedIndex, index);
          const end = Math.max(lastCheckedIndex, index) + 1;
          const inBetweenRows = rowKeys.slice(start, end);

          // Set all of those checkboxes to whatever state clicking caused.
          const checked = !state.selectedRows[event.row];
          let selectedRows = { ...state.selectedRows };
          for (const row of inBetweenRows) {
            selectedRows[row] = checked;
          }

          return { lastChecked: event.row, selectedRows };
        }

        return {
          lastChecked: event.row,
          selectedRows: {
            ...state.selectedRows,
            [event.row]: !state.selectedRows[event.row]
          }
        };
      }
      case 'selectAll': {
        const targetState = rows.some(
          row => !state.selectedRows[row[primaryKey]]
        );
        // Avoid toggling elements not present in the current rows array.
        // This preserves the state for elements that are currently being
        // filtered out, such as in the Active Concepts list.
        return {
          ...state,
          selectedRows: {
            ...state.selectedRows,
            ..._.fromPairs(rows.map(row => [row[primaryKey], targetState]))
          }
        };
      }
      case 'clearLastChecked': {
        return { ...state, lastChecked: null };
      }
      case 'clearSelection': {
        return INITIAL_SELECTION_STATE;
      }
      default:
        throw new Error(
          'Programmer error - unexpected event type in useUserSelections'
        );
    }
  }, INITIAL_SELECTION_STATE);

  useEffect(() => {
    dispatch({ type: 'clearSelection' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);

  // When the rows change (e.g. were rearranged), clear our memory of the last
  // checkbox clicked, thus briefly disabling shift click until the user clicks
  // again.
  const rowKeys = JSON.stringify(rows.map(row => row[primaryKey]));
  useEffect(() => {
    dispatch({ type: 'clearLastChecked' });
  }, [rowKeys]);

  const selections = rows
    .map(row => row[primaryKey])
    .filter(key => selectedRows[key]);

  return {
    selections,
    count: selections.length,
    singular: selections.length === 1,
    any: selections.length > 0,
    all: selections.length === rows.length,
    includes: row => selectedRows[row] || false,
    toggleRow: (row, shiftClick = false) =>
      dispatch({ type: 'toggleRow', row, shiftClick }),
    selectAll: () => dispatch({ type: 'selectAll' }),
    clear: () => dispatch({ type: 'clearSelection' })
  };
}
