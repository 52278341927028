import { useQuery, useQueryClient } from 'react-query';

import {
  getConceptManagementInformation,
  updateConceptManagementInformation
} from '../utils/ApiUtilsV5';

/**
 * Fetch the concept management information for a project and then return that
 * information along with a function to update it on the server. The concept
 * management information is automatically refetched when the update succeeds.
 *
 * @param {Object} project
 *
 * @return {{
 *          status: string,
 *          conceptManagement: any,
 *          updateNextBuild: function
 *          }}
 *          - conceptManagement is an instance of ConceptManagementInformation.
 *          updateNextBuild automatically refetches on update success.
 */
export const useConceptManagement = project => {
  const projectId = project?.project_id;
  const queryClient = useQueryClient();
  const queryKey = [
    'concept_management',
    projectId,
    // Refetch concept management information when the project is rebuilt
    project?.last_successful_build_time
  ];

  const { status, data } = useQuery(
    queryKey,
    () => getConceptManagementInformation(projectId),
    { enabled: projectId != null && !!project?.last_successful_build_time }
  );

  const updateNextBuild = updates => {
    updateConceptManagementInformation(projectId, updates).then(() =>
      queryClient.invalidateQueries(queryKey)
    );
  };

  return {
    status,
    conceptManagement: data,
    updateNextBuild
  };
};
