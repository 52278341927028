import naturalSort, { naturalSortByName } from './NaturalSort';
import { decodeFilter, encodeFilter } from '../search_params';

/**
 * This function checks encoded filters for equality. A filter is an array of
 * constraint objects as defined in Constraints.js. The equality is computed
 * without regard for the order of the constraints. In the case of categorical
 * constraints we also disregard the order of the values in the constraint
 *
 * @param {string} filterA - An encoded filter
 * @param {string} filterB - An encoded filter
 * @return {boolean}
 * */
export const filtersAreEqual = (filterA, filterB) => {
  const decodedFilterA = decodeFilter(filterA);
  decodedFilterA.sort(naturalSortByName);
  decodedFilterA.forEach(constraint => constraint.values?.sort(naturalSort));

  const decodedFilterB = decodeFilter(filterB).sort(naturalSortByName);
  decodedFilterB.sort(naturalSortByName);
  decodedFilterB.forEach(constraint => constraint.values?.sort(naturalSort));

  return encodeFilter(decodedFilterA) === encodeFilter(decodedFilterB);
};
