URI = require 'urijs'

module.exports = {
  location: ->
    URI(window.location.href)

  go: (dest) ->
    window.location.href = dest

  param: (name) ->
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
    regexS = '[\\?&]' + name + '=([^&#]*)'
    regex = new RegExp(regexS)
    results = regex.exec(window.location.search)

    if(results == null)
      null
    else
      decodeURIComponent(results[1].replace(/\+/g, ' '))
}
