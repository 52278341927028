import { getToken } from './apiToken';
import makeAbortable from './makeAbortable';

const handleAPIError = response => {
  if (!response.ok) {
    return response.json().then(error => {
      throw error;
    });
  }
  return response;
};

export default function (type, url, options = {}) {
  if (!url) {
    throw new Error('You must supply a url parameter when making a request');
  }

  if (!['GET', 'DELETE', 'POST', 'PUT'].includes(type)) {
    throw new Error(
      'You must supply "GET", "DELETE", "POST", or "PUT" as a parameter when making a request.'
    );
  }

  const headers = new Headers();

  // Add the token required for Luminoso API requests
  if (options.headers && options.headers.Authorization) {
    headers.append('Authorization', options.headers.Authorization);
  } else {
    headers.append('Authorization', `Token ${getToken()}`);
  }

  if (options.headers && options.headers.accept) {
    headers.append('Accept', options.headers.accept);
  } else {
    headers.append('Accept', 'application/json');
  }

  // Apply a responseType header if one has been specified
  if (options.headers && options.headers.responseType) {
    headers.append('Response-Type', options.headers.responseType);
  }

  if (options.headers && options.headers.contentType) {
    headers.append('Content-Type', options.headers.contentType);
  }

  const controller = new AbortController();

  const request = new Request(url, {
    method: type,
    headers,
    body: options.body,
    signal: controller.signal
  });

  return makeAbortable(
    new Promise((resolve, reject) => {
      fetch(request)
        .then(handleAPIError)
        .then(resolve, error => {
          if (!(error instanceof DOMException && error.name === 'AbortError')) {
            reject(error);
          }
        });
    }),
    controller.abort.bind(controller)
  );
}
