import storage from './storage';

export const getToken = function () {
  // since localStorage could be changed manually by the user,
  // we handle possibility that it's not proper JSON
  try {
    return storage.load(storage.KEYS.token);
  } catch (error) {
    console.warn('Failed to JSON parse stored token. Clearing stored token.');
    storage.clear(storage.KEYS.token);
  }
};

export const hasToken = () => getToken() != null;

export const setToken = t => {
  storage.save(storage.KEYS.token, t);
};

export const clearToken = () => {
  storage.clear(storage.KEYS.token);
};
