import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function Floppy({ iconColor, lineWidth, ...props }) {
  return (
    <SVGWrapper {...props}>
      <path
        d="M9.18797 4.71687C9.74026 4.71687 10.188 4.26916 10.188 3.71687V1.98187H8.82047V4.71687H9.18797Z"
        fill={iconColor}
      />
      <path
        d="M4.17097 1.98187H2.98297C2.43069 1.98187 1.98297 2.42959 1.98297 2.98187V13.0159C1.98297 13.5682 2.43069 14.0159 2.98297 14.0159H4.17097M4.17097 1.98187V3.71687C4.17097 4.26916 4.61869 4.71687 5.17097 4.71687H8.82047M4.17097 1.98187H8.82047M10.188 1.98187H11.3483C11.6521 1.98187 11.9394 2.11996 12.1292 2.35718L13.7978 4.44296C13.9397 4.62027 14.017 4.84058 14.017 5.06765V13.0159C14.017 13.5682 13.5693 14.0159 13.017 14.0159H4.17097M10.188 1.98187V3.71687C10.188 4.26916 9.74026 4.71687 9.18797 4.71687H8.82047M10.188 1.98187H8.82047M8.82047 1.98187V4.71687M4.17097 14.0159V8.45187C4.17097 7.89959 4.61869 7.45187 5.17097 7.45187H10.829C11.3813 7.45187 11.829 7.89959 11.829 8.45187V14.0159"
        stroke={iconColor}
        strokeWidth={lineWidth}
        strokeLinejoin="round"
      />
    </SVGWrapper>
  );
}
