import React from 'react';

import { SVGWrapper } from '../SVGWrapper';

export function ApproximateCircle({
  iconColor,
  lineWidth,
  widthName,
  checkedState,
  ...props
}) {
  const checked = checkedState === 'checked';
  return (
    <SVGWrapper {...props}>
      {checked && (
        <circle
          cx="7.99992"
          cy="8.00065"
          r="6.66667"
          stroke={iconColor}
          strokeWidth={widthName === 'medium' ? 1.1 : lineWidth}
        />
      )}
      <circle
        cx="8"
        cy="8"
        r="4"
        stroke={iconColor}
        strokeWidth={widthName === 'medium' ? 1.5 : lineWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray={checked ? '0.5 3' : '1 3.1'}
      />
    </SVGWrapper>
  );
}
