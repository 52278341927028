# moment is used by some templates
window.moment = require 'moment'

require '../../less/main.less'

require('../../../main/scripts/compatibility/storageMigrations').runMigrations()

require('../../../main/scripts/gitRevsWindowAttribute').default()

{ load } = require '../utils/localStorage'

window.jQuery = $ = require 'jquery'
require 'chosen-js'

require('../utils/ko.bindings')

Router = require '../utils/router'
LoginSection = require('../sections/login/login').default
ExternalLoginSection = require('../sections/login/external_login').default
TokenRedirectLoginSection = require('../sections/login/token_redirect_login').default
OverviewSection = require('../sections/overview').default
ResetPasswordSection = require('../sections/resetpassword').default
ForgotPasswordSection = require('../sections/forgot').default
JoinSection = require '../sections/join'

username = load('username')
userType =
  if (
    username and
    username.startsWith('selenium') and
    username.endsWith('@luminoso.com')
  )
  then 'testing'
  else if (
    username and
    (not username.includes('@') or
    username.endsWith('@luminoso.com'))
  )
  then 'internal'
  else 'external'
window.dataLayer?.push({luminosoUserType: userType})

# TODO: Check whether there is a section for this; if not, redirect to overview,
#  or do something sensible
url = Router.location()
name = url.filename()
name = name.substring 0, name.lastIndexOf('.')

$ ->
  window.ActiveSection = new {
    login: LoginSection
    external_login: ExternalLoginSection
    token_redirect_login: TokenRedirectLoginSection
    '': OverviewSection
    index: OverviewSection
    resetpassword: ResetPasswordSection
    forgot: ForgotPasswordSection
    join: JoinSection
  }[name]()
